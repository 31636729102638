import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {every, intersection} from "lodash";
import {useQuery} from "react-query";
import {Description, LinkBtn} from "@semograph/semui";

import Filter from "./Filter";
import LoadingPage from "../LoadingPage";
import Page404 from "../Page404";
import ProductCard from "../productCard/ProductCard";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCategories} from "../../customHooks/categories";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {getLangAbbrByEndonym, getProductLangs} from "../utils";
import {languageAbbrs} from "../../../localization/languages";

import "./body.css";


function getCategoryIdByType(categories, type) {
    for (let i = 0; i < categories.length; i++) {
        if (categories[i].key === type) {
            return categories[i].id - 1;
        }
    }
    return 0;
}


function filterProducts(products, filterState) {
    return products.filter(product =>
        every([
            !filterState.filter ||
                filterState.filter.trim().length === 0 ||
                (Object.keys(product.name)
                    .map(abbr => product.name[abbr].toLowerCase().includes(filterState.filter.toLowerCase()))
                    .includes(true)
                ),
            !filterState.tags ||
                filterState.tags.length === 0 ||
                intersection(filterState.tags, product.tags.map(tag => tag.name)).length > 0,
            !filterState.languages || filterState.languages.length === 0 || intersection(
                filterState.languages.map(lang => getLangAbbrByEndonym(languageAbbrs, lang)),
                getProductLangs(product)
            ).length > 0,
            !filterState.author ||
                filterState.author.trim().length === 0 ||
                product.author.username.toLowerCase().includes(filterState.author.toLowerCase()),
            !filterState.min_rating || product.rating >= filterState.min_rating,
            !filterState.min_price || product.price >= filterState.min_price,
            !filterState.max_price || product.price <= filterState.max_price,
        ])
    );
}


function CardsCategory(props) {
    const locale = useLocalization();

    const productsInThisCategory = props.products.filter(prod => prod.type === props.category.id - 1);

    return (productsInThisCategory.length > 0
        ? (<div className='category'>
            <div className ='title'>
                <span>{locale.get.store.footer[props.category.key]}</span>
                {productsInThisCategory.length > 5
                    ? (<span><a href={`/store/${props.category.key}`}>{locale.get?.store.catalog.viewAll}</a></span>)
                    : null
                }
            </div>
            <div className = "cards">
                {productsInThisCategory.slice(0, 5).map((product, i) => <ProductCard key={i} product = {product}/>)}
            </div>
        </div>) : null
    );
}


export default function Main() {
    const {type} = useParams();
    const [filterState, setFilterState] = useState({});
    const api = useApiClient();
    const locale = useLocalization();
    const categories = useCategories();
    const user = useCurrentUser();

    const productsQuery = useQuery(
        ["publicationDependent", "store/product", user],
        () => api.get(`/api/v1/store/product_version`)
    );
    const products = productsQuery.data?.data || [];

    const data = useMemo(() => filterProducts(products, filterState), [products, filterState]);

    // console.log(filterState);
    // console.log(data);

    return (locale?.get &&
        ([productsQuery.status].includes("loading")
            ? <LoadingPage/>
            : ([productsQuery.status].includes("error")
                ? <Page404/>
                : <div className = 'body'>
                <Description
                    name = {locale.get.store.catalog.catalogTitle}
                    desc = {locale.get.store.catalog.catalogDescription}
                />
                <div className = 'refs'>
                    {categories?.map((cat, i) => (
                        <LinkBtn
                            key = {`main-page-category-${i}`}
                            text = {locale.get.store.footer[cat.key]}
                            href = {`/store/${cat.key}`}
                            isActive = {type === cat.key}
                        />
                    ))}
                </div>
                <div className='content'>
                    <Filter data={products} onFilterChange={setFilterState} />
                    <div className='products'>
                        {type !== "all"
                            ? <div className="cards">
                                {data.filter(product => product.type === getCategoryIdByType(categories, type || ""))
                                    .map((product, i) => <ProductCard key={i} product = {product}/>)}
                            </div>
                            : categories?.slice(1).map(cat => (
                                <CardsCategory key = {cat.id} category = {cat} products = {data}/>
                            ))
                        }
                    </div>
                </div>
            </div>)
        )
    );
}
