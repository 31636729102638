import React, {useState} from "react";

import {useApiClient} from "../../customHooks/useApiClient";
import queryString from "query-string";


export default function () {
    const [currentUserContent, setCurrentUserContent] = useState("");
    const [mapId, setMapId] = useState("");

    const api = useApiClient();

    const updateUserContent = event => setCurrentUserContent(event.target.value);

    const getMapId = async () => {
        let {share_token} = queryString.parse(currentUserContent.split("?")[1]);
        if (!share_token) {
            share_token = decodeURIComponent(currentUserContent);
        }
        const response = await api.get("/api/v1/researches/id_by_shared_token", {params: {share_token}});

        if (response.data) {
            setMapId(response.data);
        }
    }

    return <div className="dashed-container">
        <h3>Share token parser</h3>
        <p>Input link or token below</p>
        <input type="text" onChange={updateUserContent}/>
        <input type="button" value="send" onClick={getMapId}/>
        <p>{mapId}</p>
    </div>;
}
