import PolygonHint from "../iconArrangement/PolygonHint";
import IconDragSource from "../iconArrangement/dnd/IconDragSource";


export default function useRenderIconsById() {
    return (idArray, icons, checkIcon, selectedIcons, tabId, partitionId, subpartitionId) => idArray.map(child => {
        const [type, productId, index] = child.split("-");
        const fullType = type === "p" ? "polygons" : type === "i" ? "icons" : "polylines";
        const icon = {
            icon: icons[fullType][productId].icons[index],
            name: icons[fullType][productId].name,
            version: icons[fullType][productId].version
        };

        const idPrefix = `account-icons-${tabId}-${partitionId}-${typeof subpartitionId === "number" ? `${subpartitionId}-` : ""}`;

        return <PolygonHint key={`account-icons-partition-${productId}-hint-${index}`} icon={icon}>
            <IconDragSource
                idPrefix={idPrefix}
                id={`${type}-${productId}-${index}`}
                icon={icon.icon}
                checkIcon={checkIcon}
                selectedIcons={selectedIcons}
                tabSource={tabId}
                source={partitionId}
                subpartitionSource={subpartitionId}
            />
        </PolygonHint>
    });
}