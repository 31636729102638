import React from "react";


export default function SvgText({id, className, text, fillColor, strokeColor, fontSize, opacity, x, y}) {
    return <>
        <symbol id={`${id}-symbol-text`}>
            <text
                className="symbol-text"
                stroke={strokeColor}
                fill={fillColor}
                fontSize={fontSize}
                opacity={opacity}
                x={0}
                y="38"
            >
                {text}
            </text>
        </symbol>
        <use id={id} className={className} href={`#${id}-symbol-text`} x={x} y={y - 15}/>
    </>;
}
