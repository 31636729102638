import {combineReducers} from 'redux-immutablejs';
import {Map} from 'immutable';
import objectReducers from './objectReducer';
import menuReducers from './menuReducer';
import undoable from 'redux-undo';
import {ignoreActions} from "redux-ignore";

const jointReducer = (state, action) => {
    switch (action.type) {
        case 'loadNewState':
            return Map({main: action.data.main, menu: Map(action.data.menu)});
        default:
            return state;
    }
};


const combinedReducer = combineReducers({
    main: ignoreActions( //menu reducers affects to main state updating
        undoable(objectReducers, {
            filter: (action) => (action.type !== "loadStateByTime" && action.type !== "setMapId")
        }),
        [
            "loadMenuState",
            "clearMenuState",
            "isGridShow",
            "isWatchMode",
            "isEditMode",
            "figureUuidDescriptionPopupShownFor",
            "isContextMenuShow",
            "isValidationAnimationShow",
            "openContextMenuTool",
            "isUserCanChangeCanvasSize",
            "backgroundImage",
            "canvasWidth",
            "canvasHeight",
            "scale",
            "prevScale",
            "clipboard",
            "selectedFigureUuids",
            "tool",
            "color",
            "fontColor",
            "openedMenuItemNumber",
            "figureCenter",
            "mouseXAtTheClipboardTime",
            "mouseYAtTheClipboardTime",
            "startTime",
            "fontSize",
            "volume",
            "thickness",
            "dashLength",
            "transparency"
        ]
    ),
    menu: menuReducers
});

//I know about the reduce-reducers library, but it has no maintainers:(
export default (state, action) => {
  const intermediateState = combinedReducer(state, action);
  const finalState = jointReducer(intermediateState, action);
  return finalState;
}
