import React from 'react';

import Figure from "../figures/Figure";
import SelectedFigureContainer from "./SelectedFigureContainer";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {isItemInArray} from '../../pureFunctions/usefulFunctions';


export default function FigureContainer(props) {
    const {isWatchMode, orderedFigures, unselectedUuids} = useReduxData();
    return <>
        {orderedFigures.map(figure =>
            ((isItemInArray(figure.get("uuid"), unselectedUuids) || isWatchMode) &&
                <Figure
                    key={figure.get("uuid")}
                    figure={figure}
                    kind={"separate"}
                    isMouseDown={props.isMouseDown}
                />
            )
        )}
        <SelectedFigureContainer
            isMouseDownOnCanvas={props.isMouseDown}
            handleMouseEnterOnConnector={props.handleMouseEnterOnConnector}
        />
    </>;
}
