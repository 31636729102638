import React from "react";
import Tooltip from "react-tooltip-lite";

import {Hint} from "../../components/tooltipHOC";
import {useHintMode} from "../customHooks/useContextHintMode";
import {useLocalization} from "../../customHooks/useContextLocalization";
import getOnboardingParams from "../onboarding/onboardingParams";


function Beacon (props) {
    return (<Hint
        content = {props.content}
        style = {{pointerEvents: "all"}}
    >
        <span className = "hint-mode-beacon">?</span>
    </Hint>);
}

export default function hintModeTooltipHOC (ChildComponent, hintModeKey, customWrapperStyles) {
    return (props) => {
        const hintMode = useHintMode();
        const locale = useLocalization();

        const key = hintModeKey || props.hintModeKey; //generic components (like a menuItem) receive key via props
        const hint = getOnboardingParams(locale?.get)[key];

        return (hintMode.get && key)
            ? (<Tooltip
                content = {<Beacon content = {hint.content}/>}
                isOpen = {true}
                arrow = {false}
                padding = "0px"
                direction = {hint.placement}
                styles = {customWrapperStyles || {}}
            >
                <ChildComponent {...props}/>
            </Tooltip>)
            : (<ChildComponent {...props}/>);
    }
}
