import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {ActionCreators} from "redux-undo";
import {saveAs} from "file-saver";
import {toast} from "react-toastify";

import MapLoader from "../MapLoader";
import {useLocalization} from "../../customHooks/useContextLocalization";
import {useApiClient} from "../../customHooks/useApiClient";
import {useMapFileName} from "./useContextMapFileName";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";
import {useReduxData} from "./useContextReduxData";
import updateMap from "./useContextMap/updateMap";
import validateMap from "./useContextMap/validateMap";
import {blobToBase64, isMapChanged, convertStateToSave, takePngSnapshotOfTheCanvas} from "./useContextMap/pureFunctions";
import {getAgeOfUser} from "../pureFunctions/usefulFunctions";


export const MapContext = React.createContext();


export const MapProvider = ({children}) => {
    const locale = useLocalization();
    const user = useCurrentUser();
    const api = useApiClient();
    const mapFileName = useMapFileName();
    const {lastAction, state} = useReduxData();

    const [mapId, setMapId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [dataToLoading, setDataToLoading] = useState([]);
    const [userWantsToSaveMap, setUserWantsToSaveMap] = useState(false);

    const dispatch = useDispatch();

    const isChanged = useMemo(() => isMapChanged(state.get("main").present), [state]);
    const setSaveMapFlag = useCallback(() => setUserWantsToSaveMap(true), [setUserWantsToSaveMap]);

    useEffect(() => {
        if (userWantsToSaveMap) {
            if (lastAction.type === "saveToServer") {
                saveMap();
                setUserWantsToSaveMap(false);
            } else if (lastAction.type === "saveToComputer") {
                const data = convertStateToSave(state);

                const now = new Date();
                const city = locale.get.studio.mapFileName.city;
                const gender = user?.gender || locale.get.studio.mapFileName.gender;
                const age = getAgeOfUser(user, now) || locale.get.studio.mapFileName.age;
                const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
                    .join(".");
                const mapNamePattern = mapFileName.get || `${city}_${gender}_${age}_${formattedDate}`;

                const filename = prompt(locale?.get.studio.header.menu.loadMapTitle, mapNamePattern);
                const blob = new Blob([JSON.stringify(data)], {type: "application/json;charset=utf-8"});
                filename && saveAs(blob, filename + ".json");
                setUserWantsToSaveMap(false);
            }
        }
    }, [lastAction, userWantsToSaveMap, user]);

    const loadMapFromFile = useCallback(async () => {
        const input = document.createElement('input');
        input.type = 'file';

        input.onchange = e => {
            const file = e.target.files[0];

            mapFileName.set(file?.name?.slice(0, -5) || "");

            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = async readerEvent => {
                let data = JSON.parse(readerEvent.target.result + "");
                // console.log(data.actionHistory.map(action => [action.type, action.uuid, action.time].join(" | ")));
                data = updateMap(data);
                if (data === "unsupported") {
                    toast.error(locale?.get.studio.errors.obsoleteMapLoadingFailed);
                    return undefined;
                }
                data = await validateMap(data);
                if (data === "invalid") {
                    toast.error(locale?.get.studio.errors.incorrectMapLoadingFailed);
                    return undefined;
                }

                setDataToLoading(data);
                setIsLoading(true);
                setMapId(undefined); //map from computer has no database id
            }
        };
        input.click();
    }, [setMapId]);

    const saveMap = useCallback(async (type) => {
        try {
            const map = convertStateToSave(state);
            const blobSnapshot = await takePngSnapshotOfTheCanvas();
            const data = {
                name: map.main.mapName,
                id: map.main.mapId,
                data: JSON.stringify(map),
                png: (await blobToBase64(blobSnapshot)).slice(22)
            };
            if (type === "auto") {
                if (mapId && isChanged) {
                    await api.put(`/api/v1/map/${mapId}`, data);
                }
            } else {
                if (mapId) {
                    await api.put(`/api/v1/map/${mapId}`, data);
                } else {
                    const resp = await api.post("/api/v1/map/", data);

                    if (resp.data.id === -1) {
                        toast.error(locale.get.studio.errors.noSpaceLeft);
                        return;
                    }

                    setMapId(resp.data.id);
                }
            }
        } catch (e) {
            if (type !== "auto") {
                toast.warn(e);
            }
        }
    }, [mapId, state, setMapId, api, dispatch, isChanged]);

    const shareMap = useCallback(async () => {
        if (!mapId) {
            toast.warn(locale?.get.studio.sharing.sendToServerRequired);
            return;
        }
        if (isChanged) {
            await saveMap();
        }
        try {
            const resp = await api.post(`/api/v1/map/${mapId}/share/`);
            return `https://creativemaps.studio/share?share_token=${encodeURIComponent(resp.data)}`;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                toast.warn(locale?.get.studio.sharing.authRequired);
            }
        }
    }, [api, state, mapId, isChanged]);

    const newMap = useCallback(async () => {
        setMapId(undefined);
        dispatch({type: "clearMenuState"});
        dispatch({type: "clearState", customName: locale?.get.studio.stdMapName});
        mapFileName.set("");
        dispatch(ActionCreators.clearHistory());
    }, [dispatch, setMapId, locale]);

    const loadMap = useCallback(async id => {
        try {
            if (!isChanged || window.confirm(locale.get.studio.confirmations.changesWillLose)) {
                const resp = await api.get(`/api/v1/map/${id}`);
                let data = resp.data.data;
                data = updateMap(data);
                if (data === "unsupported") {
                    toast.error(locale?.get.studio.errors.obsoleteMapLoadingFailed);
                    return undefined;
                }

                data = await validateMap(data);
                if (data === "invalid") {
                    toast.error(locale?.get.studio.errors.incorrectMapLoadingFailed);
                    return undefined;
                }

                setDataToLoading(data);
                setIsLoading(true);
                setMapId(id);
            }
        } catch (err) {
            if (err.response.status === 404) {
                toast.error(locale?.get.studio.errors.mapNotFound);
            }
        }
    }, [setMapId, api, locale, isChanged]);

    const loadMapCopy = useCallback(async (id, version) => {
        try {
            if (!isChanged || window.confirm(locale.get.studio.confirmations.changesWillLose)) {
                const resp = await api.get(`/api/v1/map/shared/${id}/${version}`);
                let data = resp.data.data;
                data = updateMap(data);
                if (data === "unsupported") {
                    toast.error(locale?.get.studio.errors.obsoleteMapLoadingFailed);
                    return undefined;
                }

                data = await validateMap(data);
                if (data === "invalid") {
                    toast.error(locale?.get.studio.errors.incorrectMapLoadingFailed);
                    return undefined;
                }

                setDataToLoading(data);
                setIsLoading(true);
                setMapId(null);
            }
        } catch (err) {
            if (err.response.status === 404) {
                toast.error(locale?.get.studio.errors.mapNotFound);
            }
        }
    }, [setMapId, api, locale, isChanged]);

    const loadSharedMap = useCallback(async share_token => {
        try {
            if (!isLoading) {
                const resp = await api.get(`/api/v1/map/shared/`, {params: {share_token}});
                let data = resp.data.data;
                // console.log(resp.data.map(m => `${m.id}, ${m.uuid}`)); //test for watching all maps
                data = updateMap(data);
                if (data === "unsupported") {
                    toast.error(locale?.get.studio.errors.obsoleteMapLoadingFailed);
                    return undefined;
                }

                data = await validateMap(data);
                if (data === "invalid") {
                    toast.error(locale?.get.studio.errors.incorrectMapLoadingFailed);
                    return undefined;
                }

                setDataToLoading(data);
                setIsLoading(true);
            }
        } catch (err) {
            if (err.response.status === 404) {
                toast.error(locale?.get.studio.errors.mapNotFound);
            } else if (err.response.status === 400) {
                toast.error(locale?.get.studio.errors.tokenIsIncorrect);
            }
        }
    }, [api, locale]);

    const deleteMap = useCallback(async id => {
        try {
            let response = await api.delete(`/api/v1/map/${id}`);
            if (mapId === id && response.status === 200 && !response.data["retained versions"]) { //if whole current map deleted
                setMapId(undefined);
            }
            return response;
        } catch (e) {
            toast.error(locale?.get.studio.errors.mapCannotBeDeleted);
            return e.response;
        }
    }, [mapId, setMapId, api]);

    const loadMapBackgroundFromFS = useCallback(async fitType => {
        const input = document.createElement('input');
        input.type = 'file';

        input.onchange = e => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = readerEvent => {
                let data = readerEvent.target.result;
                dispatch({type: "backgroundImage", value: data});

                const img = document.createElement("img");
                img.onload = () => {
                    if (fitType === "fix") {
                        dispatch({type: "canvasWidth", value: img.width});
                        dispatch({type: "canvasHeight", value: img.height});
                    }
                };
                img.src = data;
            }
        };
        input.click();
    }, []);

    useEffect(() => {
        if (!isLoading && window.location.pathname !== "/share") {
            const autosave = localStorage.getItem("autosave");
            if (autosave) {
                const data = JSON.parse(autosave);
                if (!['saveToServer', 'saveToComputer', 'createMap'].includes(data.main.lastAction.type)) {
                    setDataToLoading(data);
                    setIsLoading(true);
                }
            }
        }
    }, []);

    // console.log(lastAction);

    return (
        <MapContext.Provider value={{
            isChanged,
            setSaveMapFlag,
            mapId,
            saveMap,
            newMap,
            loadMap,
            loadMapCopy,
            loadSharedMap,
            shareMap,
            deleteMap,
            loadMapFromFile,
            loadMapBackgroundFromFS,
        }}>
            {children}
            {isLoading ? <MapLoader data={dataToLoading} setIsLoading={setIsLoading}/> : null}
        </MapContext.Provider>
    );
};


export const useMap = () => useContext(MapContext);
