import React from "react";
import {useDispatch} from "react-redux";

import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useReduxData} from "../../customHooks/useContextReduxData";
import getTimeElapsedSince from "../getTimeElapsedSince";


export default function TextArea() {
    const locale = useLocalization();
    const {isEditMode, selectedObject: {isTheOnlyObjectSelected, figureUuid, figureDescription}, startTime} = useReduxData();

    const dispatch = useDispatch();

    const handleTextChanged = event => dispatch({
        type: "changeObjectDescription",
        uuid: figureUuid,
        description: event.target.value,
        time: getTimeElapsedSince(startTime)
    });

    const closeMenu = event => event.key === "Enter" && dispatch({type: "openedMenuItemNumber", value: "-1"});

    return <textarea
        className="tool-menu-text-area"
        id="tool-menu-description"
        disabled={!isEditMode || !isTheOnlyObjectSelected}
        value={isTheOnlyObjectSelected ? figureDescription : ""}
        placeholder={locale?.get.studio.floatingMenu.textAreaPlaceholder}
        onChange={handleTextChanged}
        onKeyDown={closeMenu}
    />;
}
