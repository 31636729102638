import React, {useState, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {flattenDeep} from "lodash";

import Figure from "../figures/Figure";
import ConnectorContainer from "../connectors/ConnectorContainer.js";
import {useReduxData} from "../../customHooks/useContextReduxData";
import useObjectMoving from "./customHooks/useObjectMoving";
import {getChildrenValues} from '../../pureFunctions/usefulFunctions';


export default function SelectedFigureContainer({isMouseDownOnCanvas, handleMouseEnterOnConnector}) {
    const {canvasHeight, canvasWidth, figures, isEditMode, scale, selectedFigureUuids, startTime,} = useReduxData();
    const {startMovingObject, moveObject} = useObjectMoving();

    const dispatch = useDispatch();

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isFigureMoved, setIsFigureMoved] = useState(false);
    const [shouldSelectedHide, setShouldSelectedHide] = useState(false);
    const [selectedLeaves, setSelectedLeaves] = useState([]);
    const [position, setPosition] = useState({x: 0, y: 0, coords: {},});

    useEffect(() => {
        let selectedLeavesL = [];
        selectedFigureUuids.map(u => {
            if (figures.get(u).get("tool").split("-")[0] !== "g") {
                selectedLeavesL.push(u);
            } else {
                selectedLeavesL = selectedLeavesL.concat(flattenDeep([getChildrenValues(figures, u, "uuid")]));
            }
            return undefined;
        });
        setSelectedLeaves(selectedLeavesL);
    }, [selectedFigureUuids, figures]);

    const handleMouseDownOnSelectedFiguresContainer = event => {
        if ((event.target.x || event.target.cx || event.target.parentNode.parentNode.x)
            && isEditMode && event.button === 0
        ) {
            let movingType = startMovingObject(event.target, event.pageX, event.pageY, figures, scale, selectedFigureUuids);
            if (["figure", "transformational-connector"].includes(movingType)) {
                setIsMouseDown(true);
                if (movingType === "figure")
                    setShouldSelectedHide(true);
                else {
                    setIsFigureMoved(true);
                }
            }
        }
    };

    const handleMouseMoveOnSelectedFiguresContainer = event => {
        if (isMouseDown && isEditMode && !shouldSelectedHide) {
            moveObject(event.pageX, event.pageY, figures, scale, selectedFigureUuids, dispatch, startTime);
        }
    };

    const handleMouseUpOnSelectedFiguresContainer = event => {
        if (isMouseDown && isEditMode) {
            moveObject(event.pageX, event.pageY, figures, scale, selectedFigureUuids, dispatch, startTime);
        }

        setIsMouseDown(false)
        setIsFigureMoved(false);
        setShouldSelectedHide(false);

        //finish moving figures
        window.removeEventListener('mousemove', handleFiguresMove.current);
        // Use Object.assign to do a shallow merge so as not to
        // totally overwrite the other values in state.
        setPosition(position => Object.assign({}, position, {x: 0, y: 0, coords: {}}));
    };

    const handleFiguresMove = useRef(event => setPosition(position => {
        const xDiff = position.coords.x - event.pageX;
        const yDiff = position.coords.y - event.pageY;
        return {x: position.x - xDiff, y: position.y - yDiff, coords: {x: event.pageX, y: event.pageY}};
    }));

    const handleFiguresMoveStart = event => {
        if (event.button === 0 && isEditMode) {
            const pageX = event.pageX;
            const pageY = event.pageY;
            setPosition(position => Object.assign({}, position, {coords: {x: pageX, y: pageY}}));
            window.addEventListener('mousemove', handleFiguresMove.current);
        }
    };

    return <svg id="selectedFigureContainer"
        onMouseDown={handleMouseDownOnSelectedFiguresContainer}
        onMouseMove={handleMouseMoveOnSelectedFiguresContainer}
        onMouseUp={handleMouseUpOnSelectedFiguresContainer}
    >
        {isFigureMoved && (<rect
            x="0"
            y="0"
            width={scale * canvasWidth + "px"}
            height={scale * canvasHeight + "px"}
            fill="black"
            opacity="0"
        />)} {/*фон для перемещения объектов*/}
        <svg x={position.x} y={position.y} onMouseDown={handleFiguresMoveStart}>
            {selectedFigureUuids && selectedFigureUuids.map(uuid => figures.get(uuid) && <Figure
                key={figures.get(uuid).get("uuid")}
                figure={figures.get(uuid)}
                kind={"separate"}
                isMouseDown={isMouseDown}
            />)}
        </svg>
        {isEditMode
            ? !shouldSelectedHide && selectedLeaves.map(uuid => (
                figures.get(uuid) &&
                !["g", "i"].includes(figures.get(uuid).get("tool").split("-")[0]) &&
                <ConnectorContainer
                    x={figures.get(uuid).get("x") * scale - 20}
                    y={figures.get(uuid).get("y") * scale - 20}
                    key={figures.get(uuid).get("uuid")}
                    parentFigure={figures.get(uuid)}
                    figures={figures}
                    isMouseDown={isMouseDownOnCanvas}
                    handleMouseEnter={handleMouseEnterOnConnector}
                />
            ))
            : [...figures].map(([uuid, figure]) => (
                !["g", "i"].includes(figure.get("tool").split("-")[0]) &&
                <ConnectorContainer
                    x={figure.get("x") * scale - 20}
                    y={figure.get("y") * scale - 20}
                    key={uuid}
                    parentFigure={figure}
                    figures={figures}
                    isMouseDown={isMouseDownOnCanvas}
                    handleMouseEnter={handleMouseEnterOnConnector}
                />
            ))
        }
    </svg>;
}
