import React from "react";

import Other from "./dataDownloader/Other";
import MainPage from "./mainPage/MainPage";
import {useCurrentUser} from "../customHooks/useContextCurrentUser";

import "./research.css";
import "./mainPage/processingAndAnanysis/dfd/dfd.css";


export default function ResearchTabContainer() {
    const user = useCurrentUser();

    return user && user.is_analyst
        ? <>
            <a href="/" className="research-link-to-studio">← Studio</a>
            <input
                type="radio"
                className="research-tab-hidden-input"
                id="research-tab-hidden-input-main"
                name="research-tab-hidden-input"
                defaultChecked
            />
            <label className="research-tab-label" htmlFor="research-tab-hidden-input-main">Main page</label>
            <input
                type="radio"
                className="research-tab-hidden-input"
                id="research-tab-hidden-input-wfpm"
                name="research-tab-hidden-input"
            />
            <label className="research-tab-label" htmlFor="research-tab-hidden-input-wfpm">Other</label>

            <MainPage/>
            <Other/>
        </>
        : "forbidden 403";
}
