import NodeDragSource from "../dnd/NodeDragSource";
import MenuItemHint from "./MenuItemHint";
import NodeMenuItem, {HintedNodeMenuItem} from "./NodeMenuItem";
import MenuPartitionDelimiter from "./MenuPartitionDelimiter";
import NodeMenuFilter from "./NodeMenuFilter";
import {useNodeFilter} from "./hooks/useNodeFilter";
import dndItems from "../dnd/dndItems";


export default function ({runPipeline, loadingStatus}) {
    const {convertersToShow, metricsToShow} = useNodeFilter();

    // console.log(availableConverters);
    // console.log(availableMetrics);

    return <div className="research-node-menu-container">
        <NodeMenuFilter/>
        <div className="research-node-menu-items-container">
            <NodeDragSource type={dndItems.sourceNode} name="Data Source" inputs={[]}>
                <NodeMenuItem name="Data Source"/>
            </NodeDragSource>
            {convertersToShow.length > 0 && <MenuPartitionDelimiter name="Converters"/>}
            {convertersToShow.map((el, i) =>
                <NodeDragSource
                    key={`converters-${i}`}
                    type={dndItems.intermediateNode}
                    name={el.name}
                    inputs={el.inputs}
                    outputs={el.outputs}
                >
                    <HintedNodeMenuItem
                        name={el.name}
                        tooltipContent={<MenuItemHint inputs={el.inputs} outputs={el.outputs}/>}
                    />
                </NodeDragSource>
            )}
            {metricsToShow.length > 0 && <MenuPartitionDelimiter name="Visualizers"/>}
            {metricsToShow.map((el, i) =>
                <NodeDragSource
                    key={`metric-${i}`}
                    type={dndItems.finalNode}
                    name={el.name}
                    inputs={el.inputs}
                    outputs={[]}
                >
                    <HintedNodeMenuItem name={el.name} tooltipContent={<MenuItemHint inputs={el.inputs}/>}/>
                </NodeDragSource>
            )}
            <input className="research-node-menu-run-pipeline" type="button" value="RUN!" onClick={runPipeline}/>
            <label className="research-node-menu-progress-bar">{loadingStatus}</label>
        </div>
    </div>;
}