import React from "react";
import {useDispatch} from "react-redux";
import {Emoji} from 'emoji-mart';

import {useReduxData} from "../../customHooks/useContextReduxData";
import {getChildrenValues, setFixedChildrenValues, flattenEmojis} from '../../pureFunctions/usefulFunctions';
import getTimeElapsedSince from "../getTimeElapsedSince";

import 'emoji-mart/css/emoji-mart.css';


export default function EmojiContainer({toggleEmojiPickerRerenderSign}) {
    const {getFiguresUpdatedOn, selectedFigureUuids, startTime} = useReduxData();
    const figures = getFiguresUpdatedOn([
        "addPolygon",
        "addPolyline",
        "addPointFeature",
        "addGroup",
        "changeObjectParentUuid",
        "addFigureEmoji",
        "deleteFigureEmoji",
        "clearState",
        "deleteFigure",
        "disbandGroup"
    ]);

    const dispatch = useDispatch();

    const handleClick = emoji => {
        selectedFigureUuids.map(uuid => setFixedChildrenValues(
            figures,
            uuid,
            ["emoji"],
            dispatch,
            [{emoji: emoji}],
            "deleteFigureEmoji",
            getTimeElapsedSince(startTime)
        ));
        toggleEmojiPickerRerenderSign();
    };

    let emojiArray = [];

    if (selectedFigureUuids.length !== 0) {
        selectedFigureUuids.map(uuid => {
            let figure = figures.get(uuid);
            let type = figure.get("tool").split("-")[0];
            if (emojiArray.length === 0 && (figure.get("emoji") !== undefined || type === "g")) {
                emojiArray = flattenEmojis(getChildrenValues(figures, uuid, "emoji"))[0];
            }
            if (["p", "i"].includes(type)) {
                emojiArray = emojiArray.filter(value =>
                    figure.get("emoji").map(el => el['emoji']['id']).indexOf(value['emoji']['id']) !== -1
                );
            } else if (type === "g") {
                let tempArr = flattenEmojis(getChildrenValues(figures, uuid, "emoji"));
                if (tempArr[0] !== undefined && tempArr[0].length !== 0) {
                    tempArr.map(emArr => emojiArray = emojiArray.filter(
                        value => emArr.map(el => el['emoji']['id']).indexOf(value['emoji']['id']) !== -1
                    ));
                }
            }
            return undefined;
        });
    }

    if (emojiArray === undefined) {
        emojiArray = [];
    }

    return <div className="tool-menu-emoji-container">
        {emojiArray.map((em, i) =>
            <Emoji key={`container-emoji-${i}`} emoji={em.emoji} set="google" size={30} onClick={handleClick}/>
        )}
    </div>;
}
