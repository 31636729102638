import React, {useState} from 'react';


export default function BaseConnector(props) {
    const [isConnectorCaptured, setIsConnectorCaptured] = useState(false);

    const handleMouseDown = () => setIsConnectorCaptured(true);
    const handleMouseUp = () => setIsConnectorCaptured(false);

    return <rect
        className="transformational-connector"
        id={`${props.parentUuid}|${props.segmentIndex}`}
        x={props.x}
        y={props.y}
        width="8"
        height="8"
        stroke="black"
        opacity={props.opacity}
        fill={isConnectorCaptured ? "red" : "silver"}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
    />;
}
