import React from "react";


export default function RectangleSelection({x, y, width, height}) {
    if (width < 0) {
        x += width;
        width *= -1;
    }
    if (height < 0) {
        y += height;
        height *= -1;
    }

    return <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill="none"
        stroke="black"
        strokeDasharray="0.25rem"
        strokeWidth="0.0625rem"
        style={{pointerEvents: "none"}}
    />;
}
