import React, {useEffect, useState} from "react";
import Tooltip from "react-tooltip-lite";

import {useHintMode} from "../studio/customHooks/useContextHintMode";
import {useLocalization} from "../customHooks/useContextLocalization";


export function Hint (props) {
    return <Tooltip
        content = {<span className = "tooltip-content">{props.content}</span>}
        className = {props.className || ""}
        arrow = {false}
        direction = {props.direction}
        tipContentHover = {props.tipContentHover}
        padding = {props.padding}
        styles = {props.styles}
    >
        {props.children}
    </Tooltip>;
}


export default function tooltipHOC (ChildComponent, localeKey, customWrapperStyles, params) {
    return (props) => {
        const hintMode = useHintMode();
        const locale = useLocalization();

        const [shouldShowHint, setShouldShowHint] = useState(true);

        //This is needed to re-render the tooltip when changing the localization
        //See more: https://github.com/bsidelinger912/react-tooltip-lite/issues/31
        useEffect(() => {
            const showHint = () => {
                setShouldShowHint(true);
            };

            setShouldShowHint(false);
            setTimeout(showHint, 200);
        }, [locale?.get]);

        return (!hintMode?.get && props.tooltipContent && shouldShowHint)
            ? (<Hint
                content = {props.tooltipContent}
                className = "no-pointer-events-tooltip"
                direction = {params?.direction || "bottom-start"}
                padding = {params?.padding || "0"}
                tipContentHover = {false}
                styles = {customWrapperStyles || props.tooltipStyle || {}}
            >
                <ChildComponent {...props}/>
            </Hint>)
            : (<ChildComponent {...props}/>);
    }
}
