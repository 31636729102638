import React, {useState, useEffect} from "react";
import {Footer, Icon} from "@semograph/semui";

import SocialComponent from "./SocialComponent";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCategories} from "../../customHooks/categories";

import footerStyles from './footer.module.css';

import {ReactComponent as Logo} from "../../../images/logo.svg";


function MyFooter() {

    const locale = useLocalization();
    const categories = useCategories();

    const [specificItems, setSpecificItems] = useState([]);

    useEffect(() => {
        if (locale?.get && categories) {
            setSpecificItems(
                categories.slice(1).map(cat => ({
                    className: cat.key,
                    link: `/store/${cat.key}`,
                    content: locale.get.store.footer[cat.key]
                })).concat([{
                    className: "creative-maps",
                    link: "/",
                    content: (<>
                        Creative Maps Studio <span>￫</span>
                    </>),
                }])
            );
        }
    }, [locale, categories]);

    return (<Footer
        logo = {{
            link: "/store",
            content: <div className = {footerStyles["logo-text"]}/>
        }}
        renderIcon ={(props) => <Icon icon={Logo} {...props} />}
        viewBox="0 0 40 40"
        description = {locale.get?.store.footer.description}
        copyright = {`Copyright 2018-${new Date().getFullYear()} StudioMaps`}
        specificItems = {specificItems}
        baseItems = {[
            {
                link: "/store/contacts",
                content: locale.get?.store.footer.contacts,
            },
            {
                link: "/store/manual",
                content: locale.get?.store.footer.manual,
            },
            {
                link: "/store/confidentiality",
                content: locale.get?.store.footer.confidentiality,
            },
            {
                link: "/store/conditions",
                content: locale.get?.store.footer.conditions,
            }
        ]}
        socialComponent = {<SocialComponent/>}
    />);
}

export default MyFooter;
