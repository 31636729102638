import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

import Scrollbar from "../../../components/Scrollbar";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useMap} from "../../customHooks/useContextMap";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useModal} from "../../../customHooks/useContextModal";
import {deleteAt, replaceAt} from "../../../pureFunctions";


export function MapPreview({map, setMaps, setMapCount, maps, groupIndex, mapIndex, changeMapSelection, canLoadOriginal}) {
    const map_ = useMap();
    const locale = useLocalization();
    const modal = useModal();
    const api = useApiClient();

    const dispatch = useDispatch();

    const clickHandler = useCallback(() => {
        dispatch({type: "clearMenuState"});
        dispatch({type: "clearState"});
        if (canLoadOriginal) {
            map_.loadMap(map.id);
        } else {
            map_.loadMapCopy(map.id, map.version);
        }
        modal.set("");
    }, [map.id]);

    const deleteMap = async e => {
        e.stopPropagation(); //we don't need to load deleted map
        if (window.confirm(locale.get.studio.confirmations.deleteMap)) {
            const response = await map_.deleteMap(map.id);
            if (response.status === 200) {
                if (response.data["deleted versions"]) {
                    if (response.data["deleted versions"] === "all") {
                        toast.success(locale.get.studio.myMaps.allMapsDeleted);
                    } else {
                        toast.success(`${locale.get.studio.myMaps.deletedMaps}${response.data["deleted versions"]}`);
                    }
                }
                if (!response.data["retained versions"]) {
                    const newGroupMaps = deleteAt(maps[groupIndex].maps, mapIndex);
                    setMaps(replaceAt(maps, groupIndex, {title: maps[groupIndex].title, maps: newGroupMaps}));
                    setMapCount(prev => prev - 1);
                } else {
                    if (response.data["retained versions"] === "all") {
                        toast.warn(locale.get.studio.myMaps.allMapsRetained);
                    } else {
                        const lastRetainedMapVersion = (await api.get(`/api/v1/map/lite/${map.id}`)).data;
                        const newGroupMaps = replaceAt(maps[groupIndex].maps, mapIndex, lastRetainedMapVersion);
                        setMaps(replaceAt(maps, groupIndex, {title: maps[groupIndex].title, maps: newGroupMaps}));
                        setMapCount(prev => prev - 1);
                        toast.warn(`${locale.get.studio.myMaps.retainedMaps}${response.data["retained versions"]}`);
                    }
                }
            }
        }
    };

    return <div
        className="map-preview"
        onClick={clickHandler}
        style={{
            background: `url("/maps/${map.uuid}.preview.${map.version}.png") 100% 100% no-repeat`,
            backgroundSize: "100% 100px"
        }}
    >
        <div className="map-preview-header">
            <input
                id={`custom-checkbox-input-${map.id}-${map.version}`}
                className="control-checkbox-hidden-input"
                type="checkbox"
                name={`custom-checkbox-input-${map.id}-${map.version}`}
                value=""
                onClick={changeMapSelection}
            />
            <label
                className="control-checkbox-label"
                htmlFor={`custom-checkbox-input-${map.id}-${map.version}`}
                onClick={changeMapSelection}
            />
            {map.name}
            {map.can_delete && <input type="button" className="map-preview-delete" onClick={deleteMap}/>}
        </div>
    </div>;
}


export default function Maps({mapsLink, title, noMapsMessage, showSpace}) {
    const api = useApiClient();
    const locale = useLocalization();

    const [maps, setMaps] = useState([]);
    const [spaceInfo, setSpaceInfo] = useState("");
    const [mapCount, setMapCount] = useState(0);
    const [selectedMaps, setSelectedMaps] = useState([]);

    useEffect(async () => {
        let resp = await api.get(mapsLink);
        resp.data.sort((l,  r) => new Date(r.created) - new Date(l.created));
        setMapCount(resp.data.length);
        let currentDate = new Date(),
            currentYear = currentDate.getFullYear(),
            currentMonth = currentDate.getMonth();

        let groupedMaps = [];
        resp.data.map(map => {
            let lastGroup = groupedMaps?.[groupedMaps.length - 1];
            let mapDate = new Date(map.created);
            let lastMapDate = lastGroup ? new Date(lastGroup.maps[0].created) : undefined;

            if (
                !lastGroup
                || (lastMapDate.getMonth() !== mapDate.getMonth() && mapDate.getFullYear() === currentYear)
                || lastMapDate.getFullYear() !== mapDate.getFullYear()
            ) {
                groupedMaps.push({
                    title: currentYear === mapDate.getFullYear()
                        ? currentMonth === mapDate.getMonth()
                            ? locale?.get?.studio.myMaps.thisMonth
                            : locale?.get?.studio.myMaps.months[mapDate.getMonth()]
                        : `${mapDate.getFullYear()} ${locale?.get?.studio.myMaps.year}`,
                    maps: [map]
                });
            } else { //write current map in the last group
                lastGroup.maps = lastGroup.maps.concat([map]);
            }
        });
        setMaps(groupedMaps);
    }, [api, locale, mapCount]);

    useEffect(async () => {
        if (showSpace && locale?.get) {
            let resp = await api.get("/api/v1/map/space_for_maps/");
            setSpaceInfo(`(${resp.data.spaceUsed}/${resp.data.wholeSpace} MB ${locale.get.studio.myMaps.spaceUsed})`);
        }
    }, [api, locale]);

    const changeMapSelection = event => {
        event.stopPropagation(); //we don't need to load selected map

        const mapId = event.target.id.split("-")[event.target.id.split("-").length - 2];
        const mapVersion = event.target.id.split("-")[event.target.id.split("-").length - 1];
        const mapIdentifier = `${mapId}-${mapVersion}`;
        if (mapId && mapVersion) {
            setSelectedMaps(prev => {
                if (!prev.includes(mapIdentifier)) {
                    return prev.concat([mapIdentifier]);
                } else {
                    let mapIndex = prev.indexOf(mapIdentifier);
                    return deleteAt(prev, mapIndex);
                }
            });
        }
    };

    const removeSelectedMaps = async () => {
        try {
            if (confirm(locale?.get.studio.myMaps.deleteConfirmation)) {
                const formData = new FormData();
                formData.append("maps_to_delete", selectedMaps.join());
                const response = await api.delete(`/api/v1/map/maps/${selectedMaps.join()}`);
                if (response) {
                    toast.success(locale?.get.studio.myMaps.selectedMapsDeleted);
                    setMapCount(-1);  // to update page
                }
            }
        } catch (err) {
            if (err.response.status === 400) {
                toast.error(locale?.get.studio.errors.deletionMapError);
            }
        }

    };

    return maps.length
        ? <>
            <div className="my-maps-title-container">
                <span className="my-maps-title">{title} {spaceInfo}</span>
                <div className="my-maps-selection-container">
                    <span className="my-maps-title">
                        {locale.get.studio.myMaps.mapsSelected} {`${selectedMaps.length}/${mapCount}`}
                    </span>
                    {selectedMaps.length > 0 && <input
                        className="my-maps-delete"
                        type="button"
                        value={locale.get.studio.myMaps.removeSelectedMaps}
                        onClick={removeSelectedMaps}
                    />}
                </div>
            </div>
            <Scrollbar
                autohide={false}
                classNamePrefix="my-maps-"
                style={{height: "calc(40vh - 3rem)", width: "60vw", marginTop: "1rem"}}
            >
                {maps.map((mapGroup, i) => <React.Fragment key={`my-maps-group-${i}`}>
                    <span className="my-maps-partition-title">{mapGroup.title}</span>
                    <div className="my-maps">
                        {mapGroup.maps.map((map, j) => <MapPreview
                            key={`${map.id}|${map.version}`}
                            map={map}
                            setMaps={setMaps}
                            setMapCount={setMapCount}
                            maps={maps}
                            mapIndex={j}
                            groupIndex={i}
                            changeMapSelection={changeMapSelection}
                            canLoadOriginal={mapsLink === "/api/v1/map/"}
                        />)}
                    </div>
                </React.Fragment>)}
            </Scrollbar>
        </>
        : <div className="my-maps-no-maps-container">{noMapsMessage}</div>;
}
