import React from "react";

import ContextMenuButton from "./ContextMenuButton";
import ColorPicker from "./ColorPicker";
import EmojiPicker from "./EmojiPicker";
import ContextMenuSlider from "./ContextMenuSlider";
import ContextMenuToolWrapper from "./ContextMenuToolWrapper";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../../customHooks/useContextReduxData";


export default function ContextMenu () {
    const locale = useLocalization();
    const {figureCenter, isContextMenuShow, isThereSelectedPointFeature, isThereSelectedPolyline} = useReduxData();

    return isContextMenuShow !== "no" ? <div
        className="context-menu"
        style={{marginTop: `${figureCenter.y}px`, marginLeft: `${figureCenter.x}px`}}
    >
        {["figure", "group", "text", "figure-neighborhood"].includes(isContextMenuShow)
            ? <div className="context-menu-row">
                <ContextMenuButton
                    image="url(../../../../images/toolMenu/icon-emoji.svg)"
                    type="emoji"
                    disabled={isThereSelectedPolyline}
                    tooltipContent={locale?.get.studio.floatingMenu.emoji}
                />
                <ContextMenuButton
                    image={`url(../../../../images/toolMenu/icon-${isContextMenuShow === "text" ? "text" : "figure"}-color-black.svg)`}
                    type={isContextMenuShow === "text" ? "fontColor" : "color"}
                    disabled={false}
                    tooltipContent={locale?.get.studio.floatingMenu[isContextMenuShow === "text" ? "fontColorHint" : "colorHint"]}
                />
                <ContextMenuButton
                    image="url(../../../../images/toolMenu/icon-font-size.svg)"
                    type="fontSize"
                    disabled={isThereSelectedPolyline}
                    tooltipContent={locale?.get.studio.floatingMenu.fontSizeHint}
                />
                <ContextMenuButton
                    image="url(../../../../images/toolMenu/icon-transparency.svg)"
                    type="transparency"
                    disabled={false}
                    tooltipContent={locale?.get.studio.floatingMenu.transparencyHint}
                />
                <ContextMenuButton
                    image="url(../../../../images/toolMenu/icon-radius.svg)"
                    type="volume"
                    disabled={!isThereSelectedPointFeature}
                    tooltipContent={locale?.get.studio.floatingMenu.sizeHint}
                />
                <ContextMenuButton
                    image="url(../../../../images/toolMenu/icon-thickness.svg)"
                    type="thickness"
                    disabled={!isThereSelectedPolyline}
                    tooltipContent={locale?.get.studio.floatingMenu.thicknessHint}
                />
            </div> : null
        }
        <ContextMenuToolWrapper tools={["color", "fontColor"]}><ColorPicker/></ContextMenuToolWrapper>
        <ContextMenuToolWrapper tools={["emoji"]}><EmojiPicker/></ContextMenuToolWrapper>
        <ContextMenuToolWrapper tools={["fontSize"]}>
            <ContextMenuSlider
                title={locale?.get.studio.floatingMenu.fontSize}
                action={"changeFigureFontSize"}
                purpose="fontSize"
                min="-1"
                max="20"
                step="1"
            />
        </ContextMenuToolWrapper>
        <ContextMenuToolWrapper tools={["thickness"]}>
            <ContextMenuSlider
                title={locale?.get.studio.floatingMenu.thickness}
                action={"changeFigureThickness"}
                purpose="thickness"
                min="1"
                max="5"
                step="0.5"
            />
        </ContextMenuToolWrapper>
        <ContextMenuToolWrapper tools={["volume"]}>
            <ContextMenuSlider
                title={locale?.get.studio.floatingMenu.size}
                action={"changeFigureVolume"}
                purpose="volume"
                min="5"
                max="70"
                step="5"
            />
        </ContextMenuToolWrapper>
        <ContextMenuToolWrapper tools={["transparency"]}>
            <ContextMenuSlider
                title={locale?.get.studio.floatingMenu.transparency}
                action={"changeFigureTransparency"}
                purpose="transparency"
                min="0"
                max="1"
                step="0.1"
            />
        </ContextMenuToolWrapper>
    </div> : null;
}
