import {useEffect} from "react";

import {useApiClient} from "../customHooks/useApiClient";
import {useCurrentUser} from "../customHooks/useContextCurrentUser";
import {useModal} from "../customHooks/useContextModal";
import {useReduxData} from "./customHooks/useContextReduxData";


export function isAgreementAccepted() {
    return document.cookie?.split('; ')?.find(row => row.startsWith('agreement'))?.split('=')[1] === "true";
}

export default function AgreementShowingHandler () {
    const user = useCurrentUser(true);
    const api = useApiClient();
    const modal = useModal();
    const {lastAction} = useReduxData();

    useEffect(() => (async () => {
        //don't show the agreement immediately (it should to wait for the server response, if the user is authorized)
        if (lastAction.type === "createMap") {
            return undefined;
        }

        if (!isAgreementAccepted()) {
            if (user) {
                const resp = await api.get("/api/v1/agreement/");
                if (resp.data.accepted) {
                    document.cookie = "agreement=true";
                } else {
                    modal.set("license_agreement");
                }
            } else {
                modal.set("license_agreement");
            }
        }
    }), [lastAction, user]);

    return null;
}
