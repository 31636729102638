import Path from "../../../../pureFunctions/path";


/**
 * Returns reduced path from points
 * @param  {array<string>}   points  The point array, like this: ["1,5", "4,6", "10,32"]
 * @return {string}                  The reduced path
 */
export function createReducedPath(points) {
    let reducedPoints = Path.excludeDuplicatePoints(points);
    if (reducedPoints.length >= 3) {
        let path = Path.createFromPoints(reducedPoints);
        path = Path.reduceSimplePath(path, 0, 2.5, 16);
        return path;
    } else {
        return "";
    }
}

/**
 * Returns reduced path from points
 * @param  {string}   firstPath   The first path
 * @param  {string}   secondPath  The second path
 * @return {string}               The combined path
 */
export function concatTwoPaths(firstPath, secondPath) {
    return secondPath ? (firstPath ? firstPath + " L" + secondPath.slice(1) : secondPath) : firstPath;
}


/**
 * Returns if target active snapping connector or not
 * @param  {Node}   target   The target DOM node
 * @return {boolean}         true if target is an active snapping connector, false otherwise
 */
export function isActiveSnappingConnector(target) {
    return target.className.baseVal === "snapping-connector"
        && target.id.split("|")[0] !== "closing-snapping-connector"
        && target.getAttribute("opacity") === "1"
}

/**
 * Returns if target active closing connector or not
 * @param  {Node}   target   The target DOM node
 * @return {boolean}         true if target is an active closing connector, false otherwise
 */
export function isActiveClosingConnector(target) {
    return target.className.baseVal === "snapping-connector"
        && target.id.split("|")[0] === "closing-snapping-connector"
        && target.getAttribute("opacity") === "1"
}

/**
 * Get connector center coordinates
 * @param  {Node}   target          The target DOM node
 * @return {{x: number, y: number}} true if target is an active snapping connector, false otherwise
 */
export function getConnectorCenterCoo(target) {
    if (isActiveSnappingConnector(target) || isActiveClosingConnector(target)) {
        const connectorXCoo = target.cx.baseVal.value;
        const connectorYCoo = target.cy.baseVal.value;
        const figureXCoo = target.parentNode.x.baseVal.value;
        const figureYCoo = target.parentNode.y.baseVal.value;
        const pointX = (figureXCoo + connectorXCoo);
        const pointY = (figureYCoo + connectorYCoo);
        return {x: pointX, y: pointY};
    } else {
        return {x: NaN,  y: NaN};
    }
}