import React, {useState} from "react";
import * as isUUID from "is-uuid";

import RectangleSelection from "./RectangleSelection";
import {useCanvasBoundingClientRect} from "../../../../customHooks/useContextCanvasBoundingClientRect";
import {getUuidHierarchy, isPointIntoRectangle} from "../../../../pureFunctions/usefulFunctions";
import Path from "../../../../pureFunctions/path";
import {replaceAt} from "../../../../../pureFunctions";
import {useReduxData} from "../../../../customHooks/useContextReduxData";


export default function useObjectSelection() {
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {figures, scale, selectedFigureUuids} = useReduxData();

    const [rectangleX, setRectangleX] = useState(0);
    const [rectangleY, setRectangleY] = useState(0);
    const [rectangleWidth, setRectangleWidth] = useState(0);
    const [rectangleHeight, setRectangleHeight] = useState(0);
    const [isRectangleSelectionActive, setIsRectangleSelectionActive] = useState(false);

    const selectObject = event => {
        let target = event.target;
        if (["figure", "figure-neighborhood", "group"].includes(target.parentNode.parentNode.className.baseVal)
            || ["figure", "figure-neighborhood", "group", "text"].includes(target.className.baseVal)
        ) {
            let targetId = !isUUID.v4(target.id) ? target.parentNode.parentNode.id : target.id;
            let targetUuidHierarchy = getUuidHierarchy(figures, targetId);
            let shouldSelect = selectedFigureUuids.length === 0 || event.ctrlKey;
            if (!shouldSelect) {
                let isTargetSelected = false;
                targetUuidHierarchy.map(targetUuid => {
                    if (selectedFigureUuids.indexOf(targetUuid) !== -1) {
                         isTargetSelected = true;
                    }
                    return undefined;
                });
                if (!isTargetSelected) {
                    shouldSelect = true;
                }
            }
            if (shouldSelect) {
                return {type: "simpleSelection", payload: targetUuidHierarchy.slice(targetUuidHierarchy.length - 1)};
            }
        } else if (target.className.baseVal !== "transformational-connector") {
            return {type: "resetSelection"};
        }
        return {type: "canMove"};
    };

    const selectSubgroup = event => {
        let target = event.target;
        if (["figure", "figure-neighborhood", "group"].includes(target.parentNode.parentNode.className.baseVal)
            || ["figure", "figure-neighborhood", "group", "text"].includes(target.className.baseVal)
        ) {
            const targetId = !isUUID.v4(target.id) ? target.parentNode.parentNode.id : target.id;
            const targetUuidHierarchy = getUuidHierarchy(figures, targetId);
            const selectedGroupUuid = selectedFigureUuids.filter(uuid => targetUuidHierarchy.includes(uuid))[0];
            const selectedGroupIndex = selectedFigureUuids.indexOf(selectedGroupUuid);
            const subgroupToSelectUuid = targetUuidHierarchy[targetUuidHierarchy.indexOf(selectedGroupUuid) - 1];
            return subgroupToSelectUuid
                ? replaceAt(selectedFigureUuids, selectedGroupIndex, subgroupToSelectUuid)
                : selectedFigureUuids;
        }
        return [];
    };

    const startRectangleSelection = (xCoo, yCoo) => {
        setIsRectangleSelectionActive(true);
        setRectangleX(xCoo - canvasBoundingClientRect.get.left);
        setRectangleY(yCoo - canvasBoundingClientRect.get.top);
    };

    const changeSelectionRectangle = (newXCoo, newYCoo) => {
        if (isRectangleSelectionActive) {
            setRectangleWidth(newXCoo - rectangleX - canvasBoundingClientRect.get.left);
            setRectangleHeight(newYCoo - rectangleY - canvasBoundingClientRect.get.top);
        }
    };

    const finishRectangleSelection = () => {
        let figuresData = [];
        figures.map(figure => {
            let startPoint = {}, endPoint = {};
            if (figure.get("tool").split("-")[0] === "i") {
                startPoint.x = figure.get("x") * scale + figure.get("volume") * 1.5 * scale;
                startPoint.y = figure.get("y") * scale + figure.get("volume") * scale;
                endPoint.x = startPoint.x + figure.get("volume") * scale;
                endPoint.y = startPoint.y + figure.get("volume") * scale;
            } else {
                startPoint.x = figure.get("x") * scale;
                startPoint.y = figure.get("y") * scale;
                endPoint.x = (figure.get("x") + Path.getPathBoundingBox(figure.get("points")).endX) * scale;
                endPoint.y = (figure.get("y") + Path.getPathBoundingBox(figure.get("points")).endY) * scale;

                if (isNaN(startPoint.x)) startPoint.x = -100;
                if (isNaN(startPoint.y)) startPoint.y = -100;
                if (isNaN(endPoint.x)) endPoint.x = -100;
                if (isNaN(endPoint.y)) endPoint.y = -100;
            }

            figuresData.push({uuid: figure.get("uuid"), startPoint: startPoint, endPoint: endPoint});
            return undefined;
        });

        let selectedFigureUuids = [],
            selectionRectangle = {
                startX: rectangleX,
                startY: rectangleY,
                endX: rectangleX + rectangleWidth,
                endY: rectangleY + rectangleHeight
            };

        if (rectangleWidth < 0) {
            selectionRectangle.startX += rectangleWidth;
            selectionRectangle.endX -= rectangleWidth;
        }
        if (rectangleHeight < 0) {
            selectionRectangle.startY += rectangleHeight;
            selectionRectangle.endY -= rectangleHeight;
        }

        figuresData.map(figure => isPointIntoRectangle(figure.startPoint, selectionRectangle)
            && isPointIntoRectangle(figure.endPoint, selectionRectangle)
            && selectedFigureUuids.push(figure.uuid)
        );

        setIsRectangleSelectionActive(false);
        setRectangleX(0);
        setRectangleY(0);
        setRectangleHeight(0);
        setRectangleWidth(0);
        return selectedFigureUuids;
    };

    return {
        rectangleSelection:
            <RectangleSelection x={rectangleX} y={rectangleY} width={rectangleWidth} height={rectangleHeight}/>,
        selectObject,
        selectSubgroup,
        startRectangleSelection,
        changeSelectionRectangle,
        finishRectangleSelection,
        isRectangleSelectionActive: (rectangleWidth === 0 && rectangleHeight === 0) ? false : isRectangleSelectionActive
    }
}
