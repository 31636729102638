import React from "react";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../customHooks/useContextReduxData";
import getTimeElapsedSince from "../getTimeElapsedSince";


export default function TextBox({property}) {
    const {isEditMode, selectedObject: {isTheOnlyObjectSelected, figureUuid, figureName, figureLink}, startTime} = useReduxData();

    const dispatch = useDispatch();

    const handleTextChanged = event => {
        let actionData = {
            type: property === "name" ? "changeObjectName" : "changeObjectLink",
            uuid: figureUuid,
            time: getTimeElapsedSince(startTime)
        };
        actionData[property] = event.target.value;
        dispatch(actionData);
    };

    const closeMenu = event => event.key === "Enter" && dispatch({type: "openedMenuItemNumber", value: "-1"});

    return <input
        className="tool-menu-text-box"
        type="textbox"
        id={`tool-menu-${property}`}
        disabled={!isEditMode || !isTheOnlyObjectSelected}
        value={isTheOnlyObjectSelected ? (property === "name" ? figureName : figureLink) : ""}
        onChange={handleTextChanged}
        onKeyDown={closeMenu}
    />;
}
