import React, {useMemo} from "react";
import {TabProvider, TabBar, TabContent} from "@semograph/semui";

import Partition from "./Partition";
import AddNewTabButton from "./AddNewTabButton";
import AddNewPartitionButton from "./AddNewPartitionButton";
import TabTitle from "./TabTitle";
import useTabs from "../hooks/useTabs";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {useLocalization} from "../../../../customHooks/useContextLocalization";


export default function TabContainer() {
    const {tabs} = useTabs();
    const {get: {icons}} = useIconSet();
    const locale = useLocalization();

    const formattedTabs = useMemo(() => tabs.map((tab, i) => ({
        title: <TabTitle name={tab.name[locale?.get.name]} tabIndex={i}/>,
        content: <div className="account-icons-partition-container">
            {tab.children.map((partition, j) =>
                <Partition
                    key={`account-icons-partition-${i}${j}`}
                    icons={icons}
                    tabIndex={i}
                    partitionIndex={j}
                />
            )}
            <AddNewPartitionButton tabIndex={i}/>
        </div>
    })), [icons, tabs]);

    return <TabProvider tabs={formattedTabs} activeTab={0}>
        <span className="account-icons-info">{locale.get.store.icons.info}</span>
        <div className="account-icons-tab-container">
            <TabBar/>
            <AddNewTabButton/>
        </div>
        <TabContent/>
    </TabProvider>;
}