import {useRef} from "react";

import {useApiClient} from "../../customHooks/useApiClient";


export default function () {
    const api = useApiClient();

    const idFromRef = useRef();
    const idToRef = useRef();

    const downloadArchive = archive => {
        const now = new Date();
        const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
            .join(".");

        const filename = prompt("Название архива", `Архив_от_${formattedDate}`);
        const blob = new Blob([archive], {type: "application/zip"});
        filename && saveAs(blob, filename + ".zip");
    }

    const downloadCSV = archive => {
        const now = new Date();
        const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
            .join(".");

        const filename = prompt("Название файла", `CSV_от_${formattedDate}`);
        const blob = new Blob([archive], {type: "text/csv"});
        filename && saveAs(blob, filename + ".csv");
    }

    const loadAllMaps = async () => {
        downloadArchive((await api.get("/api/v1/researches/map_and_link_downloader/", {responseType: 'blob'})).data);
    };

    const loadSomeMaps = async () => downloadArchive((await api.get(
        `/api/v1/researches/map_and_link_downloader/from/${idFromRef?.current?.value || 0}/to/${idToRef?.current?.value || 1}/`,
        {responseType: 'blob'}
    )).data);

    const loadLinks = async () => {
        let links = (await api.put(
            `/api/v1/researches/map_and_link_downloader/links/from/${idFromRef?.current?.value || 0}/to/${idToRef?.current?.value || 1}/`,
            {responseType: 'blob'}
        )).data;

        if (links === "") {
            return;
        }

        links = links.split("\n");
        links = links.map(linkEntry => {
            let [link, ...entry] = linkEntry.split(",");
            link = `${location.origin}/share?share_token=${encodeURIComponent(link.slice(2, link.length - 1))}`;
            return [link].concat(entry).join(",");
        });
        links = links.join("\n")
        downloadCSV(links);
    };

    return <div className="dashed-container">
        <h3>Download Maps & Links</h3>
        <div className="research-data-downloader-partition-container">
            <input type="button" value="load all maps" onClick={loadAllMaps}/>
        </div>
        <div className="research-data-downloader-partition-container">
            <input ref={idFromRef} type="text" placeholder="id первого пользователя"/>
            <input ref={idToRef} type="text" placeholder="id последнего пользователя"/>
            <input type="button" value="load maps" onClick={loadSomeMaps}/>
            <input type="button" value="load links" onClick={loadLinks}/>
        </div>
    </div>;
}