import React from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import useTabs from "../hooks/useTabs";

import {ReactComponent as Plus} from "../../../images/cross-transparent.svg";


const AddNewTabButton = () => {
    const locale = useLocalization();
    const {dispatchTabs} = useTabs();

    const addPartition = () => dispatchTabs({type: "addTab", payload: {locale: locale}});

    return <div className="account-icons-add-tab" onClick={addPartition}>
        <div style={{marginRight: "0.5rem"}}>{locale.get.store.icons.addPartition}</div>
        <Plus height="10" width="10" fill="#144066" style={{transform: "rotate(45deg)"}}/>
    </div>;
};

export default AddNewTabButton;