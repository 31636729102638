import React, {useState, useContext, useEffect} from "react";

import {useLocalization} from "../../customHooks/useContextLocalization";


export const LicensesContext = React.createContext();

export const LicensesProvider = ({children}) => {
    const locale = useLocalization();

    const [licenses, setLicenses] = useState({
        image: [
            "licenseAgreement",
            "CCBY",
            "CCBYSA",
            "CCBYND",
            "CCBYNC",
            "CCBYNCND",
            "CCBYNCSA",
        ],
        software: []
    });

    useEffect(() => {
        if (locale?.get && !licenses.imageLocalized) {
            setLicenses(Object.assign(licenses,  {
                imageLocalized: licenses.image.map(key => locale.get.store.product.form.licenses.image[key].name),
                softwareLocalized: licenses.software.map(key => locale.get.store.product.form.licenses.software[key].name),
            }));
        }
    }, [locale, licenses]);


    return (
        <LicensesContext.Provider value = {licenses}>
            {children}
        </LicensesContext.Provider>
    );
};


export const useLicenses = () => {
    const licenses = useContext(LicensesContext);

    return licenses;
}
