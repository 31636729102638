import React from "react";

import {ReactComponent as SvgLink} from "../../images/link.svg";


export default function Link ({id, x, y, width, height, linkAddress}) {
    const openLink = () => {
        const win = window.open(linkAddress, "_blank");
        win.focus();
    }

    return <>
        <symbol id={`${id}-symbol-link`}>
            <SvgLink viewBox={`0 0 ${600 / width} ${600 / height}`}/>
        </symbol>
        <use id={id} href={`#${id}-symbol-link`} x={x} y={y} onClick={openLink} style={{cursor: "pointer"}}/>
    </>;
}