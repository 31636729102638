import React, {useState} from "react";

import {useLocalization} from "../customHooks/useContextLocalization";
import {useApiClient} from "../customHooks/useApiClient";


export default function ProductConfiguring() {
    const [config, setConfig] = useState("");

    const locale = useLocalization();
    const api = useApiClient();
    const texts = locale?.get.studio.licenseAgreement;

    const updateLocalConfig = event => setConfig(event.target.value);
    const sendConfig = () => api.put(
        `/api/v1/store/product_version/${location.pathname.split("/")[location.pathname.split("/").length - 1]}/config/`,
        {config: config}
    );

    //we cannot remove links because of CORS policy (is prevents redirects via axios)
    return <div className="modal-container">
        <h4 className="modal-title">{texts.title}</h4>
        <p className="modal-p">{texts.oAuthText}</p>
        <textarea placeholder="{parameters: {...}, tabs: [...]}" value={config} onChange={updateLocalConfig}/>
        <input
            type="button"
            className="modal-button modal-button-apply"
            value={texts.apply}
            onClick={sendConfig}
        />
    </div>;
}
