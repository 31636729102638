import React from "react";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {QueryClientProvider} from "react-query";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ToastContainer} from "react-toastify";
import {ThemeProvider} from "@semograph/semui";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Main from "./components/main/Main";
import Contacts from "./components/Contacts";
import Manual from "./components/Manual";
import Confidentiality from "./components/Confidentiality";
import Conditions from "./components/Conditions";
import ProductPublicationCompleted from "./components/ProductPublicationCompleted";
import ProductPurchased from "./components/ProductPurchased";
import Account from "./components/account/Account";
import IconArrangementWithProviders from "./components/account/IconArrangement";
import Licenses from "./components/licenses/Licenses";
import Payment from "./components/payment/Payment";
import Verified from "./components/Verified";
import Product from "./components/product/Product";
import ProductForm from "./components/productForm/ProductForm";

import {CurrentUserProvider} from "../customHooks/useContextCurrentUser";
import {useApiClient} from "../customHooks/useApiClient";
import {useModal} from "../customHooks/useContextModal";
import {LocalizationProvider} from "../customHooks/useContextLocalization";
import {CategoriesProvider} from "./customHooks/categories";
import {LicensesProvider} from "./customHooks/licenses";

import "react-toastify/dist/ReactToastify.css";
import "@semograph/semui/dist/index.css";
import "./Store.css";
import styles from "./theme/theme.js";


const Store = () => {
    const api = useApiClient();
    const modal = useModal();
    return (
        <CategoriesProvider>
        <LicensesProvider>
        <LocalizationProvider>
        <QueryClientProvider client={api.queryClient}>
        <CurrentUserProvider>
        <ThemeProvider styles = {styles}>
        <DndProvider backend={HTML5Backend}>
        <BrowserRouter basename = "/store">
            {modal?.getModal()}
            <Header/>
            <div className = "store-container">
                <Switch>
                    <Route path='/contacts' component = {Contacts}/>
                    <Route path='/verified' component = {Verified}/>
                    <Route path='/manual' component = {Manual}/>
                    <Route path='/confidentiality' component = {Confidentiality}/>
                    <Route path='/conditions' component = {Conditions}/>

                    <Route path='/licenses/:id' component = {Licenses}/>
                    <Route path = '/product/add/' component = {ProductForm}/>
                    <Route path = '/product/published/' component = {ProductPublicationCompleted}/>
                    <Route path = '/product/purchased/' component = {ProductPurchased}/>

                    <Route path='/payment/:id' component={Payment}/>
                    <Route path='/product/update/:id' component={ProductForm}/>
                    <Route path='/product/:id' component={Product}/>
                    <Route path='/account/icons' component={IconArrangementWithProviders}/>
                    <Route path='/account/:id' component={Account}/>

                    <Route path='/:type' component = {Main}/>
                    <Route path='/'>
                        <Redirect to="/all"/>
                    </Route>
                </Switch>
                <Footer/>
            </div>
            <ToastContainer theme="colored"/>
        </BrowserRouter>
        </DndProvider>
        </ThemeProvider>
        </CurrentUserProvider>
        </QueryClientProvider>
        </LocalizationProvider>
        </LicensesProvider>
        </CategoriesProvider>
    );
};

export default Store;
