import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {createStore} from "redux";
import {Map} from 'immutable';
import undoable from 'redux-undo';

import objectReducers from './reducers/objectReducer';


const cleaningReducer = (state, action) => {
    switch (action.type) {
        case 'clearState':
            return {
                past: [],
                present: Map({
                    figures: Map([]),
                    watch: Map({
                        figures: Map([])
                    }),
                    mapName: "Новая карта",
                    mapId: "",
                    lastAction: {type: "createMap"}
                }),
                future: []
            };
        default:
            return state;
    }
};

const undoableReducer = undoable(objectReducers, {
    filter: (action) => (action.type !== "loadStateByTime" && action.type !== "setMapId")
});

const store = createStore( //auxiliary store
    (state, action) => {
      const intermediateState = undoableReducer(state, action);
      const finalState = cleaningReducer(intermediateState, action);
      return finalState;
    }
);


export default function MapLoader(props) {
    const [actionToExecuteIndex, setActionToExecuteIndex] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.data?.actionHistory[actionToExecuteIndex]?.type === "createMap") { // pass useless createMap
            setActionToExecuteIndex(actionToExecuteIndex + 1);
            return undefined;
        }
        if (props.data.actionHistory.length === actionToExecuteIndex) { //load last action
            store.dispatch(props.data.main.lastAction);
            store.dispatch({type: "setMapId", id: props.data.main.mapId});
            setActionToExecuteIndex(actionToExecuteIndex + 1);
            return undefined;
        }
        if (props.data.actionHistory.length + 1 === actionToExecuteIndex) { //load map from auxiliary to main store
            dispatch({
                type: "loadNewState",
                data: {
                    main: store.getState(),
                    menu: props.data.menu
                }
            });
            store.dispatch({type: "clearState"});
            props.setIsLoading(false); //stop loader
            return undefined;
        }

        store.dispatch(props.data.actionHistory[actionToExecuteIndex]);
        setActionToExecuteIndex(actionToExecuteIndex + 1);
    }, [props.data.actionHistory, actionToExecuteIndex]);


    // the map is loading too fast. No progress bar needed
    // return <p className = "top-menu-progress-bar">{Math.round(actionToExecuteIndex / props.data.actionHistory.length * 1000) / 10}%</p>;
    return <></>;

}
