import {createImageFromSelectedIcons} from "../pureFunctions";

const ProductIconContainer = props => {
    const handleIconClick = event => {
        let newSelectedIcons = [];

        const targetId = +event.target.id.split("-")[event.target.id.split("-").length - 1];
        if (props.selectedIcons.includes(targetId)) {
            let index = props.selectedIcons.indexOf(targetId);
            newSelectedIcons = props.selectedIcons.slice(0, index).concat(props.selectedIcons.slice(index + 1));
            props.setSelectedIcons(newSelectedIcons);
        } else if (props.selectedIcons.length === 6) {
            return undefined;
        } else {
            newSelectedIcons = props.selectedIcons.concat(targetId);
            props.setSelectedIcons(newSelectedIcons);
        }

        let cardPhoto = createImageFromSelectedIcons(newSelectedIcons, props.icons);
        props.setFieldValue("card_photo", cardPhoto.image);
        props.setFieldValue("card_photo_icon_names", cardPhoto.names.join("/"));
    };

    return <div className="product-form-icons-container">
        {props.icons.map((icon, i) => (<div
            key={`product-form-archive-icon-${i}`}
            className={`simple-tool${props.selectedIcons?.includes(i) ? "-active" : ""}-wrapper`}
            id={`product-form-tool-icon-wrapper-${i}`}
            onClick={handleIconClick}
        >
            <div
                className="toolbar-tool"
                id={`product-form-tool-icon-${i}`}
                style={{backgroundImage: `url(${icon.data})`, backgroundSize: "contain"}}
            />
        </div>))}
    </div>;
};

export default ProductIconContainer;