import React, {useState} from "react";

import TabTitles from "./TabTitles";
import TabContent from "./TabContent";


export default function ToolSelector() {
    const [tabIndex, setTabIndex] = useState(0);
    const selectTab = (event) => setTabIndex(+event.target.id.split("-")[2]);

    return <div className="toolbar-item tool-selector">
        <TabTitles tabIndex={tabIndex} selectTab={selectTab}/>
        <TabContent tabIndex={tabIndex}/>
    </div>;
}
