import React from "react";
import {useDispatch} from "react-redux";

import tooltipHOC from "../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {useReduxData} from "../../../customHooks/useContextReduxData";


function Tool({tool, type, setOpenedSubpartitionIndex}) {
    const dispatch = useDispatch();

    const {get: {parameters, icons}} = useIconSet();
    const {color, tool: currentTool, fontColor, isWatchMode, isEditMode} = useReduxData();

    const handleToolClick = () => {
        if (!isWatchMode) {
            dispatch({type: "tool", value: tool});
            dispatch({type: "isEditMode", value: false});

            if (tool.split("-")[0] === "c" && color === "#FFFFFF") {
                dispatch({type: "color", value: parameters[tool]?.color || "#000000"});
            }
            else if (tool.split("-")[0] === "p" && fontColor === "#FFFFFF") {
                dispatch({type: "fontColor", value: parameters[tool]?.color || "#000000"});
            }
        }
        if (setOpenedSubpartitionIndex) {
            setOpenedSubpartitionIndex(-1);
        }
    };

    const [iconType, productId, iconIndex] = tool.split("-");
    const iconData = icons[iconType === "p" ? "polygons" : iconType === "c" ? "polylines" : "icons"][productId]
        .icons[iconIndex];

    return <div
        className={`toolbar-tool-wrapper${type}${isWatchMode ? " disabled" : ""}`}
        onClick={handleToolClick}
        style={{backgroundColor: tool === currentTool && !isEditMode ? "#FF983D" : ""}}
    >
        <div className="toolbar-tool" style={{backgroundImage: `url(${iconData})`, backgroundSize: "contain"}}/>
    </div>;
}

export default tooltipHOC(hintModeTooltipHOC(Tool));
