import React from "react";

import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";


function Slider(props) {
    return <div className="slider-container" id={props.id}>
        {!props.sliderShouldHide && <input
            className="base-block-slider"
            type="range"
            name="size"
            value={props.value}
            min={props.min}
            max={props.max}
            step={props.step}
            onChange={props.onChange}
        />}
        <label htmlFor="size" className="slider-title">
            {Math.round(props.value / (props.displayValueDenominator || 1)) + props.unitsName}
        </label>
    </div>;
}

export default hintModeTooltipHOC(Slider);
