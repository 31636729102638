import {Icon, RadioGroup} from "@semograph/semui";

import {getAllowedOptions, getUpdateIndex} from "../pureFunctions";
import {useLocalization} from "../../../../customHooks/useContextLocalization";

import {ReactComponent as RadioIcon} from "../../../images/radio.svg";
import {ReactComponent as RadioCheckedIcon} from "../../../images/radio_checked.svg";


const ProductUpdatingScope = props => {
    const locale = useLocalization();

    const renderRadioIcon = checked => <Icon icon = {checked ? RadioCheckedIcon : RadioIcon} viewBox = "0 0 16 16"/>;
    const setValue = option => props.setFieldValue(
        "updating_scope",
        getUpdateIndex(option, props.formValues.prohibited_updates)
    );

    return <RadioGroup
        groupName="updating_scope"
        defaultValue={props.formValues.updating_scope}
        renderRadioIcon={renderRadioIcon}
        onChange={setValue}
    >
        {getAllowedOptions(props.formValues.prohibited_updates).map(name =>
            <span key={`update-scope_span-${name}`} className="product-form-radio-item">
                {locale?.get?.store.product.form[name]}
            </span>
        )}
    </RadioGroup>;
}

export default ProductUpdatingScope;