import {Handle, Position} from "react-flow-renderer";

import HintedHandleLabel from "./HintedHandleLabel";
import {useLocalization} from "../../../../../customHooks/useContextLocalization";


export default function ({data: {label, inputs=[]}}) {
    const locale = useLocalization();

    const handles = [];
    for (let i = 0; i < inputs.length; i++) {
        handles.push(<Handle
            type="target"
            id={`${i}`}
            key={`${i}`}
            position={Position.Left}
            style={{top: `calc(${(i - inputs.length / 2 + 0.5) * 10}px + 50%)`}}
        />);
    }

    const inputLabels = [];
    for (let i = 0; i < inputs.length; i++) {
        const clear_data_type = inputs[i].data_type.startsWith("List")
            ? inputs[i].data_type.replace("List[", "").replace("]", "")
            : inputs[i].data_type;
        inputLabels.push(<HintedHandleLabel
            key={`${i}`}
            name={`${inputs[i].data_type}${inputs[i].role ? ` (${inputs[i].role})` : ""}`}
            tooltipContent={locale.get.research.dfd.nodeTypeHint[clear_data_type]}
            tooltipStyle={{top: `calc(${(i - inputs.length / 2 + 0.5) * 10 - 4}px + 50%)`, left: 5, display: "flex", position: "absolute"}}
        />);
    }

    return <div className="research-dfd-final-node">
        {handles}
        <label className="research-dfd-final-node-title" htmlFor="text">{label}</label>
        {inputLabels}
    </div>;
};