import React, {useState, useContext} from "react";


export const SurveySchemeContext = React.createContext();


export const SurveySchemeProvider = ({children}) => {
    const [get, set] = useState(null);

    return (
        <SurveySchemeContext.Provider value={{get, set}}>
            {children}
        </SurveySchemeContext.Provider>
    );
};


export const useSurveyScheme = () => useContext(SurveySchemeContext);
