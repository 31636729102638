import React from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function TabTitles({tabIndex, selectTab}) {
    const {isWatchMode} = useReduxData();
    const locale = useLocalization();
    const {get: {tabSet}} = useIconSet();

    return <ul className="toolbar-tab-title-container">
        {tabSet.map((tab, i) => <li
            key={i}
            className={`toolbar-tab-title${tabIndex === i ? "-active" : ""}${isWatchMode ? " text-disabled" : ""}`}
            id={`head-title-${i}`}
            onClick={selectTab}
        >{tab.name[locale?.get.name]}</li>)}
    </ul>;
}
