import Tooltip from "react-tooltip-lite";
import {Icon, RadioGroup} from "@semograph/semui";

import ProductTooltip from "../ProductTooltip";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {createImageFromSelectedIcons} from "../pureFunctions";

import {ReactComponent as RadioCheckedIcon} from "../../../images/radio_checked.svg";
import {ReactComponent as RadioIcon} from "../../../images/radio.svg";


const ProductCardPhotoType = props => {
    const locale = useLocalization();

    const setOption = option => {
        props.setFieldValue("card_photo_type", option);

        let cardPhoto = createImageFromSelectedIcons(option === 1 ? props.selectedIcons : [0,1,2,3,4,5], props.icons);
        props.setFieldValue("card_photo", cardPhoto.image);
        props.setFieldValue("card_photo_icon_names", cardPhoto.names.join("/"));
    };

    const renderRadioIcon = checked => <Icon icon={checked ? RadioCheckedIcon : RadioIcon} viewBox="0 0 16 16"/>;

    return <RadioGroup
        groupName="card-photo-type"
        defaultValue={props.formValues.card_photo_type}
        onChange={setOption}
        renderRadioIcon={renderRadioIcon}
    >
        <span className="product-form-radio-item">
            {locale?.get?.store.product.form.cardPhotoDefault}
            <ProductTooltip text={locale?.get?.store.product.form.hint}/>
        </span>
        <span className = "product-form-radio-item">{locale?.get?.store.product.form.cardPhotoChooseIconsToView}</span>
    </RadioGroup>;
}

export default ProductCardPhotoType;