import React, {useState, useEffect, useRef} from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";


const MenuItemTitle = hintModeTooltipHOC(({title}) => title);


export default function TopMenuItem({item, handlers}) {
    const locale = useLocalization();
    const {
        isWatchMode,
        isThereFuture,
        isTherePast,
        isUserCanChangeCanvasSize,
        isThereClipboardContent,
        isThereSelectedFigures
    } = useReduxData();

    const [isActive, setIsActive] = useState(false);
    const [xPosition, setXPosition] = useState(0);
    const [yPosition, setYPosition] = useState(0);

    const topMenuTitle = useRef(null);

    useEffect(() => {
        if (topMenuTitle) {
            let params = topMenuTitle.current.getBoundingClientRect();
            setXPosition(+params.left);
            setYPosition(+params.top);
        }
    }, [isActive, item.key]); //иначе шрифты не успевают подгрузиться и сдвиг неправильный

    const showSubitems = () => setIsActive(true);
    const hideSubitems = () => setIsActive(false);

    const hintModeKey = `menu${item.key[0].toUpperCase()}${item.key.slice(1)}`; //file -> menuFile etc...
    const enableItemConditions = {
        isTherePast,
        isThereFuture,
        isWatchMode,
        isThereSelectedFigures,
        isThereClipboardContent,
        isUserCanChangeCanvasSize
    };

    return <>
        <li
            className="top-menu-title"
            id={`top-menu-title-${item.key}`}
            ref={topMenuTitle}
            onClick={handlers.self || undefined}
            onMouseEnter={showSubitems}
            onMouseLeave={hideSubitems}
        ><MenuItemTitle title={locale?.get.studio.header.menu[item.key].title} hintModeKey={hintModeKey}/></li>
        {isActive && <>
            <div
                className={`top-menu-pseudo-title${item.items.length ? "" : "-rounded"}`}
                style={{marginLeft: xPosition + "px", marginTop: yPosition + "px"}}
            >{locale?.get.studio.header.menu[item.key].title}</div>
            {!!item.items.length && <div
                className="top-menu-item"
                style={{
                    marginLeft: xPosition + "px",
                    marginTop: yPosition + 39 + "px",
                    width: item.isExtended ? "295px" : "auto"
                }}
                onMouseEnter={showSubitems}
                onMouseLeave={hideSubitems}
                onClick={hideSubitems}
            >
                {item.items.map(subitem =>
                    <div key={`subitem-${subitem.key}`} className="top-menu-subitem-container">
                        <p
                            className={`top-menu-subitem${(!subitem.enabled
                                || (typeof subitem.enabled === "function" && !subitem.enabled(enableItemConditions))
                            ) ? "-disabled" : ""}`}
                            id={`top-menu-${item.key}-${subitem.key}`}
                            onClick={subitem.enabled ? handlers[`${subitem.key}`] : undefined}
                        >
                            {locale?.get.studio.header.menu[item.key].items[subitem.key]}
                        </p>
                        {subitem.hotkey && <p className={`top-menu-hot-key${(!subitem.enabled
                                || (typeof subitem.enabled === "function" && !subitem.enabled(enableItemConditions))
                        ) ? "-disabled" : ""}`}>
                            {subitem.hotkey}
                        </p>}
                    </div>
                )}
            </div>}
        </>}
    </>;
}
