import React, {useState} from 'react';

import {useSnappingMode} from "../../../customHooks/useContextSnappingMode";
import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function SnappingConnector({x, y, id, isMouseDown, handleMouseEnter}) {
    const snappingMode = useSnappingMode();
    const {isEditMode} = useReduxData();

    const [opacity, setOpacity] = useState(0);

    const _handleMouseEnter = event => {
        if (id === "closing-snapping-connector") {
            setOpacity(1);
            handleMouseEnter(event);
        }
        return event.shiftKey && isMouseDown && handleMouseEnter(event);
    };

    const _handleMouseLeave = () => id === "closing-snapping-connector" && setOpacity(0);

    return <circle
        className="snapping-connector"
        id={id + "|"}
        cx={x + 4}
        cy={y + 4}
        r="12"
        fill="blue"
        stroke="white"
        opacity={id === "closing-snapping-connector" ? opacity : (snappingMode?.get && !isEditMode ? 1 : 0)}
        onMouseEnter={_handleMouseEnter}
        onMouseLeave={_handleMouseLeave}
    />;
}
