import {useState} from "react";

import {Input} from "@semograph/semui";


const ProductResolution = props => {
    const [resolutionRate, setResolutionRate] = useState(1);

    const setValue = value => {
        // console.log(value);
        if ((+value >= 0 && +value <= 1) || value === "") {
            setResolutionRate(value);
            if (+value > 0 && +value <= 1) {
                let reader = new FileReader();
                reader.readAsDataURL(props.file);
                reader.onload = event => {
                    let result = event.target.result;

                    // paint 200x200 image with resolution 50x50
                    let cv = document.createElement("canvas"), ctx = cv.getContext("2d");

                    const img = document.createElement("img");
                    img.onload = () => {
                        let sourceHeight = img.height, sourceWidth = img.width;

                        cv.width = sourceWidth * value;
                        cv.height = sourceHeight * value;
                        ctx.drawImage(img, 0, 0, sourceWidth * value, sourceHeight * value);

                        props.setFieldValue("card_photo", cv.toDataURL());
                    };
                    img.src = result;
                };
            }
        }
    };

    return <div className="product-form-input-wrapper">
        <Input
            className="product-form-price-field"
            name={props.fieldName}
            type="number"
            value={resolutionRate}
            renderIcon={null}
            onChange={setValue}
        />
    </div>;
};

export default ProductResolution;