import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuid} from "uuid";

import ColorPicker from "./ColorPicker";
import EmojiContainer from "./EmojiContainer";
import EmojiPicker from "./EmojiPicker";
import Slider from "./Slider";
import TextBox from "./TextBox";
import TextArea from "./TextArea";
import Flags from "./Flags";
import ToolMenuItem from "./ToolMenuItem";
import ToolMenuSwitcher from "./ToolMenuSwitcher";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useReduxData} from "../../customHooks/useContextReduxData";
import hintModeTooltipHOC from "../../onboarding/hintModeTooltipHOC";
import {getRootUuid, getPreferredFontColorByBackgroundColor} from "../../pureFunctions/usefulFunctions";
import getTimeElapsedSince from "../getTimeElapsedSince";
import {groupSchema} from "../../pureFunctions/validators";

import {ReactComponent as ToolMenuHeader} from "../../images/controls/tool-menu-header.svg";


const ToolMenuTitleImage = hintModeTooltipHOC(() => <ToolMenuHeader className="tool-menu-header"/>, "toolMenuDnD");


const ToolMenuModeContainer = hintModeTooltipHOC(
    ({children}) => <div className = "tool-menu-container" id="tool-menu-mode-container">{children}</div>,
    "toolMenuModes"
);


const ToolMenuToolContainer = hintModeTooltipHOC(
    ({children}) => <div className = "tool-menu-container" id="tool-menu-container">{children}</div>,
    "toolMenuParameters"
);

const GROUP_LAYOUT = 50;

export default function ToolMenu() {
    const locale = useLocalization();
    const {
        color,
        dashLength,
        fontColor,
        fontSize,
        getFiguresUpdatedOn,
        isGridShow,
        isEditMode,
        isWatchMode,
        selectedFigureUuids,
        startTime,
        thickness,
        transparency,
        volume,
    } = useReduxData();
    const figures = getFiguresUpdatedOn([
        "addPolygon",
        "addPolyline",
        "addPointFeature",
        "addGroup",
        "changeObjectParentUuid",
        "clearState",
        "deleteFigure",
        "disbandGroup"
    ]);

    const dispatch = useDispatch();

    const [shiftX, setShiftX] = useState(0);
    const [shiftY, setShiftY] = useState(0);
    const [currentPosition, setCurrentPosition] = useState({x: "4%", y: "30%"});
    const [isToolMenuCaptured, setIsToolMenuCaptured] = useState(false);

    const [emojiPickerRerenderSign, setEmojiPickerRerenderSign] = useState(false);//for rerender on add/delete emoji (for prevent window exceeding)

    const toggleEmojiPickerRerenderSign = () => setEmojiPickerRerenderSign(!emojiPickerRerenderSign);

    const handleMouseDown = event => {
        let className = event.target.className.baseVal;
        if (className === "tool-menu-header" || className === "tool-menu-header-item") {
            let target = (className === "tool-menu-header-item" ? event.target.parentNode : event.target).getBoundingClientRect();
            setIsToolMenuCaptured(true);
            setShiftX(event.pageX - target.left);
            setShiftY(event.pageY - target.top);
        }
    }
    const handleMouseMove = event => isToolMenuCaptured && setCurrentPosition({
        x: (event.pageX - shiftX) / window.innerWidth * 100 + "%",
        y: (event.pageY - shiftY) / window.innerHeight * 100 + "%"
    });
    const handleMouseUp = () => setIsToolMenuCaptured(false);

    //extra effects
    const switchEditMode = () => dispatch({type: "isEditMode", value: !isEditMode});
    const switchGrid = () => dispatch({type: "isGridShow", value: !isGridShow});
    const groupObjects = () => {
        let rootUuids = [];
        selectedFigureUuids.map(uuid =>
            rootUuids.indexOf(getRootUuid(figures, uuid)) === -1 && rootUuids.push(getRootUuid(figures, uuid)));

        let newGroupUuid = uuid();
        const newGroupObject = {
            uuid: newGroupUuid,
            parentUuid: undefined,
            tool: "g-s-72",
            children: rootUuids,
            name: "",
            description: "",
            layout: GROUP_LAYOUT,
            orderIndex: Math.round(new Date().getTime()),
        };
        if (!groupSchema.isValidSync(newGroupObject)) {
            return;
        }
        dispatch({...newGroupObject, type: "addGroup", time: getTimeElapsedSince(startTime)});
        dispatch({type: "selectedFigureUuids", value: [newGroupUuid]});
    };
    const ungroupObjects = () => {
        selectedFigureUuids.map(u => figures.get(u).get("children") && dispatch({
            type: "disbandGroup",
            uuid: u,
            children: figures.get(u).get("children"),
            time: getTimeElapsedSince(startTime)
        }));
        dispatch({type: "selectedFigureUuids", value: []});
    };

    return !isWatchMode && <>
        <div
            className="tool-menu-background"
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{pointerEvents: isToolMenuCaptured ? "auto" : "none"}}
        />
        <div
            className="tool-menu"
            id="tool-menu"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{
                left: `clamp(0%, ${currentPosition.x}, calc(100% - 6.75rem))`,
                top: `clamp(0%, ${currentPosition.y}, calc(100% - 29rem))`
            }}
        >
            <ToolMenuTitleImage/>
            <p className="tool-menu-submenu-title">{locale?.get.studio.floatingMenu.mouse}</p>
            <ToolMenuModeContainer>
                <ToolMenuSwitcher
                    id="0"
                    images={["/images/toolMenu/icon-editing-mode.svg"]}
                    shouldHighlighted={isEditMode}
                    shouldDisabled={isWatchMode}
                    extraEffect={switchEditMode}
                    tooltipContent={locale?.get.studio.floatingMenu.editingMode}
                    hintModeKey="toolMenuEditMode"
                />
                <ToolMenuSwitcher
                    id="1"
                    switchHandlerName="isGridShow"
                    images={["/images/toolMenu/icon-outline.svg", "/images/toolMenu/icon-no-outline.svg"]}
                    actions={[{type: "isGridShow", value: true}, {type: "isGridShow", value: false}]}
                    shouldDisabled={isWatchMode}
                    extraEffect={switchGrid}
                    tooltipContent={locale?.get.studio.floatingMenu.outline}
                    hintModeKey="toolMenuGrid"
                />
                <ToolMenuSwitcher
                    id="2"
                    images={["/images/toolMenu/icon-group.png"]}
                    extraEffect={groupObjects}
                    shouldDisabled={isWatchMode || !isEditMode || selectedFigureUuids.length < 2}
                    tooltipContent={locale?.get.studio.floatingMenu.group}
                    hintModeKey="toolMenuGroup"
                />
                <ToolMenuSwitcher
                    id="3"
                    images={["/images/toolMenu/icon-ungroup.png"]}
                    extraEffect={ungroupObjects}
                    shouldDisabled={isWatchMode || !isEditMode || selectedFigureUuids.length === 0}
                    tooltipContent={locale?.get.studio.floatingMenu.ungroup}
                    hintModeKey="toolMenuUngroup"
                />
            </ToolMenuModeContainer>
            <div className="tool-menu-divider"/>
            <ToolMenuToolContainer>
                <ToolMenuItem
                    id="4"
                    name="textBox"
                    image={"/images/toolMenu/icon-name.svg"}
                    body={<TextBox property="name"/>}
                    title={locale?.get.studio.floatingMenu.name}
                    tooltipContent={locale?.get.studio.floatingMenu.name}
                    hintModeKey="toolMenuName"
                />
                <ToolMenuItem
                    id="5"
                    name="textArea"
                    image={"/images/toolMenu/icon-description.svg"}
                    body={<TextArea/>}
                    title={locale?.get.studio.floatingMenu.description}
                    tooltipContent={locale?.get.studio.floatingMenu.description}
                    hintModeKey="toolMenuDescription"
                />
                <ToolMenuItem
                    id="6"
                    name="slider"
                    sliderPurpose="fontSize"
                    sliderType="any"
                    image={"/images/toolMenu/icon-font-size.svg"}
                    body={<Slider
                        title={locale?.get.studio.floatingMenu.fontSize}
                        action={"changeFigureFontSize"}
                        purpose="fontSize"
                        value={fontSize}
                        menuAction="fontSize"
                        type="any"
                        min="-1"
                        max="20"
                        step="1"
                    />}
                    tooltipContent={locale?.get.studio.floatingMenu.fontSizeHint}
                    hintModeKey="toolMenuFontSize"
                />
                <ToolMenuItem
                    id="7"
                    name="emojiPicker"
                    image={"/images/toolMenu/icon-emoji.svg"}
                    body={<>
                        <EmojiContainer toggleEmojiPickerRerenderSign={toggleEmojiPickerRerenderSign}/>
                        <EmojiPicker toggleEmojiPickerRerenderSign={toggleEmojiPickerRerenderSign}/>
                    </>}
                    emojiPickerRerenderSign={emojiPickerRerenderSign}
                    tooltipContent={locale?.get.studio.floatingMenu.emoji}
                    hintModeKey="toolMenuEmojis"
                />
                <ToolMenuItem
                    id="8"
                    name="fontColorPicker"
                    color={fontColor}
                    image={`/images/toolMenu/icon-text-color-${getPreferredFontColorByBackgroundColor(fontColor)}.svg`}
                    body={<ColorPicker context="fontColor"/>}
                    tooltipContent={locale?.get.studio.floatingMenu.fontColorHint}
                    hintModeKey="toolMenuFontColor"
                />
                <ToolMenuItem
                    id="9"
                    name="textBox"
                    image={"/images/toolMenu/icon-link.svg"}
                    body={<TextBox property="link"/>}
                    title={locale?.get.studio.floatingMenu.link}
                    tooltipContent={locale?.get.studio.floatingMenu.link}
                    hintModeKey="toolMenuLink"
                />
                <div className="tool-menu-divider"/>
                <ToolMenuItem
                    id="10"
                    name="figureColorPicker"
                    color={color}
                    image={`/images/toolMenu/icon-figure-color-${getPreferredFontColorByBackgroundColor(color)}.svg`}
                    body={<ColorPicker context="color"/>}
                    tooltipContent={locale?.get.studio.floatingMenu.colorHint}
                    hintModeKey="toolMenuColor"
                />
                <ToolMenuItem
                    id="11"
                    name="slider"
                    sliderPurpose="transparency"
                    sliderType="any"
                    image={"/images/toolMenu/icon-transparency.svg"}
                    body={<Slider
                        title={locale?.get.studio.floatingMenu.transparency}
                        action={"changeFigureTransparency"}
                        purpose="transparency"
                        value={transparency}
                        menuAction="transparency"
                        type="any"
                        min="0"
                        max="1"
                        step="0.1"
                    />}
                    tooltipContent={locale?.get.studio.floatingMenu.transparencyHint}
                    hintModeKey="toolMenuTransparency"
                />
                <ToolMenuItem
                    id="12"
                    name="slider"
                    sliderPurpose="thickness"
                    sliderType="c"
                    image={"/images/toolMenu/icon-thickness.svg"}
                    body={<Slider
                        title={locale?.get.studio.floatingMenu.thickness}
                        action={"changeFigureThickness"}
                        purpose="thickness"
                        value={thickness}
                        menuAction="thickness"
                        type="c"
                        min="1"
                        max="5"
                        step="0.5"
                    />}
                    tooltipContent={locale?.get.studio.floatingMenu.thicknessHint}
                    hintModeKey="toolMenuThickness"
                />
                <ToolMenuItem
                    id="13"
                    name="slider"
                    sliderPurpose="volume"
                    sliderType="i"
                    image={"/images/toolMenu/icon-radius.svg"}
                    body={<Slider
                        title={locale?.get.studio.floatingMenu.size}
                        action="changeFigureVolume"
                        purpose="volume"
                        value={volume}
                        menuAction="volume"
                        type="i"
                        min="5"
                        max="70"
                        step="5"
                    />}
                    tooltipContent={locale?.get.studio.floatingMenu.sizeHint}
                    hintModeKey="toolMenuResize"
                />
                <ToolMenuItem
                    id="14"
                    name="slider"
                    sliderPurpose="dashLength"
                    sliderType="c"
                    image={"/images/toolMenu/icon-dash-length.svg"}
                    body={<Slider
                        title={locale?.get.studio.floatingMenu.dash}
                        action={"changeFigureDashLength"}
                        purpose="dashLength"
                        value={dashLength}
                        menuAction="dashLength"
                        type="c"
                        min="0"
                        max="15"
                        step="1"
                    />}
                    tooltipContent={locale?.get.studio.floatingMenu.dashHint}
                    hintModeKey="toolMenuDash"
                />
                <ToolMenuItem
                    id="15"
                    name="flags"
                    image={"/images/toolMenu/icon-flag.svg"}
                    body={<Flags/>}
                    tooltipContent={locale?.get.studio.floatingMenu.flagHint}
                    hintModeKey="toolMenuFlag"
                />
                {
                    // <ToolMenuItem
                    //     id="16"
                    //     image={"/images/toolMenu/icon-layout.png"}
                    //     body={<Slider
                    //         title="План: "
                    //         action={"changeFigureLayout"}
                    //         purpose="layout"
                    //         value={props.layout}
                    //         menuAction="changeLayout"
                    //         type="any"
                    //         min="0"
                    //         max="99"
                    //         step="1"
                    //     />}
                    //     hintInOpenedState=""
                    //     hintInClosedState={"Толщина контурной фигуры"}
                    //     setHintText={setHintText}
                    //     hintModeKey="S62"
                    // />
                }
            </ToolMenuToolContainer>
        </div>
    </>;
}
