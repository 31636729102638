import {useEffect, useState} from "react";
import {useApiClient} from "../../../../../customHooks/useApiClient";


export default function useAvailableConverters () {
    const api = useApiClient();

    const [availableConverters, setAvailableConverters] = useState([]);

    useEffect(async () => {
        const available_converters = (await api.get("/api/v1/researches/converters/")).data;
        setAvailableConverters(available_converters);
    }, []);

    return availableConverters;
}