import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useMap} from "../../customHooks/useContextMap";


export default function Resize(props) {
    const locale = useLocalization();
    const map = useMap();

    const dispatch = useDispatch();

    const [fitType, setFitType] = useState("fix");
    const [preventSizeChange, setPreventSizeChange] = useState(true);

    const checkRadio = event => setFitType(event.target.id.split("-")[event.target.id.split("-").length - 1]);
    const checkCheckbox = () => setPreventSizeChange(!preventSizeChange);

    const handleApplyClick = () => {
        dispatch({type: "isUserCanChangeCanvasSize", value: !preventSizeChange});
        map.loadMapBackgroundFromFS(fitType);
        props.close();
    };

    return <div className="modal-background-container">
        <h4 className="modal-title modal-background-title">{locale?.get.studio.backgroundLoading.title}</h4>
        <div className="modal-background-radio-group">
            <div className="survey-answer-radio-wrapper">
                <input
                    id="background-fit-stretch"
                    className="survey-answer-radio-hidden-std"
                    type="radio"
                    radioGroup="background-fit"
                    name="background-fit-stretch"
                    checked={fitType === "stretch"}
                    onChange={checkRadio}
                />
                <label className="survey-answer-radio-label" htmlFor="background-fit-stretch">
                    {locale?.get.studio.backgroundLoading.stretch}
                </label>
            </div>
            <div className="survey-answer-radio-wrapper">
                <input
                    id="background-fit-fix"
                    className="survey-answer-radio-hidden-std"
                    type="radio"
                    radioGroup="background-fit"
                    name="background-fit-fix"
                    checked={fitType === "fix"}
                    onChange={checkRadio}
                />
                <label className="survey-answer-radio-label" htmlFor="background-fit-fix">
                    {locale?.get.studio.backgroundLoading.fix}
                </label>
            </div>
        </div>
        <div className="modal-background-check-prevent">
            <div className="survey-answer-radio-wrapper">
                <input
                    id="background-prevent"
                    className="survey-answer-checkbox-hidden-std"
                    type="checkbox"
                    checked={preventSizeChange}
                    onChange={checkCheckbox}
                />
                <label className="survey-answer-checkbox-label" htmlFor="background-prevent">
                    {locale?.get.studio.backgroundLoading.prevent}
                </label>
            </div>
        </div>
        <div className="modal-background-apply">
            <input
                type="button"
                className="modal-button modal-button-apply"
                value={locale?.get.studio.backgroundLoading.apply}
                onClick={handleApplyClick}
            />
        </div>
    </div>;
}
