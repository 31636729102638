import Avatar from "./avatar.module.css";
import Button from "./button.module.css";
import Card from "./card.module.css";
import Comment from "./comment.module.css";
import Datetime from "./dateTime.module.css";
import Description from "./description.module.css";
import Footer from "./footer.module.css";
import Header from "./header.module.css";
import Input from "./input.module.css";
import LinkBtn from "./linkButton.module.css";
import Radio from "./radio.module.css";
import RadioGroup from "./radioGroup.module.css";
import Rating from "./rating.module.css";
import Select from "./select.module.css";
import Tab from "./tab.module.css";

export default {
    Avatar,
    Button,
    Card,
    Comment,
    Datetime,
    Description,
    Footer,
    Header,
    Input,
    LinkBtn,
    Radio,
    RadioGroup,
    Rating,
    Select,
    Tab
};
