import React, {useEffect, useState} from "react";
import ReactDOMServer from "react-dom/server";
import {useParams, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import MDEditor from "@uiw/react-md-editor";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useFormik} from "formik";
import {Rating, Button, Preview, LinkBtn, Comment, Datetime} from "@semograph/semui";

import Page404 from "../Page404";
import LoadingPage from "../LoadingPage";
import ProductPreview from "./ProductPreview";
import ProductCardPhoto from "../productCard/ProductCardPhoto";
import {StarHeavySmall, StarLightSmall, StarHeavy, StarLight} from "../CustomIcons";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useModal} from "../../../customHooks/useContextModal";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCategories} from "../../customHooks/categories";
import {useLicenses} from "../../customHooks/licenses";
import CustomMDEditor from "../productForm/CustomMDEditor";
import {compareTags, cropString, getFieldContent} from "../utils";

import {ReactComponent as DefaultAvatar} from "../../images/default-avatar.svg";

import "./product.css";


const createCoauthorList = (coauthors, max_length, handleOnClick) => {
    let coauthorElements = [];
    let length_left = max_length;

    for (let i = 0; i < coauthors.length; i++) {
        if (coauthors[i].name.length <= length_left) {
            coauthorElements.push(<span
                key={`product-coauthor-${i}`}
                className="product-clickable"
                id={`product-coauthor-${coauthors[i].id}`}
                onClick={handleOnClick}
            >{coauthors[i].name}</span>);
            length_left -= coauthors[i].name.length;
        } else if (i === 0) {
            coauthorElements.push(<span
                key={`product-coauthor-${i}`}
                className="product-clickable"
                id={`product-coauthor-${coauthors[i].id}`}
                onClick={handleOnClick}
            >{cropString(coauthors[i].name, 25)}</span>);
            return coauthorElements;
        } else {
            return coauthorElements;
        }
    }

    return coauthorElements;
};


function Product(props) {
    const {id} = useParams();

    const api = useApiClient();
    const locale = useLocalization();
    const categories = useCategories();
    const currentUser = useCurrentUser();
    const history = useHistory();
    const queryClient = useQueryClient();
    const user = useCurrentUser();
    const modal = useModal();
    const licenses = useLicenses();

    const [extraInfoTabNo, setExtraInfoTabNo] = useState(1);
    const [userNewComments, setUserNewComments] = useState([]);
    const [oldComments, setOldComments] = useState([]);
    const [areThereAnyMoreComments, setAreThereAnyMoreComments] = useState(true);
    const [lastCommentId, setLastCommentId] = useState(-1);

    const categoryNames = categories?.map(cat => locale?.get?.store.footer[cat.key]).slice(1) || [];

    useEffect(() => {
        if (oldComments.length === 0 && locale?.get) {
            loadOldComments(-1)();
        }
    }, [locale, currentUser?.id, id]);

    const {mutateAsync} = useMutation((data) => {
        data.product_version_id = +id;
        // console.log(data);
        try {
            return api.post(`/api/v1/store/comment/`, data);
        } catch (e) {
            console.log(e);
        }
    });

    const formik = useFormik({
        initialValues: {
            text: '',
        },
        onSubmit: async (values, {resetForm}) => {
            if (values.text) {
                await mutateAsync(values);
                resetForm();
                toast.success(locale.get.store.product.page.commentSent);
            }
        },
    });

    const buyProduct = async () => {
        try {
            let response = await api.post(`/api/v1/store/order/`, {cost: product.price, product_version_id: product.id});
            if (!response?.data?.should_pay) {
                props.history.push(`/product/purchased`);
            } else {
                props.history.push(`/payment/${response.data.id}`);
            }
        } catch (e) {
            modal.set("buy_error");
            return undefined;
        }
    };
    const addToFavourites = async () => {
        try {
            await api.post(`/api/v1/store/product_version/${id}/favourite/`);
            queryClient.setQueryData(
                ["store/product_version/get", id, currentUser],
                oldData => {
                    let newData = {};
                    Object.assign(newData, oldData);
                    newData.data.is_favourite = true;
                    return newData;
                }
            );
        } catch (e) {
            console.log(e);
        }
    };
    const removeFromFavourites = async () => {
        try {
            await api.delete(`/api/v1/store/product_version/${id}/favourite/`);
            queryClient.setQueryData(
                ["store/product_version/get", id, currentUser],
                oldData => {
                    let newData = {};
                    Object.assign(newData, oldData);
                    newData.data.is_favourite = false;
                    return newData;
                }
            );
        } catch (e) {
            console.log(e);
        }
    };

    const goToCategory = () => props.history.push(`/${categories[product.type].key}`);
    const goToUserPage = () => props.history.push(`/account/${product.author.id}`);
    const goToCoauthorPage = event => props.history.push(`/account/${event.target.id.split("-")[event.target.id.split("-").length - 1]}`);
    const loadOldComments = (last_id) => () => {
        api.get(`/api/v1/store/product_version/${id}/comment/${last_id}`).then(result => {
            setOldComments(oldComments.concat(result.data.comments.map((comment, i) => <Comment
                key = {`product-comment-from-server-${i}`}
                authorName = {comment.user.name || locale.get.store.product.page.authorDoesntExist}
                authorPage = {comment.user.id ? `/store/account/${comment.user.id}` : "/"}
                authorRating = {comment.user.rating}
                commentText = {comment.text}
                date = {comment.created}
                locale = {locale.get.langCode}
                iconOn = {StarHeavySmall}
                iconOff = {StarLightSmall}
                email = {comment.user.email}
                imgUrl = {comment.user.custom_avatar || comment.user.social_avatar || (comment.user.email
                    ? undefined
                    : 'data:image/svg+xml,' + encodeURIComponent(ReactDOMServer.renderToStaticMarkup((<DefaultAvatar/>)))
                )}
            />)));
            setLastCommentId(result.data.comments?.[result.data.comments.length - 1]?.id || -1);
            setAreThereAnyMoreComments(result.data.are_there_any_more_comments);
        });
    }

    const configureProduct = () => {
        modal.set("configure_product");
    };

    const setRatingMutation = useMutation(
        (event) => api.post(
            `/api/v1/store/product_version/${product?.id}/rating/`,
            {rating: +event.target.parentNode.parentNode.id.split("-")[3]}
        ),
        {onSuccess: () => queryClient.refetchQueries(["rating"], {active: true})}
    );
    const setRating = event => setRatingMutation.mutate(event);

    const productQuery = useQuery(
        ["rating", "store/product_version/get", id, currentUser],
        () => api.get(`/api/v1/store/product_version/${id}`)
    );
    const product = productQuery.data?.data;

    const ratingQuery = useQuery(
        ["rating", "store/product_version/rating", id],
        () => api.get(`/api/v1/store/product_version/${id}/rating`)
    );
    const rating = ratingQuery.data?.data?.rating;


    const versionsQuery = useQuery(
        ["store/product/versions", id, product, currentUser],
        () => product?.product_id ? api.get(`/api/v1/store/product/${product.product_id}/versions`) : {data: undefined}
    );
    const versions = versionsQuery.data?.data;

    // console.log(productQuery);
    // console.log(product);
    // console.log(oldComments);

    return (locale?.get &&
        ([productQuery.status, versionsQuery.status].includes("loading")
            ? <LoadingPage/>
            : ([productQuery.status].includes("error")
                ? <Page404/>
                : <div className = "product-body">
                    <div className = "product-title-container">
                        <span className = "product-title-category" onClick={goToCategory}>{categoryNames[product.type - 1]} –</span>
                        <span className = "product-title-name">{getFieldContent(product.name, locale.get.abbr.toLowerCase())}</span>
                    </div>
                    <div className = "product-wrapper">
                        <div className = "product-brief-container">
                            <Preview renderIcon = {() => <ProductCardPhoto id = {id}/>}/>
                            <div className = "product-brief-price-container">
                                <span>{(product.has_user_old_version && !product.has_user_this_version)
                                    ? (locale.get.store.product.page.updatePrice || 0)
                                    : locale.get.store.product.page.price
                                }</span>
                                <span>{(product.has_user_old_version && !product.has_user_this_version)
                                    ? product.update_price
                                    : product.price
                                } {locale.get.store.rub}</span>
                            </div>
                            {product.author.id === user?.id
                                ? <label>{locale.get.store.product.page.youAreAuthor}</label>
                                : (product.coauthors?.map(coauthor => coauthor.name).includes(user?.username)
                                    ? <label>{locale.get.store.product.page.youAreCoauthor}</label>
                                    : (product.has_user_this_version
                                        ? <label>{locale.get.store.product.page.productPurchased}</label>
                                        : <Button
                                            text={locale.get.store.product.page.buy}
                                            onClick={buyProduct}
                                        />
                                    )
                                )
                            }
                            {user && (product.is_favourite
                                ? <span className = "product-clickable" onClick = {removeFromFavourites}>
                                    {locale.get.store.product.page.removeFromFavourites}
                                </span>
                                : <span className = "product-clickable" onClick = {addToFavourites}>
                                    {locale.get.store.product.page.addToFavourites}
                                </span>)
                            }
                            {product.has_user_this_version && <span className="product-set-rating-wrapper">
                                <Rating
                                    n={rating}
                                    iconOn={StarHeavy}
                                    iconOff={StarLight}
                                    onClick={setRating}
                                />
                            </span>}
                            {product.author.id === user?.id && user.email === "noreply@creativemaps.studio" &&
                                <span style={{color: "red", margin: "1rem", cursor: "pointer"}} onClick={configureProduct}>
                                    Настроить продукт
                                </span>
                            }
                        </div>
                        <div className='product'>
                            <label>{locale.get.store.product.page.author}</label>
                            {product.author.name ? <span className="product-clickable" onClick={goToUserPage}>
                                {cropString(product.author.name, 25)}
                            </span> : locale.get.store.product.page.authorDoesntExist}
                            <label>{locale.get.store.product.page.version}</label>
                            <span>v{product.version_name}</span>
                            <label>{locale.get.store.product.page.coauthors}</label>
                            <span>{createCoauthorList(product.coauthors, 25, goToCoauthorPage)}</span>
                            <label>{locale.get.store.product.page.rating}</label>
                            <span>
                                <Rating n = {product.rating} iconOn = {StarHeavySmall} iconOff = {StarLightSmall}/>
                            </span>
                            <label>{locale.get.store.product.page.category}</label>
                            <span className="product-clickable" onClick={goToCategory}>
                                {categoryNames[product.type - 1]}
                            </span>
                            <label>{locale.get.store.product.page.userCount}</label>
                            <span>{product.users_count}</span>
                            <label>{locale.get.store.product.page.tags}</label>
                            <span>
                                {cropString(product.tags.map(tag => tag.name).sort(compareTags(locale)).join(", "), 25)}
                            </span>
                            <label>{locale.get.store.product.page.createdAt}</label>
                            <span>
                                {product?.created ? <Datetime date={product.created} locale={locale.get.langCode}/> : null}
                            </span>
                            <label>{locale.get.store.product.page.description}</label>
                            <MDEditor.Markdown
                                className = "desc product-description"
                                source = {getFieldContent(product.description, locale.get.abbr.toLowerCase())}
                            />

                            <div className = "product-extra-info-tab-container">
                                {product.type === 1 && <div onClick={() => setExtraInfoTabNo(1)}><LinkBtn
                                    text={locale.get.store.product.page.preview} isActive={extraInfoTabNo === 1}/>
                                </div>}
                                <div onClick={() => setExtraInfoTabNo(2)}><LinkBtn text = {locale.get.store.product.page.feedbacks} isActive={extraInfoTabNo === 2} /></div>
                                <div onClick={() => setExtraInfoTabNo(3)}><LinkBtn text = {locale.get.store.product.page.usingConditions} isActive={extraInfoTabNo === 3}/></div>
                                <div onClick={() => setExtraInfoTabNo(4)}><LinkBtn text = {locale.get.store.product.page.versions} isActive={extraInfoTabNo === 4}/></div>
                            </div>
                            <div className = "product-extra-info-container">
                                {product.type === 1 && extraInfoTabNo === 1 && <ProductPreview id = {id}/>}
                                {extraInfoTabNo === 2 && <>
                                    {userNewComments.map(comment => comment)}
                                    {oldComments.map(comment => comment)}
                                    {/*TODO: show for holders only*/}
                                    {areThereAnyMoreComments
                                        ? <label className = "product-clickable" onClick={loadOldComments(lastCommentId)}>
                                            {locale.get.store.product.page.showMoreComments}
                                        </label>
                                        : null
                                    }
                                    <span className = "product-new-comment-title">{locale.get.store.product.page.leaveYourFeedback}</span>
                                    <form onSubmit = {formik.handleSubmit}>
                                        <CustomMDEditor
                                            className = "product-md-editor"
                                            onChange = {value => formik.setFieldValue('text', value)}
                                            value = {formik.values.text}
                                        />
                                        <div className = "product-add-comment-wrapper">
                                            <Button
                                                type = 'submit'
                                                text = {locale.get.store.product.page.sendFeedback}
                                                onClick = {() => formik.values.text && setUserNewComments([
                                                    <Comment
                                                        key = {`product-comment-local-${userNewComments.length}`}
                                                        authorName = {currentUser?.username || locale.get.store.product.page.authorDoesntExist}
                                                        authorPage = {currentUser?.id ? `/store/account/${currentUser.id}` : "/"}
                                                        authorRating = {rating}
                                                        commentText = {formik.values.text}
                                                        date = {new Date().toISOString()}
                                                        locale = {locale.get.langCode}
                                                        iconOn = {StarHeavySmall}
                                                        iconOff = {StarLightSmall}
                                                        email = {currentUser?.email || ""}
                                                        imgUrl = {currentUser?.custom_avatar || currentUser?.social_avatar || (currentUser?.email
                                                            ? undefined
                                                            : 'data:image/svg+xml,' + encodeURIComponent(ReactDOMServer.renderToStaticMarkup((<DefaultAvatar/>)))
                                                        )}
                                                    />,
                                                    ...userNewComments
                                                ])}
                                            />
                                        </div>
                                    </form>
                                </>}
                                {extraInfoTabNo === 3 && <MDEditor.Markdown
                                    className = "product-license-container"
                                    source = {`${
                                        locale?.get?.store.product.form.licenses
                                            .image[licenses.image[product.license_type]].text
                                    }`}
                                />}
                                {extraInfoTabNo === 4 && <div>
                                    {versions && versions.map((ver, i) => <React.Fragment key={`product-version-label-${i}`}>
                                        <input
                                            type = "checkbox"
                                            className = "product-version-input"
                                            id = {`product-version-input-${i}`}
                                            name = {`product-version-input-${i}`}
                                        />
                                        <label className = "product-version-label" htmlFor = {`product-version-input-${i}`}>
                                            v{ver.version_name}
                                        </label>
                                        <div className = "product-version-review">
                                            {ver.user_has_access && <span
                                                className = "product-version-link"
                                                onClick = {() => history.push(`/product/${ver.id}`)}
                                            >{locale.get.store.product.page.goToVersionPage}</span>}
                                            <MDEditor.Markdown
                                                className = "desc"
                                                source = {ver.version_review[locale.get.abbr.toLowerCase()]}
                                            />
                                        </div>
                                    </React.Fragment>)}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    );
}

export default Product;
