import {Record} from 'immutable';

class PointFeature extends Record({
    x: undefined,
    y: undefined,
    volume: undefined,
    tool: "empty",
    uuid: undefined,
    parentUuid: undefined,
    color: null,
    emoji: [],
    fontColor: undefined,
    fontSize: undefined,
    transparency: undefined,
    flags: [],
    name: "",
    link: "",
    description: "",
    layout: undefined,
    orderIndex: undefined
}) {
}

export default PointFeature;
