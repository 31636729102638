import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Map} from "immutable";

import Slider from "./Slider";
import EditorModeSwitch from "./EditorModeSwitch";
import LanguageSelector from "./LanguageSelector";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function BaseBlock() {
    const locale = useLocalization();
    const {isWatchMode, lastAction, past, scale} = useReduxData();

    const dispatch = useDispatch();

    const [currentTime, setCurrentTime] = useState(0);
    const [lastTime, setLastTime] = useState(0);

    const handleTimeChange = event => {
        setCurrentTime(event.target.value);
        dispatch({
            currentTime: event.target.value,
            past: lastAction.type !== "loadStateByTime" ? past.concat([Map({lastAction})]) : past,
            type: "loadStateByTime"
        });
    };

    const handleScaleChange = event => {
        dispatch({type: "prevScale", value: scale});
        dispatch({type: "scale", value: +event.target.value});
    };

    return <div className="toolbar-item base-block" id="base-block">
        <div className="base-block-container">
            <p className="lang-title">{locale?.get.studio.header.toolbar.baseBlock.lang}</p>
            <p className="mode-title">{locale?.get.studio.header.toolbar.baseBlock.mode}</p>
            <p className="time-title">{locale?.get.studio.header.toolbar.baseBlock.time}</p>
            <p className="scale-title">{locale?.get.studio.header.toolbar.baseBlock.scale}</p>
        </div>
        <div className="base-block-container">
            <LanguageSelector/>
            <EditorModeSwitch setLastTime={setLastTime}/>
            <Slider
                id="time-slider"
                value={currentTime}
                min={0}
                max={lastTime}
                step="1"
                displayValueDenominator={1000}
                unitsName={!isWatchMode ? locale?.get.studio.header.toolbar.baseBlock.timeUnits : ""}
                sliderShouldHide={!isWatchMode}
                onChange={handleTimeChange}
                hintModeKey="timeSlider"
            />
            <Slider
                id="scale-slider"
                value={scale}
                min="0"
                max="5.5"
                step="0.01"
                displayValueDenominator={0.01}
                unitsName="%"
                onChange={handleScaleChange}
                hintModeKey="scaleSlider"
            />
        </div>
    </div>;
}
