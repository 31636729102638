import Canvg from "canvg";


export const isMapChanged = (state) => {
    return !['saveToServer', 'createMap', 'saveToComputer'].includes(state.get("lastAction").type);
}

export const convertStateToSave = (state) => {
    let actionHistory = [];
    for (let i = 0; i < state.get("main").past.length; i++) {
        actionHistory.push(state.get("main").past[i].get("lastAction"));
    }
    return {
        menu: state.get("menu").toJS(),
        main: state.get("main").present.toJS(), //data.main is only needed to view the map by the user
        actionHistory: actionHistory
    };
};


export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};


export const takePngSnapshotOfTheCanvas = async () => {
    const el = document.getElementById("svg-canvas");

    var canvas = document.createElement('canvas');
    canvas.width = el.clientWidth;
    canvas.height = el.clientHeight;

    const ctx = canvas.getContext('2d');
    const svg = new XMLSerializer().serializeToString(el);
    const v = await Canvg.fromString(ctx, svg);

    await v.render();

    return await new Promise(resolve => canvas.toBlob(resolve));
}