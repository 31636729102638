import React, {useState, useEffect, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useApiClient} from "./useApiClient";
import {useLocalization} from "./useContextLocalization";
import {useCurrentUser} from "./useContextCurrentUser";
import {extractVectorArchive} from "../store/components/utils";
import {parseIconNodeFromDataUri} from "../studio/pureFunctions/iconNode";


export const IconSetContext = React.createContext();

export const IconSetProvider = ({children}) => {
    const [get, set] = useState({icons: {}, iconNodes: {}, parameters: {}, tabSet: []});

    const color = useSelector(state => state.get("menu").get("color"));
    const fontColor = useSelector(state => state.get("menu").get("fontColor"));

    const api = useApiClient();
    const locale = useLocalization();
    const user = useCurrentUser();
    const dispatch = useDispatch();

    const loadDefaultTools = async () => {
        let defaultToolArchive = await api.get(`/api/v1/store/account/default_figures`, {responseType: 'blob'});
        let defaultTool = await extractVectorArchive(defaultToolArchive.data, locale, false);
        if (defaultTool) {
            defaultTool = defaultTool.sort((l, r) => l.name >= r.name ? 1 : -1);
        }
        return defaultTool;
    };

    useEffect(async () => {
        let icons = {polygons: {}, icons: {}, polylines: {}};
        let iconNodes = {};

        //default tool and tool node loading
        let defaultTools = await loadDefaultTools();
        if (defaultTools) {
            let defaultIcons = {}, defaultPolygons = {}, defaultContours = {};
            for (let i = 0; i < defaultTools.length; i++) {
                const toolName = defaultTools[i].name.split(".")[0];
                const toolDataUri = defaultTools[i].data;

                let [type, , index] = toolName.split("-");
                if (type === "i") {
                    defaultIcons[index] = toolDataUri;
                    iconNodes[toolName] = parseIconNodeFromDataUri(toolDataUri);
                } else if (type === "p") {
                    defaultPolygons[index] = toolDataUri;
                } else if (type === "c") {
                    defaultContours[index] = toolDataUri;
                }
            }
            icons.icons["s"] = {
                name: {russian: "Стандартные", english: "Standard", chinese: "Стандартные"},
                icons: defaultIcons,
                version: ""
            };
            icons.polygons["s"] = {name: {russian: "Стандартные", english: "Standard", chinese: "Стандартные"}, icons: defaultPolygons, version: ""};
            icons.polylines["s"] = {name: {russian: "Стандартные", english: "Standard", chinese: "Стандартные"}, icons: defaultContours, version: ""};
        }

        //custom tool and tool node loading
        let productVersions = [];
        try {
            productVersions = (await api.get("/api/v1/store/product_version/me"))?.data;
        } catch (e) {}

        for (let i = 0; i < productVersions.length; i++) {
            let productVersion = productVersions[i];
            let id = productVersion.id,
                name = productVersion.name,
                category = productVersion.type,
                version = productVersion.version;

            if (category === 0) {
                let productIconArchive = await api.get(`/api/v1/store/product_version/${id}/file`, {responseType: 'blob'});
                let iconsFromProduct = await extractVectorArchive(productIconArchive.data, locale, false);
                if (iconsFromProduct) {
                    iconsFromProduct = iconsFromProduct.sort((l,  r) => l.name >= r.name ? 1 : -1);

                    let iconDataFromProduct = {};
                    for (let i = 0; i < iconsFromProduct.length; i++) {
                        iconDataFromProduct[i] = iconsFromProduct[i].data;
                        iconNodes[`i-${id}-${i}`] = parseIconNodeFromDataUri(iconsFromProduct[i].data);
                    }
                    icons.icons[id] = {name: name, icons: iconDataFromProduct, version: version};
                }
            } else if (category === 2) {
                let polygon = await api.get(`/api/v1/store/product_version/${id}/file`);
                icons.polygons[id] = {
                    name: name,
                    icons: {"0": "data:image/svg+xml;base64," + btoa(polygon.data)},
                    version: version,
                }
            }
        }

        //tab and tool parameter loading
        let defaultTabs = (await api.get("/api/v1/store/account/tab_set"))?.data;

        set({icons: icons, iconNodes: iconNodes, parameters: defaultTabs.parameters, tabSet: defaultTabs.tab_set});

        //set menu tool, color and fontColor
        const defaultTool = Object.keys(defaultTabs.parameters)?.[0] || "";
        dispatch({type: "tool", value: defaultTool});

        if (defaultTool.split("-")[0] === "c" && color === "#FFFFFF") {
            dispatch({type: "color", value: defaultTabs.parameters[defaultTool].color});
        }
        else if (defaultTool.split("-")[0] === "p" && fontColor === "#FFFFFF") {
            dispatch({type: "fontColor", value: defaultTabs.parameters[defaultTool].color});
        }
    }, [user]);

    return <IconSetContext.Provider value={{get, set}}>
        {children}
    </IconSetContext.Provider>;
};

export const useIconSet = () => useContext(IconSetContext);
