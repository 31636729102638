import React from "react";

import BaseBlock from "./baseBlock/BaseBlock";
import ToolSelector from "./toolSelector/ToolSelector";
import QuickLaunchBar from "./quickLaunchBar/QuickLaunchBar";


export default function Toolbar() {
    return <div className="toolbar">
        <BaseBlock/>
        <QuickLaunchBar/>
        <ToolSelector/>
    </div>;
}
