import {Map} from 'immutable';


const initialState = Map({
    isGridShow: false,
    isWatchMode: false,
    isEditMode: true,
    isContextMenuShow: "no",
    isValidationAnimationShow: false,
    openContextMenuTool: "",
    isUserCanChangeCanvasSize: true,
    backgroundImage: "",
    canvasWidth: 1200,
    canvasHeight: 675,
    scale: window.innerWidth / 1220,
    prevScale: window.innerWidth / 1220,
    clipboard: [],
    selectedFigureUuids : [],
    tool: "",
    color: '#FFFFFF',
    fontColor: '#607d8b',
    openedMenuItemNumber: "-1",
    figureUuidDescriptionPopupShownFor: "",
    figureCenter: {x: 0, y: 0},
    mouseXAtTheClipboardTime: 0,
    mouseYAtTheClipboardTime: 0,
    startTime: new Date().getTime(),
    fontSize: 10,
    volume: 40,
    thickness: 2,
    dashLength: 0,
    transparency: 1
});

export default (state=initialState, action) => {
    if (action && action.type && reducers()[action.type]) {
        return reducers()[action.type](action);
    } else {
        return state;
    }

    function reducers() {
        return {
            loadMenuState: (action) => Map(action.data),
            clearMenuState: () => initialState,

            isGridShow: (action) => state.set("isGridShow", action.value),
            isWatchMode: (action) => state.set("isWatchMode", action.value),
            isEditMode: (action) => state.set("isEditMode", action.value),
            isContextMenuShow: (action) => (["group", "figure", "figure-neighborhood", "text", "no"].includes(action.value)
                ? state.set("isContextMenuShow", action.value)
                : state
            ),
            isValidationAnimationShow: (action) => state.set("isValidationAnimationShow", action.value),
            openContextMenuTool: (action) => state.set("openContextMenuTool", action.value),
            isUserCanChangeCanvasSize: (action) => state.set("isUserCanChangeCanvasSize", action.value),
            backgroundImage: (action) => state.set("backgroundImage", action.value),
            canvasWidth: (action) => state.set("canvasWidth", action.value),
            canvasHeight: (action) => state.set("canvasHeight", action.value),
            scale: (action) => state.set("scale", action.value),
            prevScale: (action) => state.set("prevScale", action.value),
            clipboard: (action) => state.set("clipboard", action.value),
            selectedFigureUuids: (action) => state.set("selectedFigureUuids", action.value),
            tool: (action) => state.set("tool", action.value),
            color: (action) => state.set("color", action.value),
            fontColor: (action) => state.set("fontColor", action.value),
            openedMenuItemNumber: (action) => state.set("openedMenuItemNumber", action.value),
            figureUuidDescriptionPopupShownFor: (action) => state.set("figureUuidDescriptionPopupShownFor", action.value),
            figureCenter: (action) => state.set("figureCenter", action.value),
            mouseXAtTheClipboardTime: (action) => state.set("mouseXAtTheClipboardTime", action.value),
            mouseYAtTheClipboardTime: (action) => state.set("mouseYAtTheClipboardTime", action.value),
            startTime: (action) => state.set("startTime", action.value),
            fontSize: (action) => state.set("fontSize", action.value),
            volume: (action) => state.set("volume", action.value),
            thickness: (action) => state.set("thickness", action.value),
            dashLength: (action) => state.set("dashLength", action.value),
            transparency: (action) => state.set("transparency", action.value),
        }
    }
}
