import React, {useState} from "react";

import Subpartition from "./Subpartition";
import Tool from "./Tool";
import {NextToolArrow, PrevToolArrow} from "./controls";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import useMeasure from "../../../../customHooks/useMeasure";
import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function Partition({partition, partitionIndex, capturedResizerIndex, capturedResizerWidthShift, width}) {
    const partitionItemCount = partition.children.length;
    const minPartitionWidth = 70;
    const arrowsCumulativeWidth = 64.8;
    const currentWidth = Math.max(
        width + (capturedResizerIndex === partitionIndex && capturedResizerWidthShift),
        minPartitionWidth
    ) || 0;

    const locale = useLocalization();
    const {get: {parameters}} = useIconSet();
    const {isWatchMode} = useReduxData();

    const [openedSubpartitionIndex, setOpenedSubpartitionIndex] = useState(-1);
    const [firstItemIndex, setFirstItemIndex] = useState(0);

    const [partitionTitleRef, {width: partitionTitleWidth}] = useMeasure();
    //if we don't forward partitionIndex, measures will not to be changed when index changed
    const [partitionRef, {left: partitionLeft, top: partitionTop}] = useMeasure(partitionIndex);

    const shouldShowTitle = partitionTitleWidth <= currentWidth - arrowsCumulativeWidth;

    const scrollToNextTool = () => setFirstItemIndex((firstItemIndex + 1) % partitionItemCount);
    const scrollToPrevTool = () => setFirstItemIndex((firstItemIndex - 1 + partitionItemCount) % partitionItemCount);

    return <div className="toolbar-partition"
        id={`toolbar-partition-${partitionIndex}`}
        style={{minWidth: currentWidth, width: currentWidth}}
    >
        <div className="toolbar-partition-item-container" ref={partitionRef}>
            {!partition.isComplicated
                ? (partition.children.slice(firstItemIndex).concat(partition.children.slice(0, firstItemIndex))
                    .map((tool, i) => <Tool
                        key={`tool-${i}`}
                        tool={tool}
                        type=""
                        hintModeKey={i === 0 && "toolbarTool"}
                        tooltipContent={parameters[tool]?.title?.[locale?.get.name] || ""}
                    />)
                )
                : partition.children.slice(firstItemIndex).map((subpartition, i) =>
                    <Subpartition
                        key={`tool-${i}`}
                        subpartition={subpartition}
                        subpartitionIndex={i + firstItemIndex}
                        isOpened={(i + firstItemIndex) === openedSubpartitionIndex}
                        setOpenedSubpartitionIndex={setOpenedSubpartitionIndex}
                        positionIndex={i}
                        partitionPosition={{left: partitionLeft, top: partitionTop}}
                        hintModeKey={i === 0 && "toolbarSubpartition"}
                        tooltipContent={subpartition.name[locale?.get.name]}
                    />
                ).concat(partition.children.slice(0, firstItemIndex).map((subpartition, i) =>
                    <Subpartition
                        key={`tool-${partition.children.slice(firstItemIndex).length + i}`}
                        subpartition={subpartition}
                        subpartitionIndex={i}
                        isOpened={i === openedSubpartitionIndex}
                        setOpenedSubpartitionIndex={setOpenedSubpartitionIndex}
                        positionIndex={(i - firstItemIndex + partitionItemCount) % partitionItemCount}
                        partitionPosition={{left: partitionLeft, top: partitionTop}}
                        hintModeKey={i === 0 && "toolbarSubpartition"}
                        tooltipContent={subpartition.name[locale?.get.name]}
                    />
                ))
            }
        </div>
        <div className={`toolbar-partition-bottom-container${partition.isComplicated ? "-complicated" : ""}`
            + `${isWatchMode ? " disabled" : ""}`
        }>
            <PrevToolArrow onClick={scrollToPrevTool}/>
            <p className="toolbar-partition-title"
                ref={partitionTitleRef}
                style={{opacity: shouldShowTitle ? 1 : 0, position: shouldShowTitle ? "static" : "absolute"}}
            >{partition.name[locale?.get.name]}</p>
            <NextToolArrow onClick={scrollToNextTool}/>
        </div>
    </div>;
}
