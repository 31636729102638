import Async from "react-select/async";
import {createOption, useTheme} from "@semograph/semui";

import {ClearIndicator, DropdownIndicator, IndicatorSeparator, ValueContainer} from "../../CustomIcons";
import {useApiClient} from "../../../../customHooks/useApiClient";
import {useLocalization} from "../../../../customHooks/useContextLocalization";


const ProductUsers = props => {
    const api = useApiClient();
    const locale = useLocalization();
    const theme = useTheme();

    const searchUsers = async inputValue => inputValue.length > 2 && api.get(
        `/api/v1/store/user-list/`,
        {params: {user_input: inputValue}}
    ).then(result => result.data.map(createOption));

    const getNoOptionMessage = () => locale?.get?.store.product.form.noOptions;
    const getLoadingMessage = () => locale?.get?.store.product.form.loading;
    const setFieldValue = options => props.setFieldValue(props.fieldName, options.map(option => option.value));

    return <div className = "product-form-input-wrapper">
        <Async
            isClearable
            isMulti
            isPlanar
            closeMenuOnSelect
            cacheOptions
            defaultOptions
            className={theme?.Select?.select}
            loadOptions={searchUsers}
            name={props.fieldName}
            placeholder={locale?.get?.store.product.form.usersPlaceholder}
            components={{ClearIndicator, DropdownIndicator, IndicatorSeparator, ValueContainer}}
            noOptionsMessage={getNoOptionMessage}
            loadingMessage={getLoadingMessage}
            onChange={setFieldValue}
            value={props.formValues[props.fieldName] ? props.formValues[props.fieldName].map(createOption) : []}
        />
    </div>
}

export default ProductUsers;