import {useState, useEffect} from "react";

import {deleteAt} from "../../../../../pureFunctions";
import {getHexCodeBySeparateChannels} from "./pureFunctions/color";
import {getWeightedAverage} from "./pureFunctions/getKeyOfMaxVal";


export default function ({data}) {
    const [dataToRender, setDataToRender] = useState([]);
    const [settlementsAvailableToShow, setSettlementsAvailableToShow] = useState([]);
    const [settlementsToShow, setSettlementsToShow] = useState([]);
    const [settlementsToShowTransparencies, setSettlementsToShowTransparencies] = useState({});
    const [currentMaxFrequency, setCurrentMaxFrequency] = useState(0);
    const [shouldShowCooRanges, setShouldShowCooRanges] = useState(true);

    useEffect(() => {
        if (data) {
            const jsonData = JSON.parse(data.replace(/'/g, '"'));

            const creationTimesOfSettlements = [...new Set(Object.keys(jsonData).map(
                settlement => +getWeightedAverage(jsonData[settlement].orderIndex)
            ))].sort((a, b) => (+a - +b));
            console.log(jsonData);

            const preparedData = {};
            Object.keys(jsonData).map(settlement => {
                preparedData[settlement] = {
                    minX: Math.min(...Object.keys(jsonData[settlement].x).map(coo => +coo)),
                    maxX: Math.max(...Object.keys(jsonData[settlement].x).map(coo => +coo)),
                    minY: Math.min(...Object.keys(jsonData[settlement].y).map(coo => +coo)),
                    maxY: Math.max(...Object.keys(jsonData[settlement].y).map(coo => +coo)),
                    x: getWeightedAverage(jsonData[settlement].x),
                    y: getWeightedAverage(jsonData[settlement].y),
                    fontColor: getHexCodeBySeparateChannels(
                        getWeightedAverage(jsonData[settlement].fontColorR),
                        getWeightedAverage(jsonData[settlement].fontColorG),
                        getWeightedAverage(jsonData[settlement].fontColorB)
                    ),
                    color: getHexCodeBySeparateChannels(
                        getWeightedAverage(jsonData[settlement].colorR),
                        getWeightedAverage(jsonData[settlement].colorG),
                        getWeightedAverage(jsonData[settlement].colorB)
                    ),
                    volume: getWeightedAverage(jsonData[settlement].volume),
                    fontSize: getWeightedAverage(jsonData[settlement].fontSize),
                    orderIndex: creationTimesOfSettlements.indexOf(
                        getWeightedAverage(jsonData[settlement].orderIndex)
                    ) + 1
                };
            });
            setDataToRender(preparedData);
            setSettlementsAvailableToShow(Object.keys(jsonData).sort());
            setSettlementsToShow(Object.keys(jsonData).sort());
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            const jsonData = JSON.parse(data.replace(/'/g, '"'));
            const maxFreq = Math.max(...Object.keys(jsonData).map(
                settlement => (settlementsToShow.indexOf(settlement) !== -1 ? jsonData[settlement].frequency : 0)
            ));
            const transparencies = {};
            Object.keys(jsonData).map(settlement => {
                transparencies[settlement] = (
                    "0" + Math.round(jsonData[settlement].frequency / maxFreq * 255).toString(16)
                ).slice(-2);
            });
            setCurrentMaxFrequency(maxFreq);
            setSettlementsToShowTransparencies(transparencies);
        }
    }, [settlementsToShow]);

    const switchSettlementVisibility = e => {
        const switcherName = e.target.id.split("-").slice(4).join("-");
        if (switcherName === "all") {
            if (settlementsToShow.length === settlementsAvailableToShow.length) {
                setSettlementsToShow([]);
            } else {
                setSettlementsToShow(settlementsAvailableToShow);
            }
        } else {
            const switcherIndex = settlementsToShow.indexOf(switcherName);
            if (switcherIndex === -1) {
                setSettlementsToShow(settlementsToShow.concat(switcherName));
            } else {
                setSettlementsToShow(deleteAt(settlementsToShow, switcherIndex));
            }
        }
    }

    const switchShouldShowCooRanges = () => setShouldShowCooRanges(!shouldShowCooRanges);

    return <div style={{display: "flex"}}>
        <svg style={{height: "43rem", width: "100%"}}>
            {Object.keys(dataToRender).map(settlement => settlementsToShow.indexOf(settlement) !== -1 ? <g>
                {shouldShowCooRanges && <rect
                    x={dataToRender[settlement].minX + 100}
                    y={dataToRender[settlement].minY + 50}
                    width={dataToRender[settlement].maxX - dataToRender[settlement].minX}
                    height={dataToRender[settlement].maxY - dataToRender[settlement].minY}
                    fill={`${dataToRender[settlement].color}10`}
                    stroke="none"
                    transform="scale(0.48, 1.8)"
                />}
                <text
                    fill={`${dataToRender[settlement].fontColor}${settlementsToShowTransparencies[settlement]}`}
                    x={(dataToRender[settlement].x + dataToRender[settlement].volume + 100) * 0.48 + 2}
                    y={(dataToRender[settlement].y + 50) * 1.8 + dataToRender[settlement].fontSize * 0.7}
                    fontSize={dataToRender[settlement].fontSize * 2}
                >{`${settlement} (${dataToRender[settlement].orderIndex})`}</text>
                <circle
                    r={dataToRender[settlement].volume / 2}
                    cx={(dataToRender[settlement].x + 100) * 0.48}
                    cy={(dataToRender[settlement].y + 50) * 1.8}
                    fill={`${dataToRender[settlement].color}${settlementsToShowTransparencies[settlement]}`}
                    stroke={`#000000${settlementsToShowTransparencies[settlement]}`}
                />
            </g> : null)}
        </svg>
        <div style={{height: "43rem"}}>
            <div style={{height: "2rem", display: "flex", alignItems: "center"}}>
                <p>{`Max frequency: ${Math.round(currentMaxFrequency * 100)}%`}</p>
            </div>
            <div style={{height: "3rem", display: "flex", alignItems: "center"}}>
                <input type="checkbox" checked={shouldShowCooRanges} onChange={switchShouldShowCooRanges}/>
                <p>Show dispersion of coordinates</p>
            </div>
            <div style={{overflowY: "auto", height: "38rem"}}>
                <table>
                    <tr>
                        <td>
                            <input
                                type="checkbox"
                                id="wfpm-p-visualizer-filter-all"
                                checked={settlementsToShow.length === settlementsAvailableToShow.length}
                                onChange={switchSettlementVisibility}
                            />
                        </td>
                        <td>All</td>
                    </tr>
                    {settlementsAvailableToShow.map(settlement => <tr>
                        <td>
                            <input
                                type="checkbox"
                                id={`wfpm-p-visualizer-filter-${settlement}`}
                                checked={settlementsToShow.indexOf(settlement) !== -1}
                                onChange={switchSettlementVisibility}
                            />
                        </td>
                        <td>{settlement} ({dataToRender[settlement].orderIndex})</td>
                    </tr>)}
                </table>
            </div>
        </div>
    </div>;
}
