import Icon from "../Icon";


export default function IconDragPreview({iconIds, icons}) {
    const iconsToRender = iconIds.map((child, i) => {
        if (typeof child === "object") {
            child = child.id;
        }
        const [type, productId, index] = child.split("-");
        const fullType = type === "p" ? "polygons" : (type === "i" ? "icons" : "polylines");
        const icon = icons[fullType][productId].icons[index];

        return <div key={`custom-drag-layer-preview-icon-${i}`} className="custom-drag-layer-preview-icon-wrapper">
            <Icon
                id=""
                icon={icon}
                isSelected={false}
            />
        </div>;
    });

    return (<div className="custom-drag-layer-preview-wrapper">
        <div className="custom-drag-layer-preview" role="IconPreview">
            {iconsToRender.length <= 5
                ? iconsToRender
                : <>
                    {iconsToRender.slice(0, 5)}
                    <span className="custom-drag-layer-preview-etc">...</span>
                </>
            }
        </div>
    </div>);
}
