import {useEffect, useState} from "react";
import JSZip from "jszip";
import {toast} from 'react-toastify';

import {useApiClient} from "../../customHooks/useApiClient";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";


function sortTable(mapInfo, sortRule) {
    const [columnName, order] = sortRule.split("-");
    if (columnName !== "mv_id") {
        mapInfo.sort((l, r) => l[columnName] > r[columnName] ? +(order === "asc") || -1 : +(order === "desc") || -1);
    } else {
        mapInfo.sort((l, r) =>
            +l.mv_id.split("-")[0] > +r.mv_id.split("-")[0]
            || (+l.mv_id.split("-")[0] === +r.mv_id.split("-")[0] && +l.mv_id.split("-")[1] > +r.mv_id.split("-")[1])
                ? +(order === "asc") || -1
                : +(order === "desc") || -1
        );
    }
    return mapInfo;
}


export default function ({serverMapInfo, setServerMapInfo}) {
    const api = useApiClient();
    const user = useCurrentUser();

    const [shouldShowOnlyLastUserMap, setShouldShowOnlyLastUserMap] = useState(false);
    const [shouldShowOnlyLastMapVersion, setShouldShowOnlyLastMapVersion] = useState(false);
    const [shouldUseAllUsers, setShouldUseAllUsers] = useState(true);
    const [shouldExcludeUsers, setShouldExcludeUsers] = useState(true);
    const [usersToConfine, setUsersToConfine] = useState("");
    const [loadingStatus, setLoadingStatus] = useState("");
    const [sortBy, setSortBy] = useState("");

    const loadTable = async ({
        lastUserMapOnly = shouldShowOnlyLastUserMap,
        lastMapVersionOnly = shouldShowOnlyLastMapVersion,
        useAllUsers = shouldUseAllUsers,
        excludeUsers = shouldExcludeUsers
    }) => {
        setLoadingStatus("computing...");
        const archiveFile = (await api.get(
            "/api/v1/researches/maps-info/",
            {
                params: {
                    last_user_map_only: lastUserMapOnly,
                    last_map_version_only: lastMapVersionOnly,
                    confine_users_mode: useAllUsers ? "" : excludeUsers ? "ex" : "in",
                    users_to_confine: usersToConfine
                },
                responseType: "blob",
                onDownloadProgress: e => setLoadingStatus(`loading: ${Math.round((e.loaded * 100) / e.total)}%`)
            }
        ))?.data;
        setLoadingStatus("");

        if (!archiveFile) {
            return [];
        } else {
            let zip = JSZip();
            try {
                let archive = await zip.loadAsync(archiveFile);
                if (!archive || !archive?.files || !Object.keys(archive.files).length) {
                    return [];
                }

                let zipEntries = [];
                archive.forEach((relativePath, zipEntry) => {
                    zipEntries.push(zipEntry);
                });
                let jsonData = await archive.file(zipEntries[0].name)?.async("string");
                return JSON.parse(jsonData);
            } catch (error) {
                console.log(error);
                return [];
            }
        }
    }

    useEffect(async () => {
        setServerMapInfo(await loadTable({}));
    }, [user]);

    const switchShouldShowOnlyLastUserMapCheckbox = async () => {
        setShouldShowOnlyLastUserMap(!shouldShowOnlyLastUserMap);
        setServerMapInfo(await loadTable({lastUserMapOnly: !shouldShowOnlyLastUserMap}));
    }

    const switchShouldShowOnlyLastMapVersionCheckbox = async () => {
        setShouldShowOnlyLastMapVersion(!shouldShowOnlyLastMapVersion);
        setServerMapInfo(await loadTable({lastMapVersionOnly: !shouldShowOnlyLastMapVersion}));
    }

    const switchShouldUseAllUsersCheckbox = async () => {
        setShouldUseAllUsers(!shouldUseAllUsers);
        setServerMapInfo(await loadTable({useAllUsers: !shouldUseAllUsers}));
    }

    const switchShouldExcludeUsers = async () => {
        setShouldExcludeUsers(!shouldExcludeUsers);
        setServerMapInfo(await loadTable({excludeUsers: !shouldExcludeUsers}));
    }

    const updateExcludedUsers = event => setUsersToConfine(event.target.value);

    const refreshTable = async () => setServerMapInfo(await loadTable({}));

    const copyLinkForMap = async event => {
        const mapId = event.target.id.split("-")[3], mapVersionNum = event.target.id.split("-")[4];
        const shareToken = (await api.put(`/api/v1/researches/link/${mapId}/${mapVersionNum}/`)
            .catch(() => toast.error("Map is too old"))
        )?.data;
        if (shareToken) {
            window.open(`${location.origin}/share?share_token=${encodeURIComponent(shareToken)}`, '_blank').focus();
        }
    };

    const setSorting = event => {
        const columnName = event.target.id.split("-")[3];
        const [lastSortColumn, lastSortOrder] = sortBy.split("-");
        setSortBy(`${columnName}-${lastSortColumn === columnName  && lastSortOrder === "asc" ? "desc" : "asc"}`);
    }

    return <div className="research-table-container">
        <h3>Maps & Users (*MV - Map Version)</h3>
        <div>
            <div>
                <input type="checkbox" id="show-last-user-map" onClick={switchShouldShowOnlyLastUserMapCheckbox}/>
                <label htmlFor="show-last-user-map">Show only the last map of the user</label>
            </div>
            <div>
                <input type="checkbox" id="show-last-map-version" onClick={switchShouldShowOnlyLastMapVersionCheckbox}/>
                <label htmlFor="show-last-map-version">Show only the last version of the map</label>
            </div>
            <div>
                <input type="checkbox" id="exclude-some-users" onClick={switchShouldUseAllUsersCheckbox}/>
                <label htmlFor="exclude-some-users">
                    {<label
                        className={shouldExcludeUsers ? "research-confine-mode-active" : "research-confine-mode"}
                        onClick={switchShouldExcludeUsers}
                    >Ex</label>}
                    /
                    {<label
                        className={!shouldExcludeUsers ? "research-confine-mode-active" : "research-confine-mode"}
                        onClick={switchShouldExcludeUsers}
                    >In</label>}
                    clude following users:
                    <input
                        type="text"
                        className="research-exclude-user-textbox"
                        placeholder="user_id_1, user_id_2"
                        onChange={updateExcludedUsers}
                    />
                </label>
            </div>
        </div>
        <div className="research-table-update-container">
            <input type="button" className="research-table-update-button" value="Update" onClick={refreshTable}/>
            {`map count: ${serverMapInfo.length}${loadingStatus ? ", " + loadingStatus : ""}`}
        </div>
        <table>
            <tbody>
                <tr>
                    <td id="research-table-cell-user_id" onClick={setSorting}>
                        User id{sortBy.split("-")[0] === "user_id" ? sortBy.split("-")[1] === "asc" ? "↑" : "↓" : ""}
                    </td>
                    <td className="research-table-cell-blue" id="research-table-cell-user" onClick={setSorting}>
                        User{sortBy.split("-")[0] === "user" ? sortBy.split("-")[1] === "asc" ? "↑" : "↓" : ""}
                    </td>
                    <td className="research-table-cell-date" id="research-table-cell-mv_created" onClick={setSorting}>
                        MV Created{sortBy.split("-")[0] === "mv_created" ? sortBy.split("-")[1] === "asc" ? "↑" : "↓" : ""}
                    </td>
                    <td className="research-table-cell-blue" id="research-table-cell-mv_id" onClick={setSorting}>
                        MV id{sortBy.split("-")[0] === "mv_id" ? sortBy.split("-")[1] === "asc" ? "↑" : "↓" : ""}
                    </td>
                </tr>
                {sortTable(serverMapInfo, sortBy).map((row, i) => <tr key={`research-table-row-${i}`}>
                    <td>{row.user_id}</td>
                    <td className="research-table-cell-blue">{row.user}</td>
                    <td>{row.mv_created}</td>
                    <td className="research-table-cell-blue">
                        <input
                            type="button"
                            className="research-table-create-link"
                            id={`research-table-id-${row.mv_id}`}
                            value={row.mv_id}
                            onClick={copyLinkForMap}
                        />
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>;
}