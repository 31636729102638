import {debounce, select} from 'redux-saga/effects'

import {convertStateToSave} from "./customHooks/useContextMap/pureFunctions";


function* autosave() {
    const state = yield select();
    const data = convertStateToSave(state);
    localStorage.setItem("autosave", JSON.stringify(data));
}

export function* mainSaga() {
    yield debounce(1000, "*", autosave);
}
