import React from "react";
import {useQuery} from "react-query";

import {useApiClient} from "../../../customHooks/useApiClient";
import {useLocalization} from "../../../customHooks/useContextLocalization";


const ProductCardPhoto = props => {

    const api = useApiClient();
    const locale = useLocalization();

    const cardPhotoQuery = useQuery(["store/cardPhoto", props.id], async () => {
        try {
            let blob = await api.get(`/api/v1/store/product_version/${props.id}/card_photo`, {responseType: 'blob'});
            blob = blob.data;
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            let base64Image = await new Promise(resolve => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            });
            return new Promise(resolve => resolve(base64Image));
        } catch (e) {
            return new Promise(resolve => resolve(""));
        }
    });
    const cardPhoto = cardPhotoQuery.data;

    // console.log(cardPhotoQuery);
    // console.log(cardPhoto);

    return <div className = "product-card-image" style = {{
        backgroundImage: `url(${cardPhoto})`,
        backgroundPosition: "center",
        backgroundRepeat: props.type === 3 ? "repeat" : "no-repeat",
        backgroundSize: "contain",
    }}>
        {props.isUpdate && <span className="product-card-version-text">{locale?.get?.store.new}</span>}
        {props.version && <span className="product-card-version-text">v{props.version}</span>}
    </div>;
}

export default ProductCardPhoto;