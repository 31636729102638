import React from "react";

import Maps from "./Maps";
import {useLocalization} from "../../../customHooks/useContextLocalization";


export default function MyMaps() {
    const locale = useLocalization();
    return <Maps
        mapsLink="/api/v1/map/"
        title={locale?.get?.studio.myMaps.myMaps}
        noMapsMessage={locale?.get?.studio.myMaps.youHaveNoMaps}
        showSpace={true}
    />;
}
