import React from "react";

import Maps from "./Maps";
import {useLocalization} from "../../../customHooks/useContextLocalization";


export default function UsersMaps() {
    const locale = useLocalization();
    return <Maps
        mapsLink="/api/v1/map/shared_list/"
        title={locale?.get?.studio.usersMaps.usersMaps}
        noMapsMessage={locale?.get?.studio.usersMaps.youHaveNoMaps}
        showSpace={false}
    />
}
