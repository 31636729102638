import React from "react";

import Figure from "./Figure";
import {useReduxData} from "../../customHooks/useContextReduxData";


export default function Group(props) {
    const {figures} = useReduxData();

    return <g className="group" key={props.figure.uuid} id={props.figure.uuid}>
        {props.figure.children.map(u => <Figure {...props} key={u} figure={figures.get(u)} kind={"inGroup"}/>)}
    </g>;
}
