import {useEffect, useState} from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useApiClient} from "../../../../customHooks/useApiClient";


const ProductMapLoader = props => {
    const locale = useLocalization();
    const api = useApiClient();

    const [maps, setMaps] = useState([]);
    const [chosenMap, setChosenMap] = useState();
    const [initialImage, setInitialImage] = useState(props.initialImage);

     useEffect(() => {
        api.get('/api/v1/map/').then(resp => setMaps(resp.data));
    }, [api]);

    const chooseMap = async event => {
        let map = maps[event.target.id.split("-")[3]];
        let image = `/maps/${map.uuid}.preview.${map.version}.png`;
        props.setFieldValue("card_photo", image);

        const map_entity = await api.get(`/api/v1/map/${map.id}`);
        let file = new Blob([JSON.stringify(map_entity.data.data)], {type : 'application/json'})
        props.setFieldValue("file", file);
        setChosenMap(map);
    };

    const clearField = event => {
        event.stopPropagation();
        setInitialImage(undefined);
        setChosenMap(undefined);
        props.setFieldValue("card_photo", "");
        props.setFieldValue("file", undefined);
    };

    return <div className="product-form-archive-wrapper">
        {chosenMap || initialImage
            ? <div className="product-form-archive" style={{
                backgroundImage: `url(${initialImage
                    ? initialImage
                    : `/maps/${chosenMap.uuid}.preview.${chosenMap.version}.png`
                })`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
            }}>
                <div className="product-form-archive-button-replace" onClick={clearField}/>
                <div className="product-form-archive-button-remove" onClick={clearField}/>
            </div>
            : <>
                <label className="product-form-add-file-link">{locale?.get?.store.product.form.chooseMap}</label>
                <div className="product-form-maps-container">
                    {maps.map((map, i) => <div
                        key={map.id}
                        className="product-form-map"
                        id={`product-form-map-${i}`}
                        onClick={chooseMap}
                        style={{
                            background: `url("/maps/${map.uuid}.preview.${map.version}.png") 100% 100% no-repeat`,
                            backgroundSize: "100% 100%"
                        }}
                    >{map.name}</div>)}
                </div>
            </>
        }
    </div>;
}

export default ProductMapLoader;
