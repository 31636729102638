import React from "react";

function Link (props) {
    return (
        <a
            href = {props.link}
            target="_blank"
            rel="noopener noreferrer"
        >
            {props.content}
        </a>
    );
}

export default function getOnboardingParams (lang) {
    return {
        base: {
            showProgress: true,
            showSkipButton: true,
            steps: [
                {
                    target: "body",
                    content: lang.studio.onboarding.baseSteps.welcomeTo,
                    disableBeacon: true,
                    placement: "center"
                },
                {
                    target: '.top-menu-container',
                    content: <>
                        {lang.studio.onboarding.baseSteps.menu}
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/start/1-map-editor/`} content = {lang.studio.onboarding.manual}/>
                    </>,
                },
                {
                    target: '.map-title-header',
                    content: <>
                        {lang.studio.onboarding.baseSteps.mapName}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/map-studio/1-common-information/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                },
                {
                    target: '.svg-canvas',
                    content: <>
                        {lang.studio.onboarding.baseSteps.canvas}
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/map-studio/6-canvas/`} content = {lang.studio.onboarding.manual}/>
                    </>,
                },
                {
                    target: '.toolbar',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolbar}
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/map-studio/5-toolbar/`} content = {lang.studio.onboarding.manual}/>
                    </>,
                },
                {
                    target: '.lang-current',
                    content: <>
                        {lang.studio.onboarding.baseSteps.localization}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/3-change-lang/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                },
                {
                    target: '.editor-mode-switch',
                    content: lang.studio.onboarding.baseSteps.editorMode,
                },
                {
                    target: '#editor-redacting-mode',
                    content: lang.studio.onboarding.baseSteps.editingMode,
                },
                {
                    target: '#editor-watch-mode',
                    content: <>
                        {lang.studio.onboarding.baseSteps.watchMode}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/map-studio/3-map-view/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                },
                {
                    target: '#editor-animate-mode',
                    content: lang.studio.onboarding.baseSteps.animationMode,
                },
                {
                    target: '#time-slider',
                    content: lang.studio.onboarding.baseSteps.timeSlider,
                },
                {
                    target: '#scale-slider',
                    content: <>
                        {lang.studio.onboarding.baseSteps.scaleSlider}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/map-studio/4-resizing-and-scaling/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                },
                {
                    target: '.quick-launch-bar',
                    content: lang.studio.onboarding.baseSteps.quickLaunchBar,
                },
                {
                    target: '.tool-selector',
                    content: lang.studio.onboarding.baseSteps.toolSelector,
                },
                {
                    target: '#toolbar-partition-0',
                    content: <>
                        {lang.studio.onboarding.baseSteps.polylines}
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/37-draw-object-with-pencil/`} content = {lang.studio.onboarding.withPencil}/>
                        {lang.studio.onboarding.and}
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/38-draw-object-with-polylines/`} content = {lang.studio.onboarding.withPolylines}/>
                    </>,
                },
                {
                    target: '#toolbar-partition-1',
                    content: <>
                        {lang.studio.onboarding.baseSteps.polygons}
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/37-draw-object-with-pencil/`} content = {lang.studio.onboarding.withPencil}/>
                        {lang.studio.onboarding.and}
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/38-draw-object-with-polylines/`} content = {lang.studio.onboarding.withPolylines}/>
                    </>,
                },
                {
                    target: '#toolbar-partition-2',
                    content: <>
                        {lang.studio.onboarding.baseSteps.points}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/36-draw-point-figure/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                },
                {
                    target: '.tool-menu',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenu}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/map-studio/7-editing-objects/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '.tool-menu-header',
                    content: lang.studio.onboarding.baseSteps.toolMenuDnD,
                    placement: "right",
                },
                {
                    target: '#tool-menu-mode-container',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuModes}
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/20-set-cursor-to-pencil-mode/`} content = {lang.studio.onboarding.manual}/>
                    </>,

                    placement: "right",
                },
                {
                    target: '#tool-menu-item-0',
                    content: lang.studio.onboarding.baseSteps.toolMenuEditMode,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-1',
                    content: lang.studio.onboarding.baseSteps.toolMenuGrid,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-2',
                    content: lang.studio.onboarding.baseSteps.toolMenuGroup,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-3',
                    content: lang.studio.onboarding.baseSteps.toolMenuUngroup,
                    placement: "right",
                },
                {
                    target: '#tool-menu-container',
                    content: lang.studio.onboarding.baseSteps.toolMenuParameters,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-4',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuName}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/23-change-figure-name/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-5',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuDescription}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/24-describe-object/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-6',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuFontSize}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/31-change-object-font-size/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-7',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuEmojis}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/25-show-impressions-of-object/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-8',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuFontColor}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/30-change-object-name-color/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-9',
                    content: lang.studio.onboarding.baseSteps.toolMenuLink,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-10',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuColor}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/29-change-object-color/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-11',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuTransparency}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/32-change-object-transparency/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-12',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuThickness}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/33-change-contour-object-thickness/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-13',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuResize}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/28-change-point-figure-size/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-14',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuDash}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/27-change-contour-object-dash-array/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '#tool-menu-item-15',
                    content: <>
                        {lang.studio.onboarding.baseSteps.toolMenuFlag}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/34-add-flag-to-settlement/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "right",
                },
                {
                    target: '.share-container',
                    content: <>
                        {lang.studio.onboarding.baseSteps.mapSharing}
                        <br/>
                        <Link link = {`/docs/${lang.abbr.toLowerCase()}/examples/45-share-map-with-friends/`} content = {lang.studio.onboarding.learnMore}/>
                    </>,
                    placement: "top"
                },
                {
                    target: "body",
                    content: lang.studio.onboarding.baseSteps.conclusion,
                    disableBeacon: true,
                    placement: "center"
                },
            ]
        },

        menuFile: {
            content: lang.studio.onboarding.hintMode.menuFile,
            placement: "right"
        },
        menuEdit: {
            content: lang.studio.onboarding.hintMode.menuEdit,
            placement: "right"
        },
        menuHelp: {
            content: lang.studio.onboarding.hintMode.menuHelp,
            placement: "right"
        },
        menuOnboarding: {
            content: lang.studio.onboarding.hintMode.menuOnboarding,
            placement: "right"
        },
        menuStore: {
            content: lang.studio.onboarding.hintMode.menuStore,
            placement: "right"
        },

        mapName: {
            content: lang.studio.onboarding.hintMode.mapName,
            placement: "down"
        },

        currentLocale: {
            content: lang.studio.onboarding.hintMode.currentLocale,
            placement: "left"
        },
        editorModeSwitch: {
            content: lang.studio.onboarding.hintMode.editorModeSwitch,
            placement: "left"
        },
        timeSlider: {
            content: lang.studio.onboarding.hintMode.timeSlider,
            placement: "left"
        },
        scaleSlider: {
            content: lang.studio.onboarding.hintMode.scaleSlider,
            placement: "left"
        },
        quickLaunchBarUndo: {
            content: lang.studio.onboarding.hintMode.quickLaunchBarUndo,
            placement: "left"
        },
        quickLaunchBarRedo: {
            content: lang.studio.onboarding.hintMode.quickLaunchBarRedo,
            placement: "left"
        },
        quickLaunchBarCopy: {
            content: lang.studio.onboarding.hintMode.quickLaunchBarCopy,
            placement: "left"
        },
        quickLaunchBarPaste: {
            content: lang.studio.onboarding.hintMode.quickLaunchBarPaste,
            placement: "left"
        },
        quickLaunchBarSave: {
            content: lang.studio.onboarding.hintMode.quickLaunchBarSave,
            placement: "right"
        },
        quickLaunchBarPrint: {
            content: lang.studio.onboarding.hintMode.quickLaunchBarPrint,
            placement: "right"
        },
        quickLaunchBarTable: {
            content: lang.studio.onboarding.hintMode.quickLaunchBarTable,
            placement: "right"
        },
        quickLaunchBarRoller: {
            content: lang.studio.onboarding.hintMode.quickLaunchBarRoller,
            placement: "right"
        },

        toolbarPartitionArrowPrev: {
            content: lang.studio.onboarding.hintMode.toolbarPartitionArrowPrev,
            placement: "right"
        },
        toolbarPartitionArrowNext: {
            content: lang.studio.onboarding.hintMode.toolbarPartitionArrowNext,
            placement: "down"
        },
        toolbarPartitionResizer: {
            content: lang.studio.onboarding.hintMode.toolbarPartitionResizer,
            placement: "up"
        },
        toolbarToolsArrowPrev: {
            content: lang.studio.onboarding.hintMode.toolbarToolsArrowPrev,
            placement: "right"
        },
        toolbarToolsArrowNext: {
            content: lang.studio.onboarding.hintMode.toolbarToolsArrowNext,
            placement: "left"
        },
        toolbarTool: {
            content: lang.studio.onboarding.hintMode.toolbarTool,
            placement: "right"
        },
        toolbarSubpartition: {
            content: lang.studio.onboarding.hintMode.toolbarSubpartition,
            placement: "right"
        },

        baseTransformationalConnector: {
            content: lang.studio.onboarding.hintMode.baseConnector,
            placement: "up"
        },
        directiveTransformationalConnector: {
            content: lang.studio.onboarding.hintMode.directiveConnector,
            placement: "up"
        },
        closingConnector: {
            content: lang.studio.onboarding.hintMode.closingConnector,
            placement: "up"
        },
        snappingConnector: {
            content: lang.studio.onboarding.hintMode.bicolorConnector,
            placement: "up"
        },
        multisnappingConnector: {
            content: lang.studio.onboarding.hintMode.tricolorConnector,
            placement: "up"
        },

        toolMenuDnD: {
            content: lang.studio.onboarding.hintMode.toolMenuDnD,
            placement: "right",
        },
        toolMenuModes: {
            content: lang.studio.onboarding.hintMode.toolMenuModes,
            placement: "right",
        },
        toolMenuEditMode: {
            content: lang.studio.onboarding.hintMode.toolMenuEditMode,
            placement: "right",
        },
        toolMenuGrid: {
            content: lang.studio.onboarding.hintMode.toolMenuGrid,
            placement: "right",
        },
        toolMenuParameters: {
            content: lang.studio.onboarding.hintMode.toolMenuParameters,
            placement: "right",
        },
        toolMenuGroup: {
            content: lang.studio.onboarding.hintMode.toolMenuGroup,
            placement: "right",
        },
        toolMenuUngroup: {
            content: lang.studio.onboarding.hintMode.toolMenuUngroup,
            placement: "right",
        },
        toolMenuName: {
            content: lang.studio.onboarding.hintMode.toolMenuName,
            placement: "right",
        },
        toolMenuDescription: {
            content: lang.studio.onboarding.hintMode.toolMenuDescription,
            placement: "right",
        },
        toolMenuLink: {
            content: lang.studio.onboarding.hintMode.toolMenuLink,
            placement: "right",
        },
        toolMenuEmojis: {
            content: lang.studio.onboarding.hintMode.toolMenuEmojis,
            placement: "right",
        },
        toolMenuDash: {
            content: lang.studio.onboarding.hintMode.toolMenuDash,
            placement: "right",
        },
        toolMenuResize: {
            content: lang.studio.onboarding.hintMode.toolMenuResize,
            placement: "right",
        },
        toolMenuColor: {
            content: lang.studio.onboarding.hintMode.toolMenuColor,
            placement: "right",
        },
        toolMenuFontColor: {
            content: lang.studio.onboarding.hintMode.toolMenuFontColor,
            placement: "right",
        },
        toolMenuFontSize: {
            content: lang.studio.onboarding.hintMode.toolMenuFontSize,
            placement: "right",
        },
        toolMenuTransparency: {
            content: lang.studio.onboarding.hintMode.toolMenuTransparency,
            placement: "right",
        },
        toolMenuThickness: {
            content: lang.studio.onboarding.hintMode.toolMenuThickness,
            placement: "right",
        },
        toolMenuFlag: {
            content: lang.studio.onboarding.hintMode.toolMenuFlag,
            placement: "right",
        },
        mapSharing: {
            content: lang.studio.onboarding.hintMode.mapSharing,
            placement: "top"
        }
    };
}
