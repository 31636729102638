import React from "react";
import {useQuery} from "react-query";
import {toast} from "react-toastify";
import JSZip from "jszip";

import {useApiClient} from "../../../customHooks/useApiClient";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {extractRasterArchive} from "../utils";


const ProductPreview = props => {

    const api = useApiClient();
    const locale = useLocalization();

    const previewQuery = useQuery(["store/preview", props.id], async () => {
        try {
            let blob = await api.get(`/api/v1/store/product_version/${props.id}/raster_archive`, {responseType: 'blob'});
            blob = blob.data;
            let icons = extractRasterArchive(blob, locale);
            return new Promise(resolve => resolve(icons));
        } catch (e) {
            return new Promise(resolve => resolve([]));
        }
    });
    const preview = previewQuery.data;

    // console.log(previewQuery);
    // console.log(preview);

    return preview ?
        <div className = "product-raster-preview-container">
            {preview.map((icon, i) =>
                <div
                    key={`product-raster-preview-icon-${i}`}
                    className = "product-raster-preview-icon"
                    style={{
                        backgroundImage: `url(${icon.data})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain"
                    }}
                />
            )}
        </div>
        : null;
}

export default ProductPreview;