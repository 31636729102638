import React, {useState} from 'react';


export default function DirectiveConnector(props) {
    const [isConnectorCaptured, setIsConnectorCaptured] = useState(false);

    const handleMouseDown = () => setIsConnectorCaptured(true);
    const handleMouseUp = () => setIsConnectorCaptured(false);

    return <circle
        className="transformational-connector"
        id={`${props.parentUuid}|${props.segmentIndex}|${props.indexWithinSegment}`}
        cx={props.x}
        cy={props.y}
        r="4"
        stroke="black"
        fill={isConnectorCaptured ? "red" : "silver"}
        opacity={props.opacity}
        pointerEvents={props.opacity ? "auto" : "none"}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
    />;
}
