import React from "react";

import {ReactComponent as Arrow} from "../../../images/md-editor/arrow.svg";


const RetractableSubsection = ({idSuffix, name, children, ...props}) => {
    return <div className="retractable-subsection-container">
        <input
            type="checkbox"
            className="retractable-subsection-checkbox"
            id={`retractable-subsection-checkbox-${idSuffix}`}
            name={`retractable-subsection-checkbox-${idSuffix}`}
        />
        <label
            className={props.labelClass || "retractable-subsection-checkbox-label"}
            htmlFor={`retractable-subsection-checkbox-${idSuffix}`}
        >
            <Arrow className="retractable-subsection-checkbox-label-arrow"/>{name}
        </label>
        <div className="retractable-subsection-content-container">{children}</div>
    </div>;
};

export default RetractableSubsection;