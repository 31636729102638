import React from "react";
import {useDispatch} from "react-redux";
import {flattenDeep} from "lodash";

import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {areAllElementsEqual, getChildrenValues, setFixedChildrenValues} from "../../../../pureFunctions/usefulFunctions";
import getTimeElapsedSince from "../../../getTimeElapsedSince";


export default function ContextMenuSlider({action, purpose, title, min, max, step}) {
    const locale = useLocalization();
    const {getFiguresUpdatedOn, selectedFigureUuids, startTime} = useReduxData();
    const figures = getFiguresUpdatedOn([
        "addPolygon",
        "addPolyline",
        "addPointFeature",
        "addGroup",
        "changeObjectParentUuid",
        "changeFigureDashLength",
        "changeFigureFontSize",
        "changeFigureTransparency",
        "changeFigureLayout",
        "changeFigureVolume",
        "changeFigureThickness",
        "clearState",
        "deleteFigure",
        "disbandGroup"
    ]);

    const dispatch = useDispatch();

    const handleSliderChanged = event => selectedFigureUuids.map(uuid => {
        setFixedChildrenValues(
            figures,
            uuid,
            [purpose],
            dispatch,
            [event.target.value],
            action,
            getTimeElapsedSince(startTime)
        );
        if (action === "changeFigureVolume") {
            let oldSize = +figures.get(uuid).get("volume");

            setFixedChildrenValues(
                figures,
                uuid,
                ["x", "y"],
                dispatch,
                [
                    +figures.get(uuid).get("x") - (+event.target.value - oldSize) * 2,
                    +figures.get(uuid).get("y") - (+event.target.value - oldSize) * 2
                ],
                "moveFigure",
                getTimeElapsedSince(startTime)
            );
        }
        return undefined;
    });

    let currentValue = "NaN";

    if (selectedFigureUuids.length !== 0) {
        let parameterArray = [];
        selectedFigureUuids.map(u => {
            let tempArr = flattenDeep([getChildrenValues(figures, u, purpose)]);
            if (typeof tempArr === "object") {
                tempArr.map(el => parameterArray.push(el));
            } else {
                parameterArray.push(tempArr);
            }
            return undefined;
        });

        if (areAllElementsEqual(parameterArray) && parameterArray[0] !== undefined) {
            currentValue = parameterArray[0];
        }
    }

    return <div className="context-menu-slider-container">
        <label htmlFor="size" className="context-menu-slider-title">
            {title + (currentValue === "standard" ? locale?.get.studio.floatingMenu.sliderStandard : (
                (currentValue === undefined || isNaN(+currentValue))
                    ? locale?.get.studio.floatingMenu.undefined
                    : currentValue
            ))}
        </label>
        <input
            className="context-menu-slider"
            type="range"
            name="size"
            value={isNaN(+currentValue) ? -1 : currentValue}
            min={min}
            max={max}
            step={step}
            onChange={handleSliderChanged}
        />
    </div>;
}
