import React, {useState, useEffect, useRef, useCallback} from "react";

import SurveyPage from "./SurveyPage";
import SurveyNavigator from "./SurveyNavigator";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useApiClient} from "../../../../customHooks/useApiClient";
import {useModal} from "../../../../customHooks/useContextModal";
import {useSurveyScheme} from "../../../customHooks/useContextSurveyScheme";


export default function Survey({closeButton}) {
    const [page, setPage] = useState(0);
    const [lang, setLang] = useState("default");
    const [answers, setAnswers] = useState([]);
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);

    const api = useApiClient();
    const locale = useLocalization();
    const modal = useModal();
    const surveyScheme = useSurveyScheme();

    const surveyRef = useRef(null);

    useEffect(() => {
        if (isSurveyCompleted) {
            function closeSurvey () {
                surveyScheme.set(false);
                modal.set("");
            }
            setTimeout(closeSurvey, 2000);
        }
    }, [isSurveyCompleted]);

    useEffect(() => {
        if (surveyScheme?.get) {
            let answersArr = [];
            surveyScheme.get.pages.map(page => page.elements.map(el => el.id && answersArr.push(null)));
            setAnswers(answersArr);
        }
    }, [surveyScheme?.get]);

    useEffect(() => surveyRef?.current && surveyRef.current.focus(), [page]);

    useEffect(
        () => locale?.get && surveyScheme?.get
            && Object.keys(surveyScheme.get.pages[page].title).indexOf(locale.get.abbr.toLowerCase()) !== -1
            && setLang(locale.get.abbr.toLowerCase()),
        [locale?.get, surveyScheme?.get]
    );

    const closeSurvey = data => setIsSurveyCompleted(data);
    const turnPage = () => setPage(page + 1);
    const turnPageBack = () => setPage(page - 1);
    const turnPageOnEnter = event => (event.key === "Enter" && (page === surveyScheme.get.pages.length - 1 ? sendSurvey() : turnPage()));

    const sendSurvey =  useCallback(async () => {
        setIsSurveyCompleted("onSurveySendText");
        try {
            await api.post("/api/v1/surveys/", {answers: JSON.stringify(answers)});
        } catch (err) {
            if (err.response.status === 409) {
                // no need to show survey
            }
        }
    }, [answers]);

    return !isSurveyCompleted
        ? <>
            {closeButton || null}
            <div
                className="modal-container"
                ref={surveyRef}
                tabIndex="0"
                onKeyDownCapture={turnPageOnEnter}
            >
                {surveyScheme?.get && <>
                    <SurveyPage
                        content={surveyScheme.get.pages[page]}
                        lang={lang}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                    <SurveyNavigator
                        closeSurvey={closeSurvey}
                        turnPage={turnPage}
                        turnPageBack={turnPageBack}
                        sendSurvey={sendSurvey}
                        currentPage={page}
                        pagesCount={surveyScheme.get.pages.length}
                        content={surveyScheme.get}
                        lang={lang}
                    />
                </>}
            </div>
        </>
        : surveyScheme?.get && <div className="modal-container">
            <h4 className="modal-title survey-finish-title">{surveyScheme.get[isSurveyCompleted][lang]}</h4>
        </div>;
}
