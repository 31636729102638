import React, {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import {Icon} from "@semograph/semui";

import {useLocalization} from "../../../customHooks/useContextLocalization";
import {SearchBoldIcon} from "../CustomIcons";

import {ReactComponent as SearchLightIcon} from "../../images/search-light.svg";

import searchStyles from "./search.module.css";


const Search = () => {

    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchedText, setSearchedText] = useState(false);

    const inputRef = useRef(null);

    const history = useHistory();
    const locale = useLocalization();

    useEffect(() => {
        if (isSearchActive && inputRef?.current) {
            inputRef.current.focus();
        }
    }, [isSearchActive]);

    const search = () => {
        if (isSearchActive) {
            history.push(`/all?search_text=${searchedText}`);
            window.location.reload();
        }
        setIsSearchActive(!isSearchActive);
    };

    const searchByEnter = event => {
        if (event.key === "Enter") {
            search();
        }
    };

    const onInput = event => {
        setSearchedText(event.target.value);
    };

    //library classes applies after classes defined in component, so to override them, inline styles needed
    return (isSearchActive
        ? (<div style = {{display: "flex"}}>
            <input
                type = "text"
                className = {searchStyles["search-active-input"]}
                placeholder = {locale?.get?.store.header.searchPlaceholder}
                ref = {inputRef}
                onChange = {onInput}
                onKeyDown = {searchByEnter}
            />
            <div className = {searchStyles["search-active-wrapper"]}>
                <Icon
                    key = {1}
                    icon = {SearchBoldIcon}
                    onClick = {search}
                />
            </div>
        </div>)
        : <SearchLightIcon className = {searchStyles.search} onClick={search}/>
    );
};

export default Search;
