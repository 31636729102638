import {useEffect, useState} from "react";

import tooltipHOC from "../../../../../components/tooltipHOC";


const HandleLabel = ({name, style={}}) => {
    const [canRender, setCanRender] = useState(false);

    useEffect(() => {
        function showText () {
            setCanRender(true);
        }
        const timer = setTimeout(showText, 500);  // otherwise, the styles do not have time to compute
        return () => clearTimeout(timer);
    }, []);

    return <label className="research-dfd-node-handle-name" style={style}>{canRender ? name : ""}</label>;
};


export default tooltipHOC(HandleLabel, undefined, undefined, {direction: "right", padding: "10px"});
