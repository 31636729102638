import {Map} from 'immutable';
import {v4 as uuid} from "uuid";
import {flattenDeep} from "lodash";

import PointFeature from '../schemas/pointFeature';
import Polyline from '../schemas/polyline';
import Group from '../schemas/group';
import Polygon from '../schemas/polygon';
import Path from "../pureFunctions/path";
import {getChildrenValues, isItemInArray} from '../pureFunctions/usefulFunctions';


const initialState = Map({
    figures: Map([]),
    watch: Map({
        figures: Map([])
    }),
    mapName: "Новая карта",
    mapId: uuid(),
    lastAction: {type: "createMap"},
    version: (
        [].slice.call(document.getElementsByTagName("meta"))
            .find(el => el.name === "release")?.content?.split("@")[1]
        || `dev (${new Date().toDateString()})`
    )
});

export default (state=initialState, action) => {
    if (action && action.type && reducers()[action.type]) {
        if (action.x !== undefined && action.y !== undefined) {
            if (typeof(action.x) === "object") {
                if (action.x) { //null is an object too
                    action.x = action.x.map(xCoo => Path.roundCooToHundredths(xCoo));
                    action.y = action.y.map(yCoo => Path.roundCooToHundredths(yCoo));
                }
            } else {
                // console.log(action.x);
                action.x = Path.roundCooToHundredths(action.x);
                action.y = Path.roundCooToHundredths(action.y);
            }
        }
        if (action.points !== undefined) {
            action.points = Path.roundToHundredths(action.points);
        }

        if (!["addPolygon", "addPolyline", "addPointFeature", "addGroup"].includes(action.type)
            && action.uuid
            && !state.get("figures").get(action.uuid)
        ) {
            return state;
        }

        const s = reducers()[action.type](state, action);
        if (action.type === "loadStateByTime" || action.type === "setMapId") {
            return s;
        }
        // console.log(`setting last action... ${action.type}`);

        // console.log(s.get("lastAction"));
        return action.type !== "clearState" ? s.set("lastAction", action) : s.set("lastAction", {type: "createMap"});
    } else {
        return state;
    }

    //как функция, чтобы можно было редьюсеры в конце файла разместить
    function reducers() {
        return {
            addPolygon: (state, payload, mode = "") => {
                let newFigure = new Polygon({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    points: payload.points,
                    x: payload.x,
                    y: payload.y,
                    tool: payload.tool,
                    color: payload.color,
                    emoji: payload.emoji,
                    fontColor: payload.fontColor,
                    fontSize: payload.fontSize,
                    transparency: payload.transparency,
                    name: payload.name,
                    link: payload.link,
                    description: payload.description,
                    layout: payload.layout,
                    orderIndex: payload.orderIndex
                });
                return mode === "watch" ?
                    state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]))) :
                    state.set("figures", state.get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]));

            },
            addPolyline: (state, payload, mode = "") => {
                let newFigure = new Polyline({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    points: payload.points,
                    dashLength: payload.dashLength,
                    x: payload.x,
                    y: payload.y,
                    thickness: payload.thickness,
                    tool: payload.tool,
                    color: payload.color,
                    transparency: payload.transparency,
                    name: payload.name,
                    link: payload.link,
                    description: payload.description,
                    enclosed: payload.enclosed,
                    layout: payload.layout,
                    orderIndex: payload.orderIndex
                });
                return mode === "watch" ?
                    state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]))) :
                    state.set("figures", state.get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]));

            },
            addPointFeature: (state, payload, mode = "") => {
                let newFigure = new PointFeature({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    x: payload.x,
                    y: payload.y,
                    volume: payload.volume,
                    tool: payload.tool,
                    color: payload.color,
                    emoji: payload.emoji,
                    flags: payload.flags,
                    fontColor: payload.fontColor,
                    fontSize: payload.fontSize,
                    transparency: payload.transparency,
                    name: payload.name,
                    link: payload.link,
                    description: payload.description,
                    layout: payload.layout,
                    orderIndex: payload.orderIndex
                });
                return mode === "watch" ?
                    state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]))) :
                    state.set("figures", state.get("figures").concat([[
                        payload.uuid,
                        newFigure
                    ]]));
            },
            addGroup: (state, payload, mode = "") => {
                const newGroup = new Group({
                    uuid: payload.uuid,
                    parentUuid: payload.parentUuid,
                    tool: payload.tool || "g-s-72", //TODO: for backward compatibility / move it to updater
                    children: payload.children,
                    name: payload.name || "", //TODO: for backward compatibility / move it to updater
                    description: payload.description || "", //TODO: for backward compatibility / move it to updater
                    layout: payload.layout,
                    orderIndex: payload.orderIndex
                });
                if (mode === "watch") {
                    payload.children.map((u, i) => {
                        state = state.set("watch", state.get("watch")
                            .set("figures", state.get("watch").get("figures").set(u,
                                state.get("watch").get("figures").get(u)
                                .set("parentUuid", payload.uuid)
                                // .set("layout", payload.layout) // если нужно стереть старый слой ребёнка
                            ))
                        );
                        return undefined;
                    });
                    return state.set("watch", state.get("watch").set(
                        "figures",
                        state.get("watch").get("figures").concat([[payload.uuid, newGroup]])
                    ));
                } else {
                    payload.children.map(u => {
                        state = state.set("figures",
                            state.get("figures").set(u,
                                state.get("figures").get(u)
                                .set("parentUuid", payload.uuid)
                                // .set("layout", payload.layout) // если нужно стереть старый слой ребёнка
                            )
                        );
                        return undefined;
                    });
                    return state.set("figures", state.get("figures").concat([[payload.uuid, newGroup]]));
                }
            },
            changeObjectName: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("name", payload.name)
                    )
                ))
                : state.set("figures", state.get("figures").set(
                    payload.uuid,
                    state.get("figures").get(payload.uuid).set("name", payload.name)
                )),
            changeObjectDescription: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("description", payload.description)
                    )
                ))
                : state.set("figures",
                state.get("figures").set(payload.uuid,
                    state.get("figures").get(payload.uuid)
                    .set("description", payload.description))),
            changeObjectLink: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid)
                        .set("link", payload.link)
                    )
                ))
                : state.set("figures",
                state.get("figures").set(payload.uuid,
                    state.get("figures").get(payload.uuid)
                    .set("link", payload.link))),
            changeObjectParentUuid: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("parentUuid", payload.parentUuid)
                    )
                ))
                : state.set("figures",
                    state.get("figures").set(payload.uuid,
                        state.get("figures").get(payload.uuid).set("parentUuid", payload.parentUuid)
                    )
                ),
            changeFigureColor: (state, payload, mode = "") => {
                if (mode === "watch")
                    return state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures")
                        .set(payload.uuid, state.get("watch").get("figures").get(payload.uuid)
                            .set("color", payload.color)
                        )
                    ));
                return state.set("figures", state.get("figures").set(payload.uuid,
                    state.get("figures").get(payload.uuid).set("color", payload.color)
                ))
            },
            changeFigurePoints: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("points", payload.points)
                    )
                ))
                : state.set("figures",
                    state.get("figures").set(payload.uuid,
                        state.get("figures").get(payload.uuid).set("points", payload.points)
                    )
                ),
            changeFigureDashLength: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("dashLength", payload.dashLength)
                    )
                ))
                : state.set("figures",
                    state.get("figures").set(payload.uuid,
                        state.get("figures").get(payload.uuid)
                        .set("dashLength", payload.dashLength))
                ),
            addFigureEmoji: (state, payload, mode = "") => {
                if (mode === "watch") {
                    if (state.get("watch").get("figures").get(payload.uuid).get("emoji").length >= 10)
                        return state;
                    return state.set("watch", state.get("watch").set("figures",
                        state.get("watch").get("figures").set(payload.uuid,
                            state.get("watch").get("figures").get(payload.uuid)
                            .set("emoji", state.get("watch").get("figures").get(payload.uuid).get("emoji").concat(payload.emoji))
                        )
                    ));
                } else {
                    if (state.get("figures").get(payload.uuid).get("emoji").length >= 10)
                        return state;
                    return state.set("figures",
                        state.get("figures").set(payload.uuid,
                            state.get("figures").get(payload.uuid)
                            .set("emoji", state.get("figures").get(payload.uuid).get("emoji").concat(payload.emoji))
                        )
                    );
                }
            },
            deleteFigureEmoji: (state, payload, mode = "") => {
                if (mode === "watch") {
                    let emoji = state.get("watch").get("figures").get(payload.uuid).get("emoji");
                    let index = -1;
                    for (let i = 0; i<emoji.length; i++)
                        if (JSON.stringify(emoji[i]) === JSON.stringify(payload.emoji)) {
                            index = i;
                        }
                    if (index !== -1)
                        emoji.splice(index, 1);

                    return state.set("watch", state.get("watch").set("figures",
                        state.get("watch").get("figures").set(payload.uuid,
                            state.get("watch").get("figures").get(payload.uuid).set("emoji", emoji)
                        )
                    ));
                } else {
                    let emoji = state.get("figures").get(payload.uuid).get("emoji");
                    let index = -1;
                    for (let i = 0; i<emoji.length; i++)
                        if (JSON.stringify(emoji[i]) === JSON.stringify(payload.emoji)) {
                            index = i;
                        }
                    if (index !== -1)
                        emoji.splice(index, 1);

                    return state.set("figures", state.get("figures").set(payload.uuid,
                        state.get("figures").get(payload.uuid).set("emoji", emoji)
                    ));
                }
            },
            addFigureFlag: (state, payload, mode = "") => {
                if (mode === "watch") {
                    if (state.get("watch").get("figures").get(payload.uuid).get("flags").length >= 3)
                        return state;
                    return state.set("watch", state.get("watch").set("figures",
                        state.get("watch").get("figures").set(payload.uuid,
                            state.get("watch").get("figures").get(payload.uuid)
                            .set("flags", state.get("watch").get("figures").get(payload.uuid).get("flags").concat(payload.flags))
                        )
                    ));
                } else {
                    if (state.get("figures").get(payload.uuid).get("flags").length >= 3)
                        return state;
                    return state.set("figures",
                        state.get("figures").set(payload.uuid,
                            state.get("figures").get(payload.uuid)
                            .set("flags", state.get("figures").get(payload.uuid).get("flags").concat(payload.flags))
                        )
                    );
                }
            },
            deleteFigureFlag: (state, payload, mode = "") => {
                if (mode === "watch") {
                    let flags = state.get("watch").get("figures").get(payload.uuid).get("flags");
                    let index = -1;
                    for (let i = 0; i < flags.length; i++)
                        if (flags[i] === payload.flags) {
                            index = i;
                        }
                    if (index !== -1)
                        flags.splice(index, 1);

                    return state.set("watch", state.get("watch").set("figures",
                        state.get("watch").get("figures").set(payload.uuid,
                            state.get("watch").get("figures").get(payload.uuid).set("flags", flags)
                        )
                    ));
                } else {
                    let flags = state.get("figures").get(payload.uuid).get("flags");
                    let index = -1;
                    for (let i = 0; i < flags.length; i++)
                        if (flags[i] === payload.flags) {
                            index = i;
                        }
                    if (index !== -1)
                        flags.splice(index, 1);
                    return state.set("figures", state.get("figures").set(payload.uuid,
                        state.get("figures").get(payload.uuid).set("flags", flags)
                    ));
                }
            },
            changeFigureFontColor: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("fontColor", payload.fontColor)
                    )
                ))
                : state.set("figures",
                    state.get("figures").set(payload.uuid,
                        state.get("figures").get(payload.uuid).set("fontColor", payload.fontColor)
                    )
                ),
            changeFigureFontSize: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid)
                            .set("fontSize", (+payload.fontSize >= 0 ? payload.fontSize : "standard"))
                    )
                ))
                : state.set("figures",
                state.get("figures").set(payload.uuid,
                    state.get("figures").get(payload.uuid)
                    .set("fontSize", (+payload.fontSize >= 0 ? payload.fontSize : "standard")))),
            changeFigureTransparency: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("transparency", payload.transparency)
                    )
                ))
                : state.set("figures", state.get("figures").set(payload.uuid,
                    state.get("figures").get(payload.uuid).set("transparency", payload.transparency)
                )),
            changeFigureLayout: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("layout", payload.layout)
                    )
                ))
                : state.set("figures", state.get("figures").set(payload.uuid,
                        state.get("figures").get(payload.uuid).set("layout", payload.layout)
                )),
            changeFigureVolume: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("volume", payload.volume)
                    )
                ))
                : state.set("figures", state.get("figures").set(payload.uuid,
                        state.get("figures").get(payload.uuid).set("volume", payload.volume)
                )),
            changeFigureThickness: (state, payload, mode = "") => mode === "watch"
                ? state.set("watch", state.get("watch").set("figures",
                    state.get("watch").get("figures").set(payload.uuid,
                        state.get("watch").get("figures").get(payload.uuid).set("thickness", payload.thickness)
                    )
                ))
                : state.set("figures", state.get("figures").set(payload.uuid,
                    state.get("figures").get(payload.uuid).set("thickness", payload.thickness)
                )),
            loadStateByTime: (state, payload) => {
                let newState = state;
                newState = newState.set("watch", initialState);

                let actionHistory = [];
                for (let i = 0; i < payload.past.length; i++) {
                    actionHistory.push(payload.past[i].get("lastAction"));
                }

                // console.log(actionHistory);
                // console.log(payload.past.map(p => p.get("lastAction").type));

                for (let i = 0; i < actionHistory.length; i++) {
                    if (!actionHistory[i].time || actionHistory[i].time <= +payload.currentTime) {
                        // console.log(actionHistory[i]);
                        newState = reducers()[actionHistory[i].type](newState, actionHistory[i], "watch");
                    } else {
                        break;
                    }
                }
                return newState;
            },
            clearState: (state, payload, mode = "") => (
                mode === "watch"
                    ? state.set("watch", payload.customName ? initialState.set("mapName", payload.customName) : initialState)
                    : (payload.customName ? initialState.set("mapName", payload.customName) : initialState)
            ),
            deleteFigure: (state, payload, mode = "") => {
                if (mode === "watch") {
                    let figures = state.get("watch").get("figures");
                    let uuids = flattenDeep([getChildrenValues(figures, payload.uuid, "uuid")]);
                    figures.map(figure => {
                        // console.log(figure.get("children")?.join(" "));
                        if (figure.get("children") && isItemInArray(payload.uuid, figure.get("children"))) {
                            let figureIndex = figure.get("children").indexOf(payload.uuid);
                            // console.log(figureIndex);
                            if (figureIndex !== -1) {
                                let newChildren = figure.get("children").slice(0, figureIndex).concat(figure.get("children").slice(figureIndex + 1));
                                // console.log(newChildren);

                                state = state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").set(figure.get("uuid"),
                                        state.get("watch").get("figures").get(figure.get("uuid")).set("children", newChildren)
                                )));

                                if (newChildren.length === 1) { //If group has the only child, then disband group

                                    state = state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").set(newChildren[0], //delete figure's parent entry
                                        state.get("watch").get("figures").get(newChildren[0]).set("parentUuid", undefined)
                                    )));

                                    if (figure.get("parentUuid")) { //if disbanded group into group

                                        //replacing group uuid to group child uuid
                                        let childGroupIndex = figures.get(figure.get("parentUuid")).get("children").indexOf(figure.get("uuid"));
                                        let newParentChildren = figures.get(figure.get("parentUuid")).get("children").slice(0, childGroupIndex).concat([newChildren[0]].concat(figure.get("children").slice(childGroupIndex + 1)));

                                        state = state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").set(figure.get("parentUuid"),
                                                state.get("watch").get("figures").get(figure.get("parentUuid")).set("children", newParentChildren)
                                        )));

                                        //replace parent uuid for child
                                        state = state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").set(newChildren[0],
                                            state.get("watch").get("figures").get(newChildren[0]).set("parentUuid", figure.get("parentUuid"))
                                        )));

                                        //delete disbanded group
                                        state = state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").delete(figure.get("uuid"))));
                                    }

                                    state = state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").delete(figure.get("uuid"))));
                                }
                            }
                        }
                        return undefined;
                    });

                    uuids.map(u => {
                        state = state.set("watch", state.get("watch").set("figures", state.get("watch").get("figures").delete(u)));
                        return undefined;
                    });
                } else {
                    let figures = state.get("figures");
                    let uuids = flattenDeep([getChildrenValues(figures, payload.uuid, "uuid")]);
                    figures.map(figure => {
                        // console.log(figure.get("children")?.join(" "));
                        if (figure.get("children") && isItemInArray(payload.uuid, figure.get("children"))) {
                            let figureIndex = figure.get("children").indexOf(payload.uuid);
                            // console.log(figureIndex);
                            if (figureIndex !== -1) {
                                let newChildren = figure.get("children").slice(0, figureIndex).concat(figure.get("children").slice(figureIndex + 1));
                                // console.log(newChildren);

                                state = state.set("figures", state.get("figures").set(figure.get("uuid"),
                                        state.get("figures").get(figure.get("uuid")).set("children", newChildren)
                                ));

                                if (newChildren.length === 1) { //If group has the only child, then disband group

                                    state = state.set("figures", state.get("figures").set(newChildren[0], //delete figure's parent entry
                                        state.get("figures").get(newChildren[0]).set("parentUuid", undefined)
                                    ));

                                    if (figure.get("parentUuid")) { //if disbanded group into group

                                        //replacing group uuid to group child uuid
                                        let childGroupIndex = figures.get(figure.get("parentUuid")).get("children").indexOf(figure.get("uuid"));
                                        let newParentChildren = figures.get(figure.get("parentUuid")).get("children").slice(0, childGroupIndex).concat([newChildren[0]].concat(figures.get(figure.get("parentUuid")).get("children").slice(childGroupIndex + 1)));

                                        state = state.set("figures", state.get("figures").set(figure.get("parentUuid"),
                                                state.get("figures").get(figure.get("parentUuid")).set("children", newParentChildren)
                                        ));

                                        //replace parent uuid for child
                                        state = state.set("figures", state.get("figures").set(newChildren[0],
                                            state.get("figures").get(newChildren[0]).set("parentUuid", figure.get("parentUuid"))
                                        ));

                                        //delete disbanded group
                                        state = state.set("figures", state.get("figures").delete(figure.get("uuid")));
                                    }

                                    state = state.set("figures", state.get("figures").delete(figure.get("uuid")));
                                }
                            }
                        }
                        return undefined;
                    });
                    // console.log(Object.keys(state.toJS().figures).map(u => state.toJS().figures[u].children?.join(" ")));
                    uuids.map(u => {
                        state = state.set("figures", state.get("figures").delete(u));
                        return undefined;
                    });
                }

                return state;
            },
            disbandGroup: (state, payload, mode = "") => {
                if (mode === "watch") {
                    payload.children.map(u => {
                        state = state.set("watch", state.get("watch").set("figures",
                            state.get("watch").get("figures").set(u,
                                state.get("watch").get("figures").get(u).set("parentUuid", undefined)
                            )
                        ));
                        return undefined;
                    });

                    return state.set("watch", state.get("watch").set("figures",
                        state.get("watch").get("figures").delete(payload.uuid)
                    ));
                } else {
                    payload.children.map(u => {
                        state = state.set("figures", state.get("figures").set(u,
                            state.get("figures").get(u).set("parentUuid", undefined)
                        ));
                        return undefined;
                    });

                    return state.set("figures", state.get("figures").delete(payload.uuid));
                }
            },
            moveFigure: (state, payload, mode = "") => {
                if (mode === "watch") {
                    return state.set("watch", state.get("watch").set("figures",
                        state.get("watch").get("figures").set(payload.uuid, state.get("watch").get("figures").get(payload.uuid)
                            .set("x", payload.x)
                            .set("y", payload.y)
                        )
                    ));
                } else {
                    return state.set("figures", state.get("figures").set(payload.uuid,
                        state.get("figures").get(payload.uuid).set("x", payload.x).set("y", payload.y)
                    ));
                }
            },
            createMap: state => state,
            saveToServer: state => state,
            saveToComputer: state => state,
            setMapName: (state, payload) => state.set("mapName", payload.name),
            setMapId: (state, payload) => state.set("mapId", payload.id)
        };
    }
};
