import {Record} from 'immutable';

class Polyline extends Record({
    uuid: undefined,
    parentUuid: undefined,
    x: undefined,
    y: undefined,
    points: undefined,
    thickness: undefined,
    dashLength: undefined,
    tool: "empty",
    color: null,
    transparency: undefined,
    name: "",
    link: "",
    description: "",
    enclosed: false,
    layout: undefined,
    orderIndex: undefined
}) {
}

export default Polyline;
