import React from "react";
import {useMutation, useQueryClient} from "react-query";
import {useHistory} from "react-router-dom";
import {Card, Icon, Rating} from "@semograph/semui";

import {PublishButton, UnpublishButton, UpdateButton} from "./ProductCardButtons";
import ProductCardPhoto from "./ProductCardPhoto";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useApiClient} from "../../../customHooks/useApiClient";
import {compareTags, cropString, getFieldContent} from "../utils";

import {StarHeavySmall, StarLightSmall} from "../CustomIcons";


function CustomRating(props) {
    return <Rating n = {props.n} iconOn = {StarHeavySmall} iconOff = {StarLightSmall}/>
}


export default function ProductCard({product}) {
    const locale = useLocalization();
    const user = useCurrentUser();
    const history = useHistory();
    const queryClient = useQueryClient();
    const api = useApiClient();

    const updateProduct = product_id => () => history.push(`/product/update/${product_id}`);

    const unpublishMutation = useMutation(
        (product_id) => api.put(`/api/v1/store/product_version/${product_id}/unpublish/`),
        {onSuccess: () => queryClient.refetchQueries(["publicationDependent"], {active: true})}
    );
    const unpublishProduct = product_id => (() => unpublishMutation.mutate(product_id));

    const publishMutation = useMutation(
        (product_id) => api.put(`/api/v1/store/product_version/${product_id}/publish/`),
        {onSuccess: () => queryClient.refetchQueries(["publicationDependent"], {active: true})}
    );
    const publishProduct = product_id => (() => publishMutation.mutate(product_id));

    const isUpdateAvailable = product.has_user_old_version && !product.has_user_this_version;

    return locale?.get && product && <Card
        key={product.id}
        price={locale.get.abbr === "EN"
            ? "₽" + (isUpdateAvailable ? product.update_price : product.price)
            : (isUpdateAvailable ? product.update_price : product.price) + " ₽"
        }
        name={cropString(getFieldContent(product.name, locale.get.abbr.toLowerCase()), 40)}
        tags={[cropString(product.tags.map(tag => tag.name).sort(compareTags(locale)).join(", "), 44)]}
        rating={product.rating}
        ratingComponent={CustomRating}
        href={`/store/product/${product.id}`}
        hasUser={product.author_id === user?.id}
        renderEditIcon={product.can_user_update ? (props) => <Icon icon={UpdateButton} {...props} /> : () => null}
        renderDeleteIcon={(props) => <Icon icon={product.published ? UnpublishButton : PublishButton} {...props} />}
        onEdit={updateProduct(product.id)}
        onDelete={product.published ? unpublishProduct(product.id) : publishProduct(product.id)}
        iconPreview={(props) => <Icon icon={(() => <ProductCardPhoto
            id={product.id}
            version={product.version_name}
            type={product.type}
            isUpdate={isUpdateAvailable}
        />)} {...props}/>}
    />;
}
