import React, {useState, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import _ from "lodash";
import {v4 as uuid} from "uuid";
import {ActionCreators} from 'redux-undo';
import {Scrollbars} from 'react-custom-scrollbars-2';

import FigureContainer from "./FigureContainer.js";
import SnappingConnector from "../connectors/snappingConnectors/SnappingConnector.js";
import PolygonsPatterns from "./PolygonsPatterns";
import {useCanvasBoundingClientRect} from "../../customHooks/useContextCanvasBoundingClientRect";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import {useReduxData} from "../../customHooks/useContextReduxData";
import useObjectSelection from "./customHooks/useObjectSelection/useObjectSelection";
import usePencilDrawing from "./customHooks/usePencilDrawing/usePencilDrawing";
import {renderObjectCopy, getRootUuid, isItemInArray, isFigureSelected} from '../../pureFunctions/usefulFunctions';
import {deleteAt} from "../../../pureFunctions";
import Path from "../../pureFunctions/path";
import getTimeElapsedSince from "../getTimeElapsedSince";
import {
    groupSchema,
    pointFeatureSchema,
    polygonSchema,
    polylineSchema,
    uuidArraySchema
} from "../../pureFunctions/validators";


const GROUP_LAYOUT = 50;


export default function Canvas() {
    const {
        backgroundImage,
        canvasHeight,
        canvasWidth,
        clipboard,
        color,
        dashLength,
        figureUuidDescriptionPopupShownFor,
        figures,
        fontColor,
        fontSize,
        isContextMenuShow,
        isEditMode,
        isWatchMode,
        mouseXAtTheClipboardTime,
        mouseYAtTheClipboardTime,
        nextFiguresUuids,
        openContextMenuTool,
        previousFiguresUuids,
        prevScale,
        scale,
        selectedFigureUuids,
        startTime,
        thickness,
        tool,
        transparency,
        volume
    } = useReduxData();
    const dispatch = useDispatch();

    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {get: {parameters, icons}} = useIconSet();
    const {
        rectangleSelection,
        selectObject,
        selectSubgroup,
        startRectangleSelection,
        changeSelectionRectangle,
        finishRectangleSelection,
        isRectangleSelectionActive
    } = useObjectSelection();
    const {
        startX,
        startY,
        pencilPath,
        handleMouseEnterOnConnector,
        getUuidsOfSnappingParticipants,
        addSegmentToPathOnMouseDown,
        addSegmentToPathOnMouseMove,
        createFigure
    } = usePencilDrawing();

    const [isScrolled, setIsScrolled] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
    const [mouseWasMoved, setMouseWasMoved] = useState(false);

    const svgCanvasContainer = useRef(null);
    const svgCanvas = useRef(null);
    const scrollbars = useRef(null);

    useEffect(() => {
        if (scrollbars?.current) {
            let scrollLeft = scrollbars.current.getScrollLeft() + (scale - prevScale) * canvasWidth / 2,
                scrollTop = scrollbars.current.getScrollTop() + (scale - prevScale) * canvasHeight / 2;
            scrollbars.current.scrollLeft(scrollLeft >= 0 ? scrollLeft : 0);
            scrollbars.current.scrollTop(scrollTop >= 0 ? scrollTop : 0);
        }
    }, [scale, prevScale, canvasHeight, canvasWidth]);

    useEffect(() => {
        //update bounding client rect after scale changing and page loading
        //an interval used instead of a timeout, because canvas may take longer, than a second to load
        function setCanvasBoundingClientRect () {
            if (svgCanvas?.current) {
                clearInterval(interval);
                return canvasBoundingClientRect?.set(svgCanvas.current.getBoundingClientRect());
            }
        }
        const interval = setInterval(setCanvasBoundingClientRect, 1000);
    }, [scrollbars.current?.getScrollLeft(), scrollbars.current?.getScrollTop()]);

    if (document.activeElement.tagName === "BODY" && svgCanvasContainer.current) {
        svgCanvasContainer.current.focus(); //если элемент с канваса исчез, то фокус уходит на боди и не работают хоткеи
    }

    // useLayoutEffect(() => {
    //     function updateSize() {
    //         setSize([window.innerWidth, window.innerHeight]);
    //     }
    //
    //     window.addEventListener('resize', updateSize);
    //     updateSize();
    //     return () => window.removeEventListener('resize', updateSize);
    // }, []);

    const [figureType, productId, figureIndex] = tool.split("-");
    const iconCursor = figureType === "i" && icons.icons?.[productId]
        ? icons.icons[productId].icons[figureIndex]
        : "";

    const createFigureFromPencil = (event, caller) => {
        let [path, minX, minY, isEnclosed] = createFigure(event, figureType, caller);
        if (Path.checkIfPathValid(path)) {
            const newFigureUuid = uuid();
            let newFigureObject = { //TODO: create create-figure function
                uuid: newFigureUuid,
                parentUuid: undefined,
                name: "",
                link: "",
                description: "",
                emoji: [],
                x: minX / scale,
                y: minY / scale,
                points: path,
                tool,
                color,
                transparency,
                layout: +parameters[tool]?.layout || 50,
                orderIndex: Math.round(new Date().getTime())
            };
            let newFigureAction;
            if (figureType === "p") {
                newFigureObject = {...newFigureObject, fontColor, fontSize};
                if (!polygonSchema.isValidSync(newFigureObject)) {
                    return;
                }
                newFigureAction = {...newFigureObject, type: "addPolygon"};
            } else {
                newFigureObject = {...newFigureObject, thickness, dashLength, enclosed: isEnclosed};
                if (!polylineSchema.isValidSync(newFigureObject)) {
                    return;
                }
                newFigureAction = {...newFigureObject, type: "addPolyline"};
            }
            dispatch({...newFigureAction, time: getTimeElapsedSince(startTime)});

            const newGroupChildrenUuids = getUuidsOfSnappingParticipants(newFigureUuid);
            if (newGroupChildrenUuids.length > 1) {
                const newGroupUuid = uuid();
                const newGroupObject = { //TODO: create create-group function
                    uuid: newGroupUuid,
                    parentUuid: undefined,
                    tool: "g-s-72",
                    children: newGroupChildrenUuids,
                    name: "",
                    description: "",
                    layout: GROUP_LAYOUT,
                    orderIndex: Math.round(new Date().getTime()),
                };

                if (!groupSchema.isValidSync(newGroupObject)) {
                    dispatch({type: "selectedFigureUuids", value: [newFigureUuid]});
                    return;
                }

                dispatch({...newGroupObject, type: "addGroup", time: getTimeElapsedSince(startTime)});
                dispatch({type: "selectedFigureUuids", value: [newGroupUuid]});
            } else {
                dispatch({type: "selectedFigureUuids", value: [newFigureUuid]});
            }
        }
    };

    const handleContextMenuOnCanvas = event => {
        event.preventDefault();
        if (!isWatchMode && isEditMode && tool) {
            setIsMouseDown(false);
            let targetFigureUuid = event.target.id.split("|")[0];
            if (!targetFigureUuid) {
                targetFigureUuid = event.target.parentNode.parentNode.id.split("|")[0];
            }
            if (isContextMenuShow === "no" && (
                selectedFigureUuids.length === 0 || isFigureSelected(targetFigureUuid, selectedFigureUuids, figures)
            )) {
                let targetClass = event.target.className.baseVal;
                dispatch({
                    type: "isContextMenuShow",
                    value: (["figure", "figure-neighborhood", "text"].includes(targetClass)
                        ? targetClass
                        : event.target.parentNode.parentNode.className.baseVal)
                });

                if (selectedFigureUuids.length === 0) {
                    let {type: selectionStatus, payload: selectionPayload} = selectObject(event);
                    if (selectionStatus === "resetSelection") {
                        dispatch({type: "selectedFigureUuids", value: []});
                    } else if (selectionStatus === "simpleSelection" && uuidArraySchema.isValidSync(selectionPayload)) {
                        dispatch({type: "selectedFigureUuids", value: selectionPayload});
                    }
                }
            }
        }
    };

    const selectSubgroupOnCanvas = event => {
        if (!isWatchMode && isEditMode) {
            const newSelection = selectSubgroup(event);
            if (uuidArraySchema.isValidSync(newSelection)) {
                dispatch({type: "selectedFigureUuids", value: newSelection});
            }
        }
    };

    const handleClickOnCanvas = event => {
        if (!isWatchMode) {
            if (!isEditMode) {
                dispatch({type: "isEditMode", value: !event.ctrlKey});
            } else if (!mouseWasMoved && tool) {
                const targetFigureUuid = event.target.id.split("|")[0];
                if (isFigureSelected(targetFigureUuid, selectedFigureUuids, figures)) {
                    let uuidIndex = selectedFigureUuids.indexOf(targetFigureUuid);
                    if (uuidIndex !== -1) {
                        dispatch({
                            type: "selectedFigureUuids",
                            value: !event.ctrlKey ? [] : deleteAt(selectedFigureUuids, uuidIndex)
                        });
                    }
                } else {
                    let {type: selectionStatus, payload: selectionPayload} = selectObject(event);
                    if (selectionStatus === "simpleSelection" && uuidArraySchema.isValidSync(selectionPayload)) {
                        if (selectionPayload.length === 1 && figures.get(selectionPayload[0]).get("tool") === "c-s-3") {
                            dispatch({type: "tool", value: "c-s-3"});
                        }
                        dispatch({
                            type: "selectedFigureUuids",
                            value: event.ctrlKey ? selectedFigureUuids.concat(selectionPayload) : selectionPayload
                        });
                    } else if (selectionStatus === "resetSelection") {
                        dispatch({type: "selectedFigureUuids", value: []});
                    }
                }
            }
        }
        setMouseWasMoved(false);
    };

    const handleMouseDownOnCanvas = event => {
        if (!isWatchMode && tool) {
            if (openContextMenuTool !== "no") {
                dispatch({type: "openContextMenuTool", value: "no"});
            }
            if (isContextMenuShow !== "no" && event.button !== 2) {
                dispatch({type: "isContextMenuShow", value: "no"});
            }

            if (!isEditMode) {
                let canvasX = canvasBoundingClientRect.get.left;
                let canvasY = canvasBoundingClientRect.get.top;
                if ((!canvasX && canvasX !== 0) || (!canvasY && canvasY !== 0)) {
                    return;
                }
                if (figureType === "i") {
                    let newFigureUuid = uuid();
                    let newFigureObject = {
                        uuid: newFigureUuid,
                        name: "",
                        link: "",
                        description: "",
                        emoji: [],
                        flags: [],
                        x: (event.clientX - canvasX) / scale - 2 * volume,
                        y: (event.clientY - canvasY) / scale - 1.5 * volume,
                        volume,
                        tool,
                        color,
                        fontColor,
                        fontSize,
                        transparency,
                        layout: +parameters[tool]?.layout || 50,
                        orderIndex: Math.round(new Date().getTime())
                    };

                    if (!pointFeatureSchema.isValidSync(newFigureObject)) {
                        return;
                    }

                    dispatch({...newFigureObject, type: "addPointFeature", time: getTimeElapsedSince(startTime)});
                    dispatch({type: "selectedFigureUuids", value: [newFigureUuid]});
                } else {
                    addSegmentToPathOnMouseDown(event);
                    setIsMouseDown(true);
                }
            } else {
                setIsMouseDown(true);
                const uuid = event.target.id.split("|")[0] || event.target.parentNode.parentNode.id.split("|")[0];
                if (!isFigureSelected(uuid, selectedFigureUuids, figures)) {
                    startRectangleSelection(event.pageX, event.pageY);
                }
            }
        }
    };

    const handleMouseMoveOnCanvas = event => {
        if (!isScrolled && !isWatchMode && tool) {
            if (isMouseDown) {
                if (!isEditMode) {
                    addSegmentToPathOnMouseMove(event);
                } else {
                    setMouseWasMoved(true);
                    changeSelectionRectangle(event.pageX, event.pageY);
                }
            } else {
                setMousePosition({x: event.clientX, y: event.clientY});
            }
        }
    };

    const handleMouseUpOnCanvas = event => {
        if (!isScrolled && !isWatchMode && tool) {
            if (isEditMode) {
                let newSelection = finishRectangleSelection();
                if (isRectangleSelectionActive && uuidArraySchema.isValidSync(newSelection)) {
                    dispatch({type: "selectedFigureUuids", value: newSelection});
                }
            } else if (isMouseDown && !event.ctrlKey) {
                createFigureFromPencil(event, "mouse up");
            }
            setIsMouseDown(false);
        }
    };

    const handleKeyDownOnCanvas = event => {
        if (event.key === "Escape") {
            // removeLastSegment();
        } else if (event.key === "Delete" && isEditMode) {
            if (isItemInArray(figureUuidDescriptionPopupShownFor, selectedFigureUuids)) {
                dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});
            }
            selectedFigureUuids.map(uuid => dispatch({type: "deleteFigure", uuid, time: getTimeElapsedSince(startTime)}));
            dispatch({type: "selectedFigureUuids", value: []});
        } else if (event.key === "Enter" && !isEditMode) {
            createFigureFromPencil(event, "enter");
            dispatch({type: "isEditMode", value: true});
        }
        if (isEditMode) {
            let key = event.key.toLowerCase();
            if (["g", "п"].includes(key) && event.shiftKey && selectedFigureUuids.length > 1) {
                let newGroupChildUuids = [];
                selectedFigureUuids.map(uuid => newGroupChildUuids.indexOf(getRootUuid(figures, uuid)) === -1 &&
                    newGroupChildUuids.push(getRootUuid(figures, uuid))
                );

                let newGroupUuid = uuid();
                const newGroupObject = {
                    uuid: newGroupUuid,
                    parentUuid: undefined,
                    tool: "g-s-72",
                    children: newGroupChildUuids,
                    name: "",
                    description: "",
                    layout: GROUP_LAYOUT,
                    orderIndex: Math.round(new Date().getTime()),
                };
                if (!groupSchema.isValidSync(newGroupObject)) {
                    return;
                }

                dispatch({...newGroupObject, type: "addGroup", time: getTimeElapsedSince(startTime)});
                dispatch({type: "selectedFigureUuids", value: [newGroupUuid]});
            } else if (["u", "г"].includes(key) && event.shiftKey && selectedFigureUuids.length > 0) {
                selectedFigureUuids.map(u => figures.get(u).get("children") &&
                    dispatch({
                        type: "disbandGroup",
                        uuid: u,
                        children: figures.get(u).get("children"),
                        time: getTimeElapsedSince(startTime)
                    })
                );
                dispatch({type: "selectedFigureUuids", value: []});
            } else if (["z", "я"].includes(key) && event.ctrlKey && event.shiftKey) {
                let tempSelectedFiguresUuids = [];
                selectedFigureUuids.map(uuid =>
                    nextFiguresUuids.indexOf(uuid) !== -1 && tempSelectedFiguresUuids.push(uuid)
                );
                dispatch({type: "selectedFigureUuids", value: tempSelectedFiguresUuids});
                dispatch(ActionCreators.jump(1));
            } else if (["z", "я"].includes(key) && event.ctrlKey) {
                let tempSelectedFiguresUuids = [];
                selectedFigureUuids.map(uuid =>
                    previousFiguresUuids.indexOf(uuid) !== -1 && tempSelectedFiguresUuids.push(uuid)
                );
                dispatch({type: "selectedFigureUuids", value: tempSelectedFiguresUuids});
                dispatch(ActionCreators.jump(-1));
            } else if (["c", "с"].includes(key) && event.ctrlKey) {
                dispatch({type: "mouseXAtTheClipboardTime", value: mousePosition.x});
                dispatch({type: "mouseYAtTheClipboardTime", value: mousePosition.y});
                dispatch({type: "clipboard", value: selectedFigureUuids});
            } else if (["v", "м"].includes(key) && event.ctrlKey) {
                clipboard.map(u => renderObjectCopy(
                    figures,
                    u,
                    undefined,
                    dispatch,
                    getTimeElapsedSince(startTime),
                    Math.round(new Date().getTime()),
                    (mousePosition.x - mouseXAtTheClipboardTime) / scale,
                    (mousePosition.y - mouseYAtTheClipboardTime) / scale
                ));
            }
        }
    };

    const handleScrollStart = () => setIsScrolled(true);
    const handleScrollStop = () => setIsScrolled(false);

    return !_.isEmpty(icons) && !_.isEmpty(parameters) ? <div
        className="svg-canvas-container"
        id="svg-canvas-container"
        onKeyDownCapture={handleKeyDownOnCanvas}
        tabIndex="0"
        ref={svgCanvasContainer}
    >
        <Scrollbars
            ref={scrollbars}
            onScrollStart={handleScrollStart}
            onScrollStop={handleScrollStop}
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
            style={{width: "100%"}}
        >
            <svg
                id="svg-canvas"
                className="svg-canvas"
                ref={svgCanvas}
                style={{
                    width: scale * canvasWidth + "px",
                    height: scale * canvasHeight + "px",
                    cursor: !isWatchMode && !isEditMode && tool
                        ? (figureType === "i"
                            ? `url(${iconCursor}) 30 30, auto`
                            : "url(/images/canvas/icon-pencil-cursor.svg) 5 28, auto")
                        : "auto"
                }}
                onDoubleClick={selectSubgroupOnCanvas}
                onContextMenu={handleContextMenuOnCanvas}
                onMouseDown={handleMouseDownOnCanvas}
                onMouseUp={handleMouseUpOnCanvas}
                onMouseMove={handleMouseMoveOnCanvas}
                onClick={handleClickOnCanvas}
            >
                <PolygonsPatterns/>
                <rect width="100%" height="100%" fill={!backgroundImage ? "#FAFEFF" : "none"}/>
                {backgroundImage && <image width="100%" height="100%" href={backgroundImage} preserveAspectRatio="none"/>}
                <FigureContainer
                    handleMouseEnterOnConnector={handleMouseEnterOnConnector}
                    handleKeyDownOnCanvas={handleKeyDownOnCanvas}
                    isMouseDown={isMouseDown}
                />
                {!isEditMode && <SnappingConnector
                    id="closing-snapping-connector"
                    x={startX * scale - 4}
                    y={startY * scale - 4}
                    isMouseDown={isMouseDown}
                    handleMouseEnter={handleMouseEnterOnConnector}
                />}
                <path
                    d={Path.transform(pencilPath, 0, 0, scale, scale)}
                    stroke="red"
                    fillOpacity="0"
                    style={{pointerEvents: "none"}}
                />
                {rectangleSelection}
            </svg>
        </Scrollbars>
    </div> : null;
}
