import {useEffect, useState} from "react";
import {useApiClient} from "../../../../../customHooks/useApiClient";


export default function useAvailableMetrics () {
    const api = useApiClient();

    const [availableMetrics, setAvailableMetrics] = useState([]);

    useEffect(async () => {
        const available_metrics = (await api.get("/api/v1/researches/metrics/")).data;
        setAvailableMetrics(available_metrics);
    }, []);

    return availableMetrics;
}