import {useMemo} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {ValidationError} from "yup";
import {QueryClient} from "react-query";

import {useLocalization} from "./useContextLocalization";


export const useApiClient = () => {
    const locale = useLocalization();
    const processError = (err, restricted) => {
        if (restricted && err.response && err.response.status === 401) {
            toast.warn(locale?.get?.auth[err.response.data.detail]);
        } else if (err.response && err.response.status === 403) {
            toast.warn(locale?.get?.auth[err.response.data.detail]);
        } else if (err.response && err.response.status === 422) {
            const errors = err.response.data.detail.map(d => ({[d.loc[2]]: d.msg}))
            throw new ValidationError(errors);
        } else if (err.response && err.response.status === 408) {
            //timeout from cladr, no need to warn user
            return "error";
        } else if (err.response && err.response.status === 500) {
            toast.error(locale?.get?.auth[err.response.data.detail]);
        } else if (err.toString().includes("Network Error")) {
            toast.error(locale?.get?.auth.networkError);
        }
        throw (err);
    }

    const headers = {};

    const methods = useMemo(() => ({
        queryClient: new QueryClient(),
        get: async (url, {restricted=true, params={}, ...options}={}) => {
            try {
                return await axios.get(url, {headers, params, ...options});
            } catch (err) {
                processError(err, restricted);
            }
        },

        post: async (url, data, {restricted=true, ...options}={}) => {
            try {
                return await axios.post(url.endsWith('/') ? url : url+'/', data, {headers, ...options});
            } catch (err) {
                processError(err, restricted);
            }
        },

        put: async (url, data, {restricted=true, ...options}={}) => {
            try {
                return await axios.put(url, data, {headers, ...options});
            } catch (err) {
                processError(err, restricted);
            }
        },

        delete: async (url, {restricted=true, ...options}={}) => {
            try {
                return await axios.delete(url, {headers, ...options});
            } catch (err) {
                processError(err, restricted);
            }
        } // eslint-disable-next-line
    }), []);

    return methods;
}
