import React from "react";
import {toast} from "react-toastify";

import SocialNetworkIcon from "../../../components/SocialNetworkIcon";
import ShareToSomeoneField from "./ShareToSomeoneField";
import LinkToNewWindow from "../../../components/LinkToNewTab";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useMap} from "../../customHooks/useContextMap";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useModal} from "../../../customHooks/useContextModal";
import hintModeTooltipHOC from "../../onboarding/hintModeTooltipHOC";

import {ReactComponent as VKIcon} from "../../../images/social/icon-vk.svg";
import {ReactComponent as TGIcon} from "../../../images/social/icon-telegram.svg";
import {ReactComponent as CPIcon} from "../../images/controls/icon-copy.svg";


function CPIconTuned(props) {
    return <CPIcon viewBox="8 0 24 24" {...props}/>;
}

const ShareContainer = hintModeTooltipHOC((props) => {
    return (<div className = "share-container">
        {props.children}
    </div>);
}, "mapSharing");


export default function Footer() {
    const locale = useLocalization();
    const {shareMap} = useMap();
    const user = useCurrentUser();
    const modal = useModal();

    const openSignInIfUnauthorized = () => !user && modal.set("signin");
    const getWindowPosition = () => ({x: window.screen.height / 2 - 150, y: window.screen.width / 2 - 300});

    const share = async () => {
        openSignInIfUnauthorized();
        const url = await shareMap();
        if (!user) {
            toast.warn(locale?.get.studio.sharing.authRequired);
        }
        return url;
    }

    const handleShareToTelegram = async () => {
        const {x: popupLeft, y: popupTop} = getWindowPosition();
        let url = await share();
        if (!url) {
            return undefined;
        }
        const text = "";
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=300,left=${popupLeft},top=${popupTop}`;

        window.open(`https://t.me/share/url?url=${url}&text=${text}`, "tg.share", params);
    };

    const handleShareToVK = async () => {
        const {x: popupLeft, y: popupTop} = getWindowPosition();
        let url = await share();
        if (!url) {
            return undefined;
        }
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=300,left=${popupLeft},top=${popupTop}`;

        window.open(`https://vk.com/share.php?url=${url}`, "vk.share", params);
    };

    const handleCopyLink = async () => {
        let url = await share();
        if (!url) {
            return undefined;
        }
        navigator.clipboard.writeText(url).then(
            () => toast.info(locale?.get.studio.sharing.linkCopiedSuccessfully),
            () => toast.warn(locale?.get.studio.sharing.copyLinkFailed)
        );
    };

    return <footer className="footer">
        <div className="footer-sharing-container">
            <ShareContainer>
                <SocialNetworkIcon
                    id="footer-social-telegram"
                    className="footer-icon-wrapper"
                    SvgIcon={TGIcon}
                    iconSize="24"
                    onClick={handleShareToTelegram}
                />
                <SocialNetworkIcon
                    id="footer-social-vk"
                    className="footer-icon-wrapper"
                    SvgIcon={VKIcon}
                    iconSize="24"
                    onClick={handleShareToVK}
                />
                <SocialNetworkIcon
                    id="footer-social-copy"
                    className="footer-icon-wrapper"
                    SvgIcon={CPIconTuned}
                    iconSize="24"
                    onClick={handleCopyLink}
                />
                <ShareToSomeoneField openSignInIfUnauthorized={openSignInIfUnauthorized}/>
            </ShareContainer>
            <p className="footer-studio-name">Creative Maps Studio</p>
            <p className="footer-company-name"> - Human Semantics</p>
        </div>
        <div className="footer-sharing-container">
            <LinkToNewWindow
                link="https://t.me/studiomaps"
                content={<p className="footer-channel-link">{locale?.get.studio.sharing.channelLink}</p>}
            />
            <LinkToNewWindow
                link="https://vk.com/public202161779"
                content={<p className="footer-channel-link">{locale?.get.studio.sharing.publicLink}</p>}
            />
        </div>
    </footer>;
}
