import {Input} from "@semograph/semui";


const ProductPrice = props => {
    const setValue = value => props.setFieldValue(props.fieldName, value);

    return <div className="product-form-input-wrapper">
        <Input
            className="product-form-price-field"
            name={props.fieldName}
            type="number"
            value={props.formValues[props.fieldName]}
            renderIcon={null}
            onChange={setValue}
        />
    </div>;
};

export default ProductPrice;