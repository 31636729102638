import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ActionCreators} from "redux-undo";
import {useHistory} from "react-router-dom";

import TopMenuItem from "./topMenu/TopMenuItem";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCurrentUser, UserToolbarView} from "../../../customHooks/useContextCurrentUser";
import {useModal} from "../../../customHooks/useContextModal";
import {useMap} from "../../customHooks/useContextMap";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useOnboarding} from "../../customHooks/useContextOnboarding";
import {useSurveyScheme} from "../../customHooks/useContextSurveyScheme";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {renderObjectCopy} from '../../pureFunctions/usefulFunctions';
import {menuItems} from "./topMenu/menuItems";
import hintModeTooltipHOC from "../../onboarding/hintModeTooltipHOC";
import getTimeElapsedSince from "../getTimeElapsedSince";


const MapTitle = hintModeTooltipHOC(() => {
    const {isChanged} = useMap();
    const {startTime, mapName} = useReduxData();

    const [editable, setEditable_] = useState(false);
    const [value, setValue_] = useState();

    const dispatch = useDispatch();

    const setEditable = useCallback(() => setEditable_(true), []);
    const save = useCallback(() => {
        if (value !== "") {
            dispatch({type: "setMapName", name: value, time: getTimeElapsedSince(startTime)});
        }
        setEditable_(false);
    }, [value, dispatch]);

    const setValue = useCallback(e => setValue_(e.target.value), [setValue_]);

    useEffect(() => setValue_(mapName), [mapName]);

    return (
        <span className="map-title-wrapper">
            {editable && <input
                className="map-title-input"
                id="map-title-input"
                type="text"
                value={value}
                onChange={setValue}
                onBlur={save}
                autoFocus={true}
                style={{width: value.length + "rem"}}
            />}
            {!editable && <h4 className="map-title-header" onClick={setEditable}>{mapName}{isChanged && "*"}</h4>}
        </span>
    );
}, "mapName", {display: "flex", flexGrow: 1});


export default function TopMenuContainer() {
    const api = useApiClient();
    const locale = useLocalization();
    const history = useHistory();
    const modal = useModal();
    const user = useCurrentUser();
    const onboarding = useOnboarding();
    const map = useMap();
    const surveyScheme = useSurveyScheme();
    const {
        clipboard,
        figures,
        nextFiguresUuids,
        previousFiguresUuids,
        scale,
        selectedFigureUuids,
        startTime
    } = useReduxData();

    const dispatch = useDispatch();

    const isChanged = map.isChanged;

    const handlers = {
        file: {
            newMap: () => (!isChanged || window.confirm(locale.get.studio.confirmations.changesWillLose)) && map.newMap(),
            myMaps: () => modal.set(user ? "my_maps" : "signin"),
            // usersMaps: () => modal.set(user ? "users_maps" : "signin"),
            send: async () => {
                if (user) {
                    dispatch({type: "saveToServer"})
                    map.setSaveMapFlag();
                    
                    try {
                        await api.get("/api/v1/surveys/");
                    } catch (e) {
                        if (e.response.status === 404) {
                            try {
                                let response = await api.get("/api/v1/surveys/scheme/");
                                surveyScheme.set(response.data);
                                modal.set("survey");
                            } catch (err) {
                                if (err.response.status === 404) {
                                    // survey scheme not found
                                }
                            }
                        }
                    }
                } else {
                    modal.set("signin");
                }
            },
            load: () => {
                if (!isChanged || window.confirm(locale.get.studio.confirmations.changesWillLose)) {
                    dispatch({type: "clearMenuState"});
                    dispatch({type: "clearState"});
                    map.loadMapFromFile();
                }
            },
            save: () => {
                dispatch({type: "saveToComputer"});
                map.setSaveMapFlag();
            },
            loadBackground: () => modal.set("background_loading"),
            clearBackground: () => {
                dispatch({type: "backgroundImage", value: ""});
                dispatch({type: "isUserCanChangeCanvasSize", value: true});
            },
            // export: () => { //don't used yet
            //     // const data = document.getElementById("svg-canvas-container").innerHTML;
            //     // let s = new XMLSerializer();
            //     // let serialized = s.serializeToString(data);
            //     // const blob = new Blob([serialized], {type: "application/text;charset=utf-8"});
            //     // const filename = prompt(locale?.get.studio.header.menu.loadMapTitle);
            //     // filename && saveAs(blob, filename + ".txt");
            // },
            // exportTexts: () => {
            //     const emojisToString = emojis => {
            //         let names = emojis.map(em => `${em.emoji.native}`);
            //         return names.join(";");
            //     };
            //
            //     let rawFigures = state.get("main").present.get("figures").toJS();
            //     let readyMadeData = Object.keys(rawFigures).map(uuid => rawFigures[uuid]);
            //
            //     //entry format: name,figureName,figureDescription;figureEmojis,geoObject,tool,creationTime,color,fontColor,volume,opacity,sex,age,mapId
            //     readyMadeData = readyMadeData.map(el => {
            //         let description = `${el.description};${emojisToString(el.emoji || [])}`;
            //         if (description === ";") {
            //             description = ""
            //         }
            //         if (description.length > 0 && description[0] === ";") {
            //             description = description.slice(1);
            //         }
            //         if (description.length > 0 && description[description.length - 1] === ";") {
            //             description = description.slice(0, description.length - 1);
            //         }
            //
            //         return '"' + [
            //             user?.fullname || user?.username || "",
            //             el.name,
            //             description,
            //             "", //geoObject
            //             parameters[el.tool]?.title?.[locale?.get.name] || "",
            //             el.orderIndex - startTime,
            //             el.color,
            //             el.fontColor || "",
            //             // el.fontSize || el.dashLength || "",
            //             el.volume || "",
            //             el.transparency,
            //             user?.gender || "",
            //             getAgeOfUser(user) || "",
            //             mapId,
            //         ].join('","') + '"';
            //     });
            //
            //     const now = new Date();
            //     const gender = user?.gender || locale.get.studio.mapFileName.gender;
            //     const age = getAgeOfUser(user, now) || locale.get.studio.mapFileName.age;
            //     const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
            //         .join(".");
            //     const mapNamePattern = mapFileName.get || `${gender}_${age}_${formattedDate}`;
            //
            //     const filename = prompt(locale?.get.studio.header.menu.loadMapTitle, mapNamePattern);
            //     const blob = new Blob([readyMadeData.join("\r\n")], {type: "data:text/csv;charset=utf-8"});
            //     filename && saveAs(blob, filename + ".csv");
            // },
            // validate: () => dispatch({type: "isValidationAnimationShow", value: true})
        },
        edit: {
            undo: () => {
                let tempSelectedFiguresUuids = [];
                selectedFigureUuids.map(uuid =>
                    previousFiguresUuids.indexOf(uuid) !== -1 && tempSelectedFiguresUuids.push(uuid)
                );
                dispatch({type: "selectedFigureUuids", value: tempSelectedFiguresUuids});
                dispatch(ActionCreators.jump(-1));
            },
            redo: () => {
                let tempSelectedFiguresUuids = [];
                selectedFigureUuids.map(uuid =>
                    nextFiguresUuids.indexOf(uuid) !== -1 && tempSelectedFiguresUuids.push(uuid)
                );
                dispatch({type: "selectedFigureUuids", value: tempSelectedFiguresUuids});
                dispatch(ActionCreators.jump(1));
            },
            copy: () => dispatch({type: "clipboard", value: selectedFigureUuids}),
            paste: () => clipboard.map(u => renderObjectCopy(
                figures,
                u,
                undefined,
                dispatch,
                getTimeElapsedSince(startTime),
                Math.round(new Date().getTime()),
                20 / scale,
                20 / scale
            )),
            delete: () => {
                selectedFigureUuids.map(uuid =>
                    dispatch({type: "deleteFigure", uuid: uuid, time: getTimeElapsedSince(startTime)})
                );
                dispatch({type: "selectedFigureUuids", value: []});
            },
            resize: () => modal.set("resize"),
        },
        help: {
            self: () => window.open(`/docs/${locale.get.abbr.toLowerCase()}/start/1-map-editor/`)
        },
        onboarding: {
            self: () => onboarding.setShouldShowOnboarding(true)
        },
        store: {
            self: () => history.push("/store")
        }
    };

    const signIn = () => modal.set("signin");
    const signUp = () => modal.set("signup");

    if (
        user?.email
        && process.env.REACT_APP_BACKGROUND_ALLOWED_EMAILS?.split(",")?.includes(user?.email)
        && menuItems[0].items.length === 5
    ) {
        menuItems[0].items = menuItems[0].items.concat([
            {
                key: "loadBackground",
                enabled: true
            },
            {
                key: "clearBackground",
                enabled: true
            }
        ]);
    }

    return <header className="top-menu-wrapper">
        <ul className="top-menu-container">
            {menuItems.map(item => <TopMenuItem key={item.key} item={item} handlers={handlers[item.key]}/>)}
        </ul>
        <MapTitle/>
        {user ? <UserToolbarView user={user}/> : <>
            <span className="top-menu-auth-button" onClick={signIn}>{locale?.get.auth.signingin}</span>
            <span className="top-menu-auth-button" onClick={signUp}>{locale?.get.auth.signingup}</span>
        </>}
    </header>;
}
