import React from "react";
import {useHistory, useParams} from "react-router";
import {useQuery} from "react-query";
import {Avatar} from "@semograph/semui";

import ProductCard from "../productCard/ProductCard";
import LoadingPage from "../LoadingPage";
import Page404 from "../Page404";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useModal} from "../../../customHooks/useContextModal";

import "./account.css";


function Account() {
    const {id} = useParams();

    const history = useHistory();
    const api = useApiClient();
    const currentUser = useCurrentUser();
    const locale = useLocalization();
    const modal = useModal();

    const editProfile = () => modal.set("edit_profile");
    const deleteProfile = () => modal.set("delete_profile");
    const addNewProduct = () => history.push("/product/add");
    const editIconArrangement = () => history.push("/account/icons");
    const goToStudio = () => {
        location = "/";
        return undefined;
    };

    const userQuery = useQuery([`store/user/${id}`, currentUser], () => api.get(`/api/v1/user/${id}`));
    const user = userQuery.status === 'success' ? userQuery.data.data : {};

    const purchasedProductsQuery = useQuery(
        [`store/account/${id}/purchased`, currentUser],
        () => api.get(`/api/v1/store/account/${id}/purchased_product_versions`)
    );
    const purchasedProducts = purchasedProductsQuery.status === 'success' ? purchasedProductsQuery.data.data : {};

    const unpublishedProductsQuery = useQuery(
        ["publicationDependent", `store/account/${id}/unpublished`, currentUser],
        () => api.get(`/api/v1/store/account/${id}/unpublished_product_versions`)
    );
    const unpublishedProducts = unpublishedProductsQuery.status === 'success' ? unpublishedProductsQuery.data.data : {};

    const publishedProductsQuery = useQuery(
        ["publicationDependent", `store/account/${id}/published`, currentUser],
        () => api.get(`/api/v1/store/account/${id}/published_product_versions`)
    );
    const publishedProducts = publishedProductsQuery.status === 'success' ? publishedProductsQuery.data.data : {};

    const coauthoredProductsQuery = useQuery(
        [`store/account/${id}/coauthored`, currentUser],
        () => api.get(`/api/v1/store/account/${id}/coauthored_product_versions`)
    );
    const coauthoredProducts = coauthoredProductsQuery.status === 'success' ? coauthoredProductsQuery.data.data : {};

    const favouriteProductsQuery = useQuery(
        ["publicationDependent", `store/account/${id}/wanted`, currentUser],
        () => api.get(`/api/v1/store/account/${id}/wanted_product_versions`)
    );
    const favouriteProducts = favouriteProductsQuery.status === 'success' ? favouriteProductsQuery.data.data : {};

    // console.log(purchasedProductsQuery);
    // console.log(purchasedProducts);

    return locale?.get && ([
        userQuery.status,
        unpublishedProductsQuery.status,
        publishedProductsQuery.status,
        purchasedProductsQuery.status,
        coauthoredProductsQuery.status
    ].includes("loading")
        ? <LoadingPage/>
        : ([userQuery.status].includes("error")
            ? <Page404/>
            : <div className = "account-container">
                <div className = "account-user-info-container">
                    <span className="account-user-name">
                        {(user && user.id !== currentUser?.id && (user.full_name || user.username))
                            || locale.get.store.account.myProfile}
                    </span>
                    <div className = "account-avatar-wrapper">
                        <Avatar
                            imgUrl = {user && (user.custom_avatar || user.social_avatar)}
                            email = {user && user.email}
                            styleName = "full"
                        />
                    </div>
                    {user && user.id === currentUser?.id ? <span className="account-user-name-black">
                        {user && (user.full_name || user.username)}
                    </span> : null}
                    {user && <label>{user.interests}</label>}
                    {user && <label>{user.email}</label>}
                    {user && <label>{user.phone_number}</label>}

                    {user && user.id === currentUser?.id
                        ? <div className = "account-user-restricted-info-container">
                            <span onClick={editProfile}>{locale.get.store.account.editProfile}</span>
                            <span onClick={deleteProfile}>{locale.get.store.account.deleteProfile}</span>
                            <span onClick={addNewProduct}>
                                {publishedProducts.length + unpublishedProducts.length > 0
                                    ? locale.get.store.account.addProduct
                                    : locale.get.store.account.becomeAnAuthor}
                            </span>
                            <span onClick={editIconArrangement}>
                                {locale.get.store.account.editIconArrangement}
                            </span>
                            <span onClick={goToStudio}>{locale.get.store.account.creativeMapsStudio} <span className = "account-studio-arrow">￫</span></span>
                        </div>
                        : null
                    }
                </div>
                <div className = "account-product-container">
                    {favouriteProducts?.length > 0 && <>
                        <span className = "account-product-title">{locale.get.store.account.favouriteProducts}</span>
                        <div className = "cards">
                            {favouriteProducts.map((product, i) => <ProductCard key={i} product = {product}/>)}
                        </div>
                    </>}
                    {publishedProducts?.length > 0 && <>
                        <span className = "account-product-title">{locale.get.store.account.publishedProducts}</span>
                        <div className = "cards">
                            {publishedProducts.map((product, i) => <ProductCard key={i} product = {product}/>)}
                        </div>
                    </>}
                    {unpublishedProducts?.length > 0 && <>
                        <span className = "account-product-title">{locale.get.store.account.unpublishedProducts}</span>
                        <div className='cards'>
                            {unpublishedProducts.map((product, i) => <ProductCard key={i} product = {product}/>)}
                        </div>
                    </>}
                    {coauthoredProducts?.length > 0 && <>
                        <span className = "account-product-title">
                            {locale.get.store.account[user && user.id === currentUser?.id ? "myCoauthoredProducts" : "usersCoauthoredProducts"]}
                        </span>
                        <div className = "cards">
                            {coauthoredProducts.map((product, i) => <ProductCard key={i} product = {product}/>)}
                        </div>
                    </>}
                    {user && user.id === currentUser?.id && purchasedProducts?.length > 0 && <>
                        <span className = "account-product-title">{locale.get.store.account.purchasedProducts}</span>
                        <div className='cards'>
                            {purchasedProducts.map((product, i) => <ProductCard key={i} product = {product}/>)}
                        </div>
                    </>}
                </div>
            </div>
        )
    );
}

export default Account;
