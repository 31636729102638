import {useDataSource} from "../../hooks/useContextDataSource";


export default function ({selectedNodeId, handleServerMapTableShowing}) {
    const {dataSources, dispatch} = useDataSource();

    const setDataSourceOfNode = event => {
        dispatch({
            type: "Add or update data source",
            id: selectedNodeId,
            data: {source: event.target.value}
        });
        handleServerMapTableShowing(event.target.value === "server");
    };

    return <div className="research-source-node-configurator-option-container">
        <div>
            <input
                type="radio"
                id="use-server-data"
                name="data-source"
                value="server"
                checked={dataSources[selectedNodeId]?.source === "server"}
                onChange={setDataSourceOfNode}
            />
            <label htmlFor="use-server-data">Use server data</label>
        </div>
        <div>
            <input
                type="radio"
                id="use-local-data"
                name="data-source"
                value="local"
                checked={dataSources[selectedNodeId]?.source === "local"}
                onChange={setDataSourceOfNode}
            />
            <label htmlFor="use-local-data">Use local data</label>
        </div>
    </div>;
}