import React, {useRef} from "react";
import {useDispatch} from "react-redux";

import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useReduxData} from "../../customHooks/useContextReduxData";


export default function Resize() {
    const locale = useLocalization();
    const {canvasHeight, canvasWidth} = useReduxData();

    const dispatch = useDispatch();

    const windowWidth = useRef(null);
    const windowHeight = useRef(null);

    const handleApplyClick = () => {
        let newWidth = windowWidth.current.value,
            newHeight = windowHeight.current.value;
        if (newHeight === "") newHeight = windowHeight.current.placeholder;
        if (newWidth === "") newWidth = windowWidth.current.placeholder;

        dispatch({type: "canvasWidth", value: newWidth});
        dispatch({type: "canvasHeight", value: newHeight});
        windowWidth.current.value = "";
        windowHeight.current.value = "";
    };

    const handleTextChanged = event => {
        let processedValue = "";
        for (let i = 0; i < event.target.value.length; i++) {
            if (event.target.value[i] >= "0" && event.target.value[i] <= "9") {
                processedValue = processedValue.concat(event.target.value[i]);
            }
        }
        if (processedValue.length > 4) {
            processedValue = "9999";
        }
        event.target.value = processedValue;
    };

    return <div>
        <h4 className="modal-title">{locale?.get.studio.resizeCanvas.title}</h4>
        <div className="modal-resize-row">
            <p className="modal-p" style={{position: "absolute", top: "82px", right: "51px"}}>px</p>
            <p className="modal-p">{locale?.get.studio.resizeCanvas.height}</p>
            <input
                type="text"
                className="modal-resize-input"
                id="resize-window-height"
                ref={windowHeight}
                placeholder={canvasHeight}
                onChange={handleTextChanged}
            />
        </div>
        <div className="modal-resize-row">
            <p className="modal-p" style={{position: "absolute", top: "142px", right: "51px"}}>px</p>
            <p className="modal-p">{locale?.get.studio.resizeCanvas.width}</p>
            <input
                type="text"
                className="modal-resize-input"
                id="resize-window-width"
                ref={windowWidth}
                placeholder={canvasWidth}
                onChange={handleTextChanged}
            />
        </div>
        <input
            type="button"
            className="modal-button modal-button-apply"
            value={locale?.get.studio.resizeCanvas.apply}
            onClick={handleApplyClick}
        />
    </div>;
}
