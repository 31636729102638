import React from "react";
import {useDispatch} from "react-redux";
import {Picker} from "emoji-mart";

import {useLocalization} from "../../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../../customHooks/useContextReduxData";
import {setFixedChildrenValues} from '../../../../pureFunctions/usefulFunctions';
import getTimeElapsedSince from "../../../getTimeElapsedSince";

import "emoji-mart/css/emoji-mart.css";


export default function EmojiPicker() {
    const locale = useLocalization();
    const {getFiguresUpdatedOn, selectedFigureUuids, startTime} = useReduxData();
    const figures = getFiguresUpdatedOn([
        "addPolygon",
        "addPolyline",
        "addPointFeature",
        "addGroup",
        "changeObjectParentUuid",
        "clearState",
        "deleteFigure",
        "disbandGroup"
    ]);

    const dispatch = useDispatch();

    const handleSelect = emoji => selectedFigureUuids.map(uuid => figures.get(uuid).get("tool").split("-")[0] !== "c"
        ? setFixedChildrenValues(
            figures,
            uuid,
            ["emoji"],
            dispatch,
            [[{emoji: emoji}]],
            "addFigureEmoji",
            getTimeElapsedSince(startTime)
        ) : null
    );

    return <div className="context-menu-emoji-picker" id="context-menu-emoji-picker">
        <Picker
            set="google"
            i18n={locale?.get.studio.emojiPicker}
            skin={1}
            emojiSize={22}
            showPreview={false}
            showSkinTones={false}
            onSelect={handleSelect}
        />
    </div>;
}
