import React, {useState, useContext, useEffect} from "react";
import Joyride from "react-joyride";

import {useLocalization} from "../../customHooks/useContextLocalization";
import getOnboardingParams from "../onboarding/onboardingParams";


export const OnboardingContext = React.createContext();


export const OnboardingProvider = ({children}) => {
    const [shouldShowOnboarding, setShouldShowOnboarding] = useState(false);

    return (
        <OnboardingContext.Provider value={{shouldShowOnboarding, setShouldShowOnboarding}}>
            {children}
        </OnboardingContext.Provider>
    );
};


export const useOnboarding = () => useContext(OnboardingContext);


export default function OnboardingHandler () {
    const onboarding = useOnboarding();
    const locale = useLocalization();

    const [onboardingState, setOnboardingState] = useState({
        key: new Date(), // This field makes the tour to re-render when we restart the tour
        run: onboarding.shouldShowOnboarding,
        stepIndex: 0, // Make the component controlled
    });

    useEffect(() => {
        if (onboarding.shouldShowOnboarding !== onboardingState.run) {
            // console.log("useEffect called");
            setOnboardingState({ ...onboardingState, run: onboarding.shouldShowOnboarding});
        }
    }, [onboarding.shouldShowOnboarding]);

    const handleJoyrideCallback = (data, obj) => {
        switch (data.action) {
            case "start":
                onboarding.setShouldShowOnboarding(true);
                break;
            case "reset":
                setOnboardingState({...onboardingState, stepIndex: 0});
                break;
            case "close":
                setOnboardingState({...onboardingState, stepIndex: 0});
                onboarding.setShouldShowOnboarding(false);
                break;
            case "skip":
                onboarding.setShouldShowOnboarding(false);
                break;
            case "stop":
                onboarding.setShouldShowOnboarding(false);
                break;
            case "next":
                if (data.lifecycle === "complete") {
                    setOnboardingState({...onboardingState, stepIndex: data.index + 1});
                }
                break;
            case "prev":
            if (data.lifecycle === "complete") {
                setOnboardingState({...onboardingState, stepIndex: data.index - 1});
            }
                break;
            case "restart":
                setOnboardingState({...onboardingState, stepIndex: 0});
                break;
            default:
                break;
        }

        if (data.status === "finished") {
            onboarding.setShouldShowOnboarding(false);
            setOnboardingState({...onboardingState, stepIndex: 0});
        }


    };

    const currentOnboarding = getOnboardingParams(locale?.get)["base"];

    return (
        <Joyride
            {...onboardingState}
            callback = {handleJoyrideCallback}
            steps = {currentOnboarding?.steps || []}
            continuous = {true}
            locale = {locale?.get.studio.onboarding.joyrideLocale}
            showProgress = {currentOnboarding?.showProgress}
            scrollToFirstStep = {true}
            spotlightPadding = {5}
            showSkipButton = {true}
            disableOverlayClose={true}
            styles = {{
                options: {
                    arrowColor: '#C4DEF5',
                    backgroundColor: '#C4DEF5',
                    overlayColor: 'rgba(9, 32, 51, 0.3)', //подобрал, чтобы было близко к rgba(20,64,102, 0.3) после hard-light
                    primaryColor: '#003F64',
                    textColor: '#144066',
                    zIndex: 1000
                }
            }}
        />
    );
}
