import React from 'react';

import BaseConnector from "./transformationalConnectors/BaseConnector.js";
import DirectiveConnector from "./transformationalConnectors/DirectiveConnector.js";
import ConnectorLine from "./transformationalConnectors/ConnectorLine.js";
import HintConnector from "./HintConnector";
import SnappingConnector from "./snappingConnectors/SnappingConnector";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {getUuidHierarchy} from "../../pureFunctions/usefulFunctions";
import Path from "../../pureFunctions/path";


export default function ConnectorContainer({parentFigure, figures, x, y, isMouseDown, handleMouseEnter}) {
    const {isEditMode, isWatchMode, scale, selectedFigureUuids} = useReduxData();

    const parsed = Path._parse(Path.transform(parentFigure.get("points"), 0, 0, scale, scale));
    const curves = [{command: "M", points: [{x: parsed[0].mCoo.x, y: parsed[0].mCoo.y}]}].concat(parsed[0].curves);

    const isEnclosed = parentFigure.get("tool").split("-")[0] === "p"
        || (parentFigure.get("tool").split("-")[0] === "c" && parentFigure.get("enclosed"));

    let isConnectorVisible = false;
    if (figures) {
        getUuidHierarchy(figures, parentFigure.uuid).map(uuid => {
            if (selectedFigureUuids.indexOf(uuid) !== -1) {
                isConnectorVisible = true;
            }
            return undefined;
        });
    }
    const connectorOpacity = (isConnectorVisible && isEditMode ? 1 : 0);

    return <svg x={x} y={y}>
        {curves.map((curve, i) => {
            let lines = [];
            if (curve.points.length > 1) {
                lines = [<ConnectorLine
                    key={`${i}-cl-1`}
                    x1={curves[i - 1].points[curves[i - 1].points.length - 1].x + 20}
                    y1={curves[i - 1].points[curves[i - 1].points.length - 1].y + 20}
                    x2={curve.points[0].x + 16}
                    y2={curve.points[0].y + 16}
                    opacity={connectorOpacity}
                />];
                if (curve.points.length === 2) {
                    lines.push(<ConnectorLine
                        key={`${i}-cl-2`}
                        x1={curve.points[0].x + 16}
                        y1={curve.points[0].y + 16}
                        x2={curve.points[1].x + 20}
                        y2={curve.points[1].y + 20}
                        opacity={connectorOpacity}
                    />);
                } else if (curve.points.length === 3) {
                    lines.push(<ConnectorLine
                        key={`${i}-cl-2`}
                        x1={curve.points[1].x + 16}
                        y1={curve.points[1].y + 16}
                        x2={curve.points[2].x + 20}
                        y2={curve.points[2].y + 20}
                        opacity={connectorOpacity}
                    />);
                }
            }
            return lines;
        })}
        {curves.map((curve, i) => {
            let snappingConnector = <SnappingConnector
                key={`${i}-sc`}
                id={`${parentFigure.uuid}|${(isEnclosed && i === curves.length - 1) ? 0 : i}|snapping`}
                x={curve.points[curve.points.length - 1].x + 16}
                y={curve.points[curve.points.length - 1].y + 16}
                isMouseDown={isMouseDown}
                handleMouseEnter={handleMouseEnter}
            />;

            let connectors = [
                (!isEditMode && !isWatchMode
                    && <HintConnector
                        key={`${i}-hc`}
                        x={curve.points[curve.points.length - 1].x + 20}
                        y={curve.points[curve.points.length - 1].y + 20}
                    />
                ),
                isEditMode && snappingConnector,
                <BaseConnector
                    key={`${i}-bc`}
                    segmentIndex={(isEnclosed && i === curves.length - 1) ? 0 : i}
                    x={curve.points[curve.points.length - 1].x + 16}
                    y={curve.points[curve.points.length - 1].y + 16}
                    parentUuid={parentFigure.uuid}
                    opacity={connectorOpacity}
                    hintModeKey={i === 0 && "baseTransformationalConnector"}
                />,
                !isEditMode && snappingConnector,
            ];

            if (curve.points.length === 2) {
                connectors.push(<DirectiveConnector
                    key={`${i}-dc-1`}
                    segmentIndex={i}
                    indexWithinSegment={0}
                    x={curve.points[0].x + 16}
                    y={curve.points[0].y + 16}
                    parentUuid={parentFigure.uuid}
                    opacity={connectorOpacity}
                    hintModeKey={i === 0 && "directiveTransformationalConnector"}
                />);
            } else if (curve.points.length === 3) {
                connectors=connectors.concat([
                    <DirectiveConnector
                        key={`${i}-dc-1`}
                        segmentIndex={i}
                        indexWithinSegment={0}
                        x={curve.points[0].x + 16}
                        y={curve.points[0].y + 16}
                        parentUuid={parentFigure.uuid}
                        opacity={connectorOpacity}
                        hintModeKey={i === 0 && "directiveTransformationalConnector"}
                    />,
                    <DirectiveConnector
                        key={`${i}-dc-2`}
                        segmentIndex={i}
                        indexWithinSegment={1}
                        x={curve.points[1].x + 16}
                        y={curve.points[1].y + 16}
                        parentUuid={parentFigure.uuid}
                        opacity={connectorOpacity}
                    />
                ]);
            }
            return connectors;
        })}
    </svg>;
}
