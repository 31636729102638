import * as Yup from "yup";
import * as isUUID from "is-uuid";


export function isNumberOnSegment(leftBorder, rightBorder, step=1) {
    return val => typeof val === "number" && val >= leftBorder && val <= rightBorder && (step === 0 || val % step === 0);
}

export function isNonNaNNumber(val) {
    return typeof val === "number" && !isNaN(val);
}

export function isUUIDorUndefined(val) {
    return isUUID.v4(val) || val === undefined;
}

export const colorRegex = /^((#[a-fA-F0-9]{6})|(#[a-fA-F0-9]{3}))$/;
export const toolRegex = /^[ipcg]-[\ds]+-[\d]+$/;
export const groupToolRegex = /^g-s-72$/;
export const pointsRegex = /^[MmLlCcSsQqTtAaZzHhVve\-\d.,\s]+$/;

export const uuidSchema = Yup.string().test("is uuid v4", "error", isUUID.v4);
export const optionalUuidSchema = Yup.string().test("is uuid v4 or undefined", "error", isUUIDorUndefined);
export const pointsSchema = Yup.string().matches(pointsRegex).required();
export const coordinateSchema = Yup.number().test("is number", "error", isNonNaNNumber);
export const dashLengthSchema = Yup.number().test("is number valid", "error", isNumberOnSegment(0, 15));
export const thicknessSchema = Yup.number().test("is number valid", "error", isNumberOnSegment(1, 5, 0.5));
export const volumeSchema = Yup.number().test("is number valid", "error", isNumberOnSegment(5, 70, 5));
export const toolSchema = Yup.string().matches(toolRegex).required();
export const groupToolSchema = Yup.string().matches(groupToolRegex).required();
export const colorSchema = Yup.string().matches(colorRegex).required();
export const emojiArraySchema = Yup.array().required(); // TODO: I need to record only icon id, not all icon, so until it isn't done, I left trivial checking
export const emojiSchema = Yup.object();
export const transparencySchema = Yup.number().test("is number valid", "error", isNumberOnSegment(0, 1, 0));
export const flagsSchema = Yup.array().of(Yup.mixed().oneOf(["red", "green", "pink", "blue", "black"])).nullable();
export const fontSizeSchema = Yup.number().test("is number valid", "error", isNumberOnSegment(-1, 20, 1)); //-1 is a standard fontSize
export const textSchema = Yup.string().test("is string", "error", val => typeof val === "string"); //check if any string, and not undefined
export const enclosedSchema = Yup.boolean();
export const layoutSchema = Yup.number().test("is number valid", "error", isNumberOnSegment(0, 100, 1));
export const orderIndexSchema = Yup.number().min(0).required();
export const uuidArraySchema = Yup.array().of(uuidSchema).required();
export const childrenSchema = Yup.array().of(uuidSchema).min(2).required();

export const polygonSchema = Yup.object().shape({
    uuid: uuidSchema,
    parentUuid: optionalUuidSchema,
    x: coordinateSchema,
    y: coordinateSchema,
    points: pointsSchema,
    tool: toolSchema,
    color: colorSchema,
    emoji: emojiArraySchema,
    fontColor: colorSchema,
    fontSize: fontSizeSchema,
    transparency: transparencySchema,
    name: textSchema,
    link: textSchema,
    description: textSchema,
    layout: layoutSchema,
    orderIndex: orderIndexSchema
});
export const polylineSchema = Yup.object().shape({
    uuid: uuidSchema,
    parentUuid: optionalUuidSchema,
    x: coordinateSchema,
    y: coordinateSchema,
    points: pointsSchema,
    thickness: thicknessSchema,
    dashLength: dashLengthSchema,
    tool: toolSchema,
    color: colorSchema,
    transparency: transparencySchema,
    name: textSchema,
    link: textSchema,
    description: textSchema,
    enclosed: enclosedSchema,
    layout: layoutSchema,
    orderIndex: orderIndexSchema
});
export const pointFeatureSchema = Yup.object().shape({
    x: coordinateSchema,
    y: coordinateSchema,
    volume: volumeSchema,
    tool: toolSchema,
    uuid: uuidSchema,
    parentUuid: optionalUuidSchema,
    color: colorSchema,
    emoji: emojiArraySchema,
    fontColor: colorSchema,
    fontSize: fontSizeSchema,
    transparency: transparencySchema,
    flags: flagsSchema,
    name: textSchema,
    link: textSchema,
    description: textSchema,
    layout: layoutSchema,
    orderIndex: orderIndexSchema
});
export const groupSchema = Yup.object().shape({
    uuid: uuidSchema,
    parentUuid: optionalUuidSchema,
	tool: groupToolSchema,
	children: childrenSchema,
	name: textSchema,
	description: textSchema,
    layout: layoutSchema,
    orderIndex: orderIndexSchema
});

export const mapSchema = Yup.object().shape({
    menu: Yup.object().shape({
        backgroundImage: Yup.string(),
        canvasHeight: Yup.number().min(0).max(9999),
        canvasWidth: Yup.number().min(0).max(9999),
        clipboard: Yup.array().of(uuidSchema),
        color: colorSchema,
        dashLength: dashLengthSchema,
        figureCenter: Yup.object().shape({
            x: coordinateSchema,
            y: coordinateSchema,
        }),
        figureUuidDescriptionPopupShownFor: optionalUuidSchema,
        fontColor: colorSchema,
        fontSize: fontSizeSchema,
        isContextMenuShow: Yup.mixed().oneOf(["no", "text", "figure", "group"]),
        isEditMode: Yup.boolean(),
        isGridShow: Yup.boolean(),
        isUserCanChangeCanvasSize: Yup.boolean(),
        isValidationAnimationShow: Yup.boolean(),
        isWatchMode: Yup.boolean(),
        mouseXAtTheClipboardTime: coordinateSchema,
        mouseYAtTheClipboardTime: coordinateSchema,
        openContextMenuTool: Yup.mixed()
            .oneOf(["no", "emoji", "color", "fontColor", "fonSize", "transparency", "volume", "thickness"]),
        openedMenuItemNumber: Yup.mixed().test("menuItemNumber", "invalid", val => +val >= -1 && +val <= 16),
        prevScale: Yup.number().min(0).max(5.5),
        scale: Yup.number().min(0).max(5.5),
        selectedFigureUuids: Yup.array().of(uuidSchema),
        startTime: Yup.number().min(0),
        thickness: thicknessSchema,
        tool: toolSchema,
        transparency: transparencySchema,
        volume: volumeSchema.test("volume", "invalid", val => val % 5 === 0),
    }),
    main: Yup.object().shape({
        figures: Yup.object().test("figures", "invalid", val => {
            const figures = Object.keys(val).map(uuid => val[uuid]);
            figures.map(figure => {
                if (!polygonSchema.isValid(figure)
                    && !polylineSchema.isValid(figure)
                    && !pointFeatureSchema.isValid(figure)
                    && !groupSchema.isValid(figure)
                ) {
                    return false;
                }
            })
            return true;
        }),
        watch: Yup.object(),
        mapName: textSchema,
        mapId: uuidSchema,
        version: Yup.string()
    }),
    actionHistory: Yup.array().of(Yup.object().shape({
        type: Yup.mixed().oneOf([
            "addPolygon", //uuid parentUuid points x y tool color emoji fontColor fontSize transparency name description layout orderIndex
            "addPolyline", //uuid parentUuid points dashLength x y thickness tool color transparency name description enclosed layout orderIndex
            "addPointFeature", //uuid parentUuid x y volume tool color emoji flags fontColor fontSize transparency name description layout orderIndex
            "addGroup", //uuid parentUuid tool children name description layout orderIndex
            "changeObjectName", // uuid name
            "changeObjectLink", // uuid link
            "changeObjectDescription", // uuid description
            "changeObjectParentUuid", // uuid parentUuid
            "changeFigureColor", // uuid color
            "changeFigurePoints", // uuid points
            "changeFigureDashLength", // uuid dashLength
            "addFigureEmoji", // uuid emoji
            "deleteFigureEmoji", // uuid emoji
            "addFigureFlag", // uuid flags
            "deleteFigureFlag", // uuid flags
            "changeFigureFontColor", // uuid fontColor
            "changeFigureFontSize", // uuid fontSize
            "changeFigureTransparency", // uuid transparency
            "changeFigureLayout", // uuid layout
            "changeFigureVolume", // uuid volume
            "changeFigureThickness", // uuid thickness
            "deleteFigure", // uuid
            "disbandGroup", // uuid children
            "moveFigure", // uuid x y
            "createMap", //no any additions
            "saveToServer", //no any additions
            "saveToComputer", //no any additions
            "setMapName" // uuid name
        ]),
        uuid: Yup.mixed().when("type", {
            is: val => !["createMap", "saveToServer", "saveToComputer"].includes(val),
            then: uuidSchema
        }),
        parentUuid: Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "addGroup", "changeObjectParentUuid"].includes(val),
            then: optionalUuidSchema
        }),
        points: Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "changeFigurePoints"].includes(val),
            then: pointsSchema
        }),
        x:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "moveFigure"].includes(val),
            then: coordinateSchema
        }),
        y:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "moveFigure"].includes(val),
            then: coordinateSchema
        }),
        dashLength:  Yup.mixed().when("type", {
            is: val => ["addPolyline", "changeFigureDashLength"].includes(val),
            then: dashLengthSchema
        }),
        thickness:  Yup.mixed().when("type", {
            is: val => ["addPolyline", "changeFigureThickness"].includes(val),
            then: thicknessSchema
        }),
        volume:  Yup.mixed().when("type", {
            is: val => ["addPointFeature", "changeFigureVolume"].includes(val),
            then: volumeSchema
        }),
        tool:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "addGroup"].includes(val),
            then: toolSchema
        }),
        color:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "changeFigureColor"].includes(val),
            then: colorSchema
        }),
        emoji:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPointFeature", "addFigureEmoji"].includes(val),
            then: emojiArraySchema,
            otherwise: Yup.mixed().when("type", {is: "deleteFigureEmoji", then: emojiSchema})
        }),
        transparency:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "changeFigureTransparency"].includes(val),
            then: transparencySchema
        }),
        flags:  Yup.mixed().when("type", {
            is: val => ["addPointFeature", "addFigureFlag", "deleteFigureFlag"].includes(val),
            then: flagsSchema
        }),
        fontColor:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPointFeature", "changeFigureFontColor"].includes(val),
            then: colorSchema
        }),
        fontSize:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPointFeature", "changeFigureFontSize"].includes(val),
            then: fontSizeSchema
        }),
        name:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "addGroup", "changeObjectName", "setMapName"].includes(val),
            then: textSchema
        }),
        link:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "addGroup", "changeObjectLink"].includes(val),
            then: textSchema
        }),
        description:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "addGroup", "changeObjectDescription"].includes(val),
            then: textSchema
        }),
        enclosed:  Yup.mixed().when("type", {
            is: val => ["addPolyline"].includes(val),
            then: enclosedSchema
        }),
        layout:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "addGroup"].includes(val),
            then: layoutSchema
        }),
        orderIndex:  Yup.mixed().when("type", {
            is: val => ["addPolygon", "addPolyline", "addPointFeature", "addGroup"].includes(val),
            then: orderIndexSchema
        }),
        children:  Yup.mixed().when("type", {
            is: val => ["addGroup", "disbandGroup"].includes(val),
            then: childrenSchema
        }),
    }))
});
