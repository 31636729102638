import {useCallback, useEffect, useState} from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import useTabs from "../hooks/useTabs";


export default function NameField({defaultValue, tIndex, pIndex, sIndex}) {
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const {tabs, dispatchTabs} = useTabs();
    const locale = useLocalization();

    useEffect(() => { //on DnD
        setCurrentValue(defaultValue);
    }, [defaultValue]);

    const onBlur = useCallback(() => {
        if ((pIndex === undefined && sIndex === undefined && tabs[tIndex]?.name[locale.get.name] !== currentValue)
            || (sIndex === undefined && tabs[tIndex].children[pIndex]?.name[locale.get.name] !== currentValue)
            || (tabs[tIndex].children[pIndex].children[sIndex]?.name[locale.get.name] !== currentValue)
        ) {
            let name;
            if (pIndex === undefined && sIndex === undefined) {
                name = tabs[tIndex].name;
            } else if (sIndex === undefined) {
                name = tabs[tIndex].children[pIndex].name;
            } else {
                name = tabs[tIndex].children[pIndex].children[sIndex].name;
            }

            name[locale.get.name] = currentValue;
            let action = {payload: {name: name, tIndex: tIndex, locale}};
            if (pIndex === undefined && sIndex === undefined) {
                action.type = "setTabName";
            } else if (sIndex === undefined) {
                action.type = "setPartitionName";
                action.payload.pIndex = pIndex;
            } else {
                action.type = "setSubpartitionName";
                action.payload.sIndex = sIndex;
                action.payload.pIndex = pIndex;
            }
            dispatchTabs(action);
        }
    }, [tabs, dispatchTabs, currentValue, tIndex, pIndex, sIndex]);

    const onChange = useCallback(event => setCurrentValue(event.target.value), [setCurrentValue]);

    return <input type="text" className="account-icons-name-field" value={currentValue} onBlur={onBlur} onChange={onChange}/>;
};