import React, {useState, useContext} from "react";


export const CanvasBoundingClientRectContext = React.createContext();


export const CanvasBoundingClientRectProvider = ({children}) => {
    const [get, set] = useState({left: NaN,  top: NaN});

    return (
        <CanvasBoundingClientRectContext.Provider value={{get, set}}>
            {children}
        </CanvasBoundingClientRectContext.Provider>
    );
};


export const useCanvasBoundingClientRect = () => useContext(CanvasBoundingClientRectContext);
