import React, {useState} from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";
import {languages} from "../../../../localization/languages";


function LanguageSelector () {
    const locale = useLocalization();

    const [shouldSelectorOpen, setShouldSelectorOpen] = useState(false);

    const openSelector = () => setShouldSelectorOpen(true);
    const closeSelector = () => setShouldSelectorOpen(false);
    const changeLang = event => locale?.set(languages[event.target.id.split("-")[2]]);

    return <div
        className="lang-selector-container"
        onMouseEnter={openSelector}
        onMouseLeave={closeSelector}
    >
        {shouldSelectorOpen && <div className="lang-selector-active-wrapper">{locale?.get.abbr} <span>v</span></div>}
        <div className="lang-current">{locale?.get.abbr} <span>v</span></div>
        {shouldSelectorOpen && <ul className="lang-selector-item-container">
            {Object.keys(languages).map(langName => <li
                key={`lang-item-${langName}`}
                id={`lang-item-${langName}`}
                className="lang-selector-item"
                onClick={changeLang}
            >{languages[langName].abbr}</li>)}
        </ul>}
    </div>;
}

export default hintModeTooltipHOC(LanguageSelector, "currentLocale");
