import React, {useContext} from "react";


export const CategoriesContext = React.createContext();

export const CategoriesProvider = ({children}) => {
    const categories = [
        {id: 1, key: "all"},
        {id: 2, key: "icons"},
        {id: 3, key: "backgrounds"},
        {id: 4, key: "polygons"},
        {id: 5, key: "templates"},
        {id: 6, key: "tools"},
    ];


    return (
        <CategoriesContext.Provider value = {categories}>
            {children}
        </CategoriesContext.Provider>
    );
};


export const useCategories = () => {
    const categories = useContext(CategoriesContext);

    return categories;
}
