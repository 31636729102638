import {Icon, RadioGroup} from "@semograph/semui";

import {useLocalization} from "../../../../customHooks/useContextLocalization";

import {ReactComponent as RadioCheckedIcon} from "../../../images/radio_checked.svg";
import {ReactComponent as RadioIcon} from "../../../images/radio.svg";


const ProductPublicity = props => {
    const locale = useLocalization();

    const renderRadioIcon = checked => <Icon icon={checked ? RadioCheckedIcon : RadioIcon} viewBox="0 0 16 16"/>;
    const setValue = option => props.setFieldValue("publicity", option);

    return <RadioGroup
        groupName="publicity"
        defaultValue={props.defaultValue}
        renderRadioIcon={renderRadioIcon}
        onChange={setValue}
    >
        <span className="product-form-radio-item">{locale?.get?.store.product.form.anybody}</span>
        <span className="product-form-radio-item">{locale?.get?.store.product.form.restricted}</span>
    </RadioGroup>;
}

export default ProductPublicity;