import React from "react";
import {useHistory} from "react-router-dom";

import {useLocalization} from "../../customHooks/useContextLocalization";
import {useCurrentUser} from "../../customHooks/useContextCurrentUser";

import {ReactComponent as Tick} from "../images/tick.svg";

import "./servicePage.css";


export default function Verified() {
    const history = useHistory();
    const locale = useLocalization();
    const currentUser = useCurrentUser();

    const goToStudio = () => location.pathname = "/";
    const goToStore = () => history.push(`/`);

    return (
        <div className = "page-404-container">
            <Tick/>
            <h1 className = "tick-title">{locale?.get?.store.verified.title}</h1>
            <span className = "tick-link" onClick={goToStudio}>{locale?.get?.store.verified.goToStudio}</span>
            <span className = "tick-link" onClick={goToStore}>{locale?.get?.store.verified.goToStore}</span>
        </div>
    );
}
