import React, {useState, useEffect} from "react";

import Tool from "./Tool";
import tooltipHOC from "../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import useMeasure from "../../../../customHooks/useMeasure";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {useReduxData} from "../../../customHooks/useContextReduxData";

import {ReactComponent as SubpartitionArrow} from "../../../images/controls/subpartition-arrow.svg";


const SubpartitionHint = hintModeTooltipHOC(() => <div style={{height: "0"}}/>);


const SubpartitionPreview = tooltipHOC(({icon, hintModeKey, tooltipContent}) => {
    return (<>
        <div
            className="toolbar-subpartition"
            style={{backgroundImage: `url(${icon})`, backgroundSize: "contain"}}
        />
        <SubpartitionHint hintModeKey={hintModeKey} tooltipContent={tooltipContent}/>
        <SubpartitionArrow className="toolbar-subpartition-arrow"/>
    </>);
});


export default function Subpartition({
    subpartition,
    subpartitionIndex,
    isOpened,
    setOpenedSubpartitionIndex,
    positionIndex,
    partitionPosition,
    hintModeKey,
    tooltipContent
}) {
    const [xPosition, setXPosition] = useState(0);
    const [yPosition, setYPosition] = useState(0);

    const {tool: currentTool, isWatchMode, isEditMode} = useReduxData();
    const locale = useLocalization();
    const {get: {parameters, icons}} = useIconSet();
    const [subpartitionRef, {left: subpartitionLeft, top: subpartitionTop, height: subpartitionHeight}] = useMeasure();

    useEffect(() => {
        if (subpartitionLeft && subpartitionTop && subpartitionHeight && partitionPosition) {
            setXPosition(+subpartitionLeft - +partitionPosition.left - 5);
            setYPosition(+subpartitionTop - +partitionPosition.top + subpartitionHeight + 5);
        }
    }, [subpartitionRef.current, subpartitionTop, subpartitionLeft, subpartitionHeight, partitionPosition]);

    const handleSubpartitionClick = () => {
        setOpenedSubpartitionIndex(isOpened ? -1 : subpartitionIndex);
        let focus = subpartitionRef?.current?.focus();
    };

    const handleBlur = (event) => {
        if (!event.relatedTarget
            || !event.relatedTarget.className
            || (
                event.relatedTarget.className !== "toolbar-subpartition-container"
                && event.relatedTarget.className !== "toolbar-subpartition-title"
            )
        ) {
            setOpenedSubpartitionIndex(-1);
        } else {
            event.target.focus();
        }
    }

    const [iconType, productId, iconIndex] = subpartition.icon.split("-");
    const subpartitionImage = icons[iconType === "p" ? "polygons" : iconType === "c" ? "polylines" : "icons"][productId]
        .icons[iconIndex];

    return <>
        {isOpened ? <>
            <div
                className={`toolbar-subpartition-container${isWatchMode ? " disabled" : ""}`}
                tabIndex="2"
                style={{marginLeft: xPosition + 3, marginTop: yPosition - 5}}
            >
                <p className="toolbar-subpartition-title" tabIndex="1">{subpartition.name[locale?.get.name]}</p>
                {subpartition.children.map((tool, i) =>
                    <Tool
                        key={`toolbar-subpartition-${subpartitionIndex}-tool-${i}`}
                        tool={tool}
                        type="-subpartition"
                        setOpenedSubpartitionIndex={setOpenedSubpartitionIndex}
                        tooltipContent={parameters[tool]?.title?.[locale?.get.name] || ""}
                    />
                )}
            </div>
            <div className="toolbar-subpartition-image" style={{marginLeft: (positionIndex * 3.375) + "rem"}}>
                <div
                    className={`toolbar-subpartition-active${isWatchMode ? " disabled" : ""}`}
                    style={{backgroundImage: `url(${subpartitionImage})`, backgroundSize: "contain"}}
                />
                <SubpartitionArrow className="toolbar-subpartition-arrow"/>
            </div>
        </> : undefined}
        <div
            className={`toolbar-subpartition-preview-container${isWatchMode ? " disabled" : ""}`}
            ref={subpartitionRef}
            tabIndex="0"
            onClick={handleSubpartitionClick}
            onBlur={handleBlur}
            style={{backgroundColor: subpartition.children.includes(currentTool) && !isEditMode ? "#FF983D" : ""}}
        >
            <SubpartitionPreview icon={subpartitionImage} hintModeKey={hintModeKey} tooltipContent={tooltipContent}/>
        </div>
    </>;
}
