import React from "react";
import {useDispatch} from "react-redux";
import {ActionCreators} from "redux-undo";
import {toast} from "react-toastify";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {useApiClient} from "../../../../customHooks/useApiClient";
import {useMap} from "../../../customHooks/useContextMap";
import {useCurrentUser} from "../../../../customHooks/useContextCurrentUser";
import {useSurveyScheme} from "../../../customHooks/useContextSurveyScheme";
import {useModal} from "../../../../customHooks/useContextModal";
import {useMapFileName} from "../../../customHooks/useContextMapFileName";
import {useLocalization} from "../../../../customHooks/useContextLocalization";

import tooltipHOC from "../../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";
import {renderObjectCopy, getAgeOfUser} from "../../../pureFunctions/usefulFunctions";
import getTimeElapsedSince from "../../getTimeElapsedSince";
import {takePngSnapshotOfTheCanvas} from "../../../customHooks/useContextMap/pureFunctions";

import {ReactComponent as Undo} from "../../../images/controls/icon-undo.svg";
import {ReactComponent as Redo} from "../../../images/controls/icon-redo.svg";
import {ReactComponent as Copy} from "../../../images/controls/icon-copy.svg";
import {ReactComponent as Paste} from "../../../images/controls/icon-paste.svg";
import {ReactComponent as Save} from "../../../images/controls/icon-save.svg";
import {ReactComponent as Table} from "../../../images/controls/icon-table.svg";
import {ReactComponent as Print} from "../../../images/controls/icon-print.svg";
import {ReactComponent as Roller} from "../../../images/controls/icon-roller.svg";


const IconUndo = tooltipHOC(
    hintModeTooltipHOC(Undo, "quickLaunchBarUndo", {marginBottom: "-2px", marginTop: "-2px"}),
    undefined,
    {display: "flex"},
    {direction: "right", padding: "10px"}
)
const IconRedo = tooltipHOC(
    hintModeTooltipHOC(Redo, "quickLaunchBarRedo", {marginBottom: "-2px", marginTop: "-2px"}),
    undefined,
    {display: "flex"},
    {direction: "right", padding: "10px"}
);
const IconCopy = tooltipHOC(
    hintModeTooltipHOC(Copy, "quickLaunchBarCopy", {marginBottom: "-2px", marginTop: "-2px"}),
    undefined,
    {display: "flex"},
    {direction: "right", padding: "10px"}
);
const IconPaste = tooltipHOC(
    hintModeTooltipHOC(Paste, "quickLaunchBarPaste", {marginBottom: "-2px", marginTop: "-2px"}),
    undefined,
    {display: "flex"},
    {direction: "right", padding: "10px"}
);
const IconSave = tooltipHOC(
    hintModeTooltipHOC(Save, "quickLaunchBarSave", {marginBottom: "-2px", marginTop: "-2px"}),
    undefined,
    {display: "flex"},
    {direction: "right", padding: "10px"}
);
const IconTable = tooltipHOC(
    hintModeTooltipHOC(Table, "quickLaunchBarTable", {marginBottom: "-2px", marginTop: "-2px"}),
    undefined,
    {display: "flex"},
    {direction: "right", padding: "10px"}
);
const IconPrint = tooltipHOC(
    hintModeTooltipHOC(Print, "quickLaunchBarPrint", {marginBottom: "-2px", marginTop: "-2px"}),
    undefined,
    {display: "flex"},
    {direction: "right", padding: "10px"}
);
const IconRoller = tooltipHOC(
    hintModeTooltipHOC(Roller, "quickLaunchBarRoller", {marginBottom: "-2px", marginTop: "-2px"}),
    undefined,
    {display: "flex"},
    {direction: "right", padding: "10px"}
);


export default function QuickLaunchBar () {
    const dispatch = useDispatch();
    const api = useApiClient();
    const map = useMap();
    const user = useCurrentUser();
    const surveyScheme = useSurveyScheme();
    const modal = useModal();
    const mapFileName = useMapFileName();
    const locale = useLocalization();
    const {
        clipboard,
        isThereFuture,
        isTherePast,
        isWatchMode,
        figures,
        nextFiguresUuids,
        previousFiguresUuids,
        scale,
        selectedFigureUuids,
        startTime
    } = useReduxData();

    const undo = () => {
        let tempSelectedFiguresUuids = [];
        selectedFigureUuids.map(uuid =>
            previousFiguresUuids.indexOf(uuid) !== -1 && tempSelectedFiguresUuids.push(uuid)
        );
        dispatch({type: "selectedFigureUuids", value: tempSelectedFiguresUuids});
        dispatch(ActionCreators.jump(-1));
    };

    const redo = () => {
        let tempSelectedFiguresUuids = [];
        selectedFigureUuids.map(uuid =>
            nextFiguresUuids.indexOf(uuid) !== -1 && tempSelectedFiguresUuids.push(uuid)
        );
        dispatch({type: "selectedFigureUuids", value: tempSelectedFiguresUuids});
        dispatch(ActionCreators.jump(1));
    };

    const copy = () => dispatch({type: "clipboard", value: selectedFigureUuids});

    const paste = () => clipboard.map(u => renderObjectCopy(
        figures,
        u,
        undefined,
        dispatch,
        getTimeElapsedSince(startTime),
        Math.round(new Date().getTime()),
        20 / scale,
        20 / scale
    ));

    const saveToServer = async () => {
        if (user) {
            dispatch({type: "saveToServer"})
            map.setSaveMapFlag();

            try {
                await api.get("/api/v1/surveys/");
            } catch (e) {
                if (e.response.status === 404) {
                    try {
                        let response = await api.get("/api/v1/surveys/scheme/");
                        surveyScheme.set(response.data);
                        modal.set("survey");
                    } catch (err) {
                        if (err.response.status === 404) {
                            // survey scheme not found
                        }
                    }
                }
            }
        } else {
            modal.set("signin");
        }
    };

    const openMyMaps = () => modal.set(user ? "my_maps" : "signin");

    const saveAsPNG = async () => {
        let png;
        try {
            png = await takePngSnapshotOfTheCanvas();
        } catch (e) {
            toast.warn(e);
            return undefined;
        }

        const now = new Date();
        const gender = user?.gender || locale.get.studio.mapFileName.gender;
        const age = getAgeOfUser(user, now) || locale.get.studio.mapFileName.age;
        const formattedDate = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()]
            .join(".");
        const mapNamePattern = mapFileName.get || `${gender}_${age}_${formattedDate}`;

        const filename = prompt(locale?.get.studio.header.menu.loadMapTitle, mapNamePattern);
        filename && saveAs(png, filename + ".png");
    };

    const copyFigureParameters = () => {
        const figure = figures.get(selectedFigureUuids[0]).toJS();
        dispatch({type: "color", value: figure["color"]});
        dispatch({type: "transparency", value: figure["transparency"]});
        if (figure["fontColor"]) {
            dispatch({type: "fontColor", value: figure["fontColor"]});
            dispatch({type: "fontSize", value: figure["fontSize"]});
        }
        if (figure["thickness"]) {
            dispatch({type: "thickness", value: figure["thickness"]});
            dispatch({type: "dashLength", value: figure["dashLength"]});
        }
        if (figure["volume"]) {
            dispatch({type: "volume", value: figure["volume"]});
        }
    };

    return <div className="quick-launch-bar">
        <IconUndo
            className={`quick-launch-bar-item${!isWatchMode && isTherePast ? "" : " disabled"}`}
            onClick={undo}
            tooltipContent={locale.get.studio.header.quickLaunchBar.undo}
        />
        <IconRedo
            className={`quick-launch-bar-item${!isWatchMode && isThereFuture ? "" : " disabled"}`}
            onClick={redo}
            tooltipContent={locale.get.studio.header.quickLaunchBar.redo}
        />
        <IconCopy
            className={`quick-launch-bar-item${!isWatchMode && selectedFigureUuids.length !== 0 ? "" : " disabled"}`}
            onClick={copy}
            tooltipContent={locale.get.studio.header.quickLaunchBar.copy}
        />
        <IconPaste
            className={`quick-launch-bar-item${!isWatchMode && clipboard.length !== 0 ? "" : " disabled"}`}
            onClick={paste}
            tooltipContent={locale.get.studio.header.quickLaunchBar.paste}
        />
        <IconSave
            className={`quick-launch-bar-item`}
            onClick={saveToServer}
            tooltipContent={locale.get.studio.header.quickLaunchBar.save}
        />
        <IconTable
            className={`quick-launch-bar-item`}
            onClick={openMyMaps}
            tooltipContent={locale.get.studio.header.quickLaunchBar.load}
        />
        <IconPrint
            className={`quick-launch-bar-item`}
            onClick={saveAsPNG}
            tooltipContent={locale.get.studio.header.quickLaunchBar.print}
        />
        <IconRoller
            className={`quick-launch-bar-item${!isWatchMode && selectedFigureUuids.length === 1 ? "" : " disabled"}`}
            onClick={copyFigureParameters}
            tooltipContent={locale.get.studio.header.quickLaunchBar.copyParams}
        />
    </div>
}
