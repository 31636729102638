import React, {useState, useLayoutEffect, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {useBeforeunload} from "react-beforeunload";
import {ActionCreators} from "redux-undo";
import queryString from "query-string";

import TopMenuContainer from "./components/header/TopMenuContainer";
import Toolbar from "./components/header/Toolbar";
import Canvas from "./components/canvas/Canvas";
import ToolMenu from "./components/toolMenu/ToolMenu";
import Description from "./components/canvas/popups/Description";
import ContextMenu from "./components/canvas/popups/contextMenu/ContextMenu";
import Footer from "./components/footer/Footer";
import AgreementShowingHandler from "./AgreementShowingHandler";
import AutosendToServerHandler from "./AutosendToServerHandler";
import {useModal} from "../customHooks/useContextModal";
import {useMap} from "./customHooks/useContextMap";
import {useHintMode} from "./customHooks/useContextHintMode";
import {useSnappingMode} from "./customHooks/useContextSnappingMode";
import {useSurveyScheme} from "./customHooks/useContextSurveyScheme";
import {useReduxData} from "./customHooks/useContextReduxData";
import {isMapChanged} from "./customHooks/useContextMap/pureFunctions";
import OnboardingHandler from "./customHooks/useContextOnboarding";
import HintModeOverlay from "./onboarding/HintModeOverlay";

import "react-toastify/dist/ReactToastify.css";


export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function Studio() {
    const hintMode = useHintMode();
    const snappingMode = useSnappingMode();
    const map = useMap();
    const modal = useModal();
    const location = useLocation();
    const surveyScheme = useSurveyScheme();
    const {presentState} = useReduxData();
    const dispatch = useDispatch();

    useBeforeunload(event => isMapChanged(presentState) && event.preventDefault());

    useEffect(() => { //blocking display the survey when it's called not when sending map
        if (location.pathname.startsWith("/survey") && !surveyScheme?.get) {
            modal.set("");
        }
    }, [location?.pathname, surveyScheme?.get]);

    useEffect(() => { //loading map via direct link
        const preloadMap = async () => {
            if (location.pathname === "/share" && map) {
                localStorage.clear();
                const {share_token} = queryString.parse(location.search);
                // console.log(share_token);
                dispatch({type: "clearMenuState"});
                dispatch({type: "clearState"});
                dispatch(ActionCreators.clearHistory());
                dispatch({type: "loadFromLink"});
                await map.loadSharedMap(share_token);
                modal.set("");
            }
        }

        preloadMap();
    }, [location?.pathname]);

    useEffect(() => {
        if (queryString.parse(location.search)["token"]) {
            modal.set("reset_password");
        }
    }, []);

    const switchModesOff = () => {
        if (hintMode.get) {
            hintMode.set(false);
        }
        if (snappingMode.get) {
            snappingMode.set(false);
        }
    };
    const switchModesOnByKey = event => {
        if (event.shiftKey && document.activeElement.id !== "map-title-input") {
            snappingMode.set(true);
            if (
                ["h", "р"].includes(event.key.toLowerCase())
                && (document.activeElement.type !== "text"
                    && document.activeElement.tagName !== "INPUT")
            ) {
                hintMode.set(true);
            }
        }
    };
    const switchModesOffByKey = event => {
        if (event.key === "Shift") {
            snappingMode.set(false);
            hintMode.set(false);
        }
    };

    const handleMouseDown = (event) => {
        let isTargetToolMenuItemBodyChild = false;
        let currentNode = event.target;
        while (currentNode && currentNode.className !== "app") {
            if (currentNode.className === "tool-menu-item-body") {
                isTargetToolMenuItemBodyChild = true;
                break;
            }
            currentNode = currentNode.parentNode;
        }

        let targetClass = event.target.className;
        if (!isTargetToolMenuItemBodyChild && (typeof(targetClass) !== "string"
            || (targetClass !== "tool-menu-item" && targetClass.split(" ")[0] !== "tool-menu-item-wrapper")
        )) {
            dispatch({type: "openedMenuItemNumber", value: "-1"});
        }
    }

    return <>
        <div
            className="app"
            id="app"
            onMouseDown={handleMouseDown}
            onKeyDownCapture={switchModesOnByKey}
            onKeyUpCapture={switchModesOffByKey}
            onMouseLeave={switchModesOff}
            onBlur={switchModesOff}
        >
            {modal?.getModal()}

            <div>
                <Description/>
                <ContextMenu/>
            </div>

            <div className="main-container">
                <TopMenuContainer/>
                <Toolbar/>
                <Canvas/>
                <Footer/>
            </div>

            <ToolMenu/>
            <HintModeOverlay/>
            <OnboardingHandler/>
            <AgreementShowingHandler/>
            <AutosendToServerHandler/>
        </div>
    </>;
}
