import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import SvgEmojis from "./SvgEmojis";
import SvgText from "./SvgText";
import SvgFlags from "./SvgFlags";
import Link from "./Link";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useCanvasBoundingClientRect} from "../../customHooks/useContextCanvasBoundingClientRect";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import {useReduxData} from "../../customHooks/useContextReduxData";
import {parseIconNode} from "../../pureFunctions/iconNode";


export default function PointFeature({figure, kind, isMouseDown}) {
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const locale = useLocalization();
    const {get: {parameters, iconNodes}} = useIconSet();
    const {checkIfPointFeatureSelected, figureCenter, isContextMenuShow, isValidationAnimationShow, scale} = useReduxData();
    const isPointFeatureSelected = checkIfPointFeatureSelected(figure);

    const dispatch = useDispatch();

    const [viewBox, setViewBox] = useState("0 0 60 60");
    const [fillRule, setFillRule] = useState("nonzero");
    const [clipRule, setClipRule] = useState("nonzero");
    const [figureNode, setFigureNode] = useState(<g/>);

    useEffect(() => {
        if (figure.get("tool").split("-")[0] === "i") {
            if (iconNodes[figure.get("tool")]) {
                let iconNode = iconNodes[figure.get("tool")];
                const {figure: figureNode_, parameters} = parseIconNode(iconNode);
                const viewBox = parameters.viewBox;
                if (parameters.fillRule) {
                    setFillRule(parameters.fillRule);
                }
                if (parameters.clipRule) {
                    setClipRule(parameters.clipRule);
                }
                setViewBox(`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`);
                setFigureNode(figureNode_);
            } else {
                setFigureNode(<g className="figure">
                    <path d="M 10,20 L 50,20 L 50,60 L 10,60 L 10,20"/>
                </g>);
            }
        }
    }, [iconNodes]);

    useEffect(() => {
        function func() {
            if (isValidationAnimationShow) {
                dispatch({type: "isValidationAnimationShow", value: false});
            }
        }
        setTimeout(func, 1000);
    }, [isValidationAnimationShow]);


    const handleContextMenuOnPointFeature = () => {
        if (canvasBoundingClientRect && isContextMenuShow === "no") {
            let x = (figure.get("x") + 2 * figure.get("volume")) * scale
                + canvasBoundingClientRect.get.left;
            let y = (figure.get("y") + 1.5 * figure.get("volume")) * scale
                + canvasBoundingClientRect.get.top;
            if (x !== figureCenter.x || y !== figureCenter.y) {
                dispatch({type: "figureCenter", value: {x: x, y: y}});
            }
        }
    };

    //show and hide description window
    const handleMouseLeaveOnPointFeature = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    const handleMouseEnterOnPointFeature = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});

    //hide description on figure move
    const handleMouseDownOnPointFeature = event => event.button === 0
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    //show description on moving ended up
    const handleMouseUpOnPointFeature = event => {
        if (event.button === 0) {
            dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")})
        }
    };


    const volume = figure.get("volume") * scale;
    const fontSize = figure.get("fontSize") === "standard"
        ? volume / 8
        : (figure.get("fontSize") !== "" ? figure.get("fontSize") / 2 : 4);

    const viewBoxCoefficient = (+viewBox.split(" ")[2] / 90);
    const langCoefficient = locale?.get.name === "chinese" ? 40/23 : 1;

    return (figure && parameters) ? <g
        onMouseEnter={handleMouseEnterOnPointFeature}
        onMouseLeave={handleMouseLeaveOnPointFeature}
        onMouseDown={handleMouseDownOnPointFeature}
        onMouseUp={handleMouseUpOnPointFeature}
        onContextMenu={handleContextMenuOnPointFeature}
    >
        <svg
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            clipRule={clipRule}
            y={figure.get("y") * scale + volume}
            x={figure.get("x") * scale + 1.5 * volume}
            height={volume}
            width={volume}
            style={{
                fill: figure.get("color") == null ? "white" : figure.get("color"),
                fillRule: fillRule,
                stroke: figure.get("name") === "" && parameters[figure.get("tool")]?.isNameRequired ? "red" : "black",
                animationName: (isValidationAnimationShow && parameters[figure.get("tool")]?.isNameRequired
                    && (figure.get("name") === "" ||
                        (figure.get("description") === "" && figure.get("emoji").length === 0)
                    )
                ) ? "highlight" : "",
                animationDuration: "1s",
                animationFillMode: "backwards",
                opacity: figure.get("transparency")
            }}
        >
            <circle
                id={figure.get("uuid")}
                className="figure-neighborhood"
                cx={45 * viewBoxCoefficient}
                cy={55 * viewBoxCoefficient}
                fill={"green"}
                stroke="none"
                strokeWidth="0"
                opacity={isPointFeatureSelected ? 0.6 : 0}
                r={45 * viewBoxCoefficient}
            />
            {figureNode}
        </svg>
        <SvgText
            id={figure.get("uuid")}
            className={kind === "separate" ? "text" : "group"}
            strokeColor={figure.get("fontColor")}
            fillColor="black"
            fontSize={fontSize + "mm"}
            text={figure.get("name")}
            x={figure.get("x") * scale + 2 * volume - figure.get("name").length * fontSize * langCoefficient}
            y={figure.get("y") * scale + (2 + 0.07 * fontSize * langCoefficient * 40 / volume) * volume - 18}
            opacity={figure.get("transparency")}
        />
        <SvgEmojis
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            x={figure.get("x") * scale + 2 * volume - (
                figure.get("emoji")?.length <= 5 ? figure.get("emoji").length * 10 : 50
            ) * (volume / scale / 40)}
            y={figure.get("y") * scale + volume - 40 - (volume / scale - 40)}
            opacity={figure.get("transparency")}
            text={figure.get("emoji")?.map(em => em.emoji.native) || []}
            width={`${10 * volume / scale / 2.5}`}
            height={volume}
            fontSize={16}
            scaleCoefficient={volume / 40 / scale}
        />
        <SvgFlags
            id={figure.get("uuid")}
            x={figure.get("x") * scale + 2.6 * volume}
            y={figure.get("y") * scale + 1.1 * volume}
            height={volume / 4}
            width={volume / 4}
            flagInterval={volume / 3.2}
            flagsToRender={figure.get("flags")}
        />
        {figure.get("link") && <Link
            id={figure.get("uuid")}
            x={figure.get("x") * scale + 2.2 * volume}
            y={figure.get("y") * scale + volume}
            height={volume / 4}
            width={volume / 4}
            linkAddress={figure.get("link")}
        />}
    </g> : null;
}
