import React, {useState, useEffect} from "react";

import Partition from "./Partition";
import {NextPartitionArrow, PrevPartitionArrow, PartitionResizer} from "./controls";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useIconSet} from "../../../../customHooks/useContextIconSet";
import {replaceAt} from "../../../../pureFunctions";

import {ReactComponent as Logo} from "../../../../images/logo.svg";


export default function TabContent({tabIndex}) {
    const [firstPartitionIndex, setFirstPartitionIndex] = useState(0);

    const [capturedResizerIndex, setCapturedResizerIndex] = useState(-1);
    const [onCaptureXCoo, setOnCaptureXCoo] = useState(-1);
    const [capturedResizerWidthShift, setCapturedResizerWidthShift] = useState(-1);

    const locale = useLocalization();
    const {get: {tabSet}} = useIconSet();

    const [partitionWidths, setPartitionWidths] = useState(
        tabSet?.[tabIndex]?.children?.map(partition => partition.defaultWidth || 0) || [0]
    );

    const partitionCount = tabSet?.[tabIndex]?.children?.length || 0;
    const isSomeResizerCaptured = capturedResizerIndex !== -1;
    const minPartitionWidth = 70;

    useEffect(() => {
        if (tabSet?.[tabIndex]?.children) {
            setPartitionWidths(tabSet[tabIndex]?.children?.map(partition => partition.defaultWidth) || 0);
        }
    }, [tabIndex, tabSet]);

    const captureResizer = index => event => {
        setCapturedResizerIndex(index);
        setOnCaptureXCoo(event.clientX);
    };
    const moveResizer = (event) => setCapturedResizerWidthShift(event.clientX - onCaptureXCoo);
    const releaseResizer = () => {
        if (isSomeResizerCaptured) {
            setPartitionWidths(replaceAt(
                partitionWidths,
                capturedResizerIndex,
                Math.max(partitionWidths[capturedResizerIndex] + capturedResizerWidthShift, minPartitionWidth)
            ));
            setCapturedResizerIndex(-1);
            setCapturedResizerWidthShift(-1);
        }
    };

    const scrollToNextPartition = () => setFirstPartitionIndex((firstPartitionIndex + 1) % partitionCount);
    const scrollToPrevPartition = () => setFirstPartitionIndex((firstPartitionIndex - 1 + partitionCount) % partitionCount);

    return <div className="toolbar-tab-content-wrapper" style={{borderTopLeftRadius: !tabIndex ? "0" : "0.625rem"}}>
        {tabSet.length > 0 ? <>
            <PrevPartitionArrow onClick={scrollToPrevPartition}/>
            <div
                className="toolbar-tab-content-container"
                onMouseMove={isSomeResizerCaptured ? moveResizer : undefined}
                onMouseUp={releaseResizer}
            >
                {tabSet[tabIndex]?.children && tabSet[tabIndex].children.slice(firstPartitionIndex).map((partition, i) =>
                    <React.Fragment key={i}>
                        <Partition
                            partition={partition}
                            partitionIndex={i + firstPartitionIndex}
                            capturedResizerIndex={capturedResizerIndex}
                            capturedResizerWidthShift={capturedResizerWidthShift}
                            width={partitionWidths[i + firstPartitionIndex]}
                        />
                        <PartitionResizer onMouseDown={captureResizer(i + firstPartitionIndex)}/>
                    </React.Fragment>
                )}
                {tabSet[tabIndex]?.children && tabSet[tabIndex].children.slice(0, firstPartitionIndex).map((partition, i) =>
                    <React.Fragment key={i}>
                        <Partition
                            partition={partition}
                            partitionIndex={i}
                            capturedResizerIndex={capturedResizerIndex}
                            capturedResizerWidthShift={capturedResizerWidthShift}
                            width={partitionWidths[i]}
                        />
                        <PartitionResizer onMouseDown={captureResizer(i)}/>
                    </React.Fragment>
                )}
            </div>
            <NextPartitionArrow onClick={scrollToNextPartition}/>
        </>
            : <div className="toolbar-tab-content-loader-wrapper">
                <Logo className = "loading-logo-animation" height = "40" width = "40" />
                <h1 className = "toolbar-tab-content-loader-title">{locale?.get?.store.loadingPage.title}</h1>
            </div>}
    </div>;
}
