import {useDataSource} from "../../hooks/useContextDataSource";


export default function ({selectedNodeId}) {
    const {dataSources, dispatch} = useDataSource();

    const setServerTableDumpOfNode = event => dispatch({
        type: "Add or update data source",
        id: selectedNodeId,
        data: {serverDumpTable: event.target.value}
    });

    return <select onChange={setServerTableDumpOfNode} value={dataSources[selectedNodeId]?.serverDumpTable}>
        <option>--choose table--</option>
        <option>Surveys</option>
        <option>Users</option>
    </select>;
}