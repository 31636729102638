import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {useCanvasBoundingClientRect} from "../../customHooks/useContextCanvasBoundingClientRect";
import {useIconSet} from "../../../customHooks/useContextIconSet";
import Path from "../../pureFunctions/path.js";
import {useReduxData} from "../../customHooks/useContextReduxData";


export default function Polyline({figure, kind, isMouseDown}) {
    const canvasBoundingClientRect = useCanvasBoundingClientRect();
    const {get: {parameters}} = useIconSet();

    const {figureCenter, isValidationAnimationShow, scale} = useReduxData();
    const path = figure ? Path.transform(figure.get("points"), 2, 2, scale, scale) : "";

    const dispatch = useDispatch();

    useEffect(() => {
        function func() {
            if (isValidationAnimationShow)
                dispatch({type: "isValidationAnimationShow", value: false});
        }
        setTimeout(func, 1000);
    });

    const handleContextMenuOnPolyline = () => {
        if (canvasBoundingClientRect) {
            let x = figure.get("x") * scale + canvasBoundingClientRect.get.left;
            let y = figure.get("y") * scale + canvasBoundingClientRect.get.top;
            if (x !== figureCenter.x || y !== figureCenter.y) {
                dispatch({type: "figureCenter", value: {x: x, y: y}});
            }
        }
    };

    //show and hide description window
    const handleMouseEnterOnPolyline = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});

    const handleMouseLeaveOnPolyline = () => !isMouseDown
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    //hide description on figure move
    const handleMouseDownOnPolyline = event => event.button === 0
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: ""});

    //show description on moving ended up
    const handleMouseUpOnPolyline = event => event.button === 0
        && dispatch({type: "figureUuidDescriptionPopupShownFor", value: figure.get("uuid")});

    return <g
        className="figure"
        onMouseEnter={handleMouseEnterOnPolyline}
        onMouseLeave={handleMouseLeaveOnPolyline}
        onMouseDown={handleMouseDownOnPolyline}
        onMouseUp={handleMouseUpOnPolyline}
        onContextMenu={handleContextMenuOnPolyline}
    >
        <symbol id={`${figure.get("uuid")}-symbol-figure`}>
            <path
                fill="none"
                stroke="blue"
                strokeWidth={(figure.get("thickness") * 5 + 20) * scale}
                strokeLinecap="round"
                opacity="0"
                d={path}
            />
            <path
                fill="none"
                stroke={figure.get("color")}
                strokeWidth={figure.get("thickness")}
                strokeDasharray={figure.get("dashLength")}
                strokeLinecap="round"
                style={{
                    animationName: (isValidationAnimationShow && parameters[figure.get("tool")]?.isNameRequired
                        && (figure.get("name") === "" || figure.get("description") === "")
                    ) ? "highlight" : "",
                    animationDuration: "0.5s",
                    animationFillMode: "backwards",
                    strokeMiterlimit: "10"
                }}
                opacity={figure.get("transparency")}
                d={path}
            />
        </symbol>
        <use
            id={figure.get("uuid")}
            className={kind === "separate" ? "figure" : "group"}
            href={`#${figure.get("uuid")}-symbol-figure`}
            x={(figure.get("x") - 2) * scale}
            y={(figure.get("y") - 2) * scale}
        />
    </g>;
}
