import {v4 as uuid} from "uuid";

import {groupSchema, pointFeatureSchema, polygonSchema, polylineSchema} from "./validators";
import * as isUUID from "is-uuid";


export function areAllElementsEqual (mas) {
    let result = true;
    mas.map(el => mas[0] !== el ? result = false : null);
    return result;
}

export function setFixedChildrenValues (figures, uuid, parameters, dispatch, newValues, actionType, time, condition=()=>true) {
    let figure = figures.get(uuid);
    if (figure.get("tool") !== "g-s-72") {
        if (parameters.length === 2) {
            if (figure.get(parameters[0]) !== undefined || figure.get(parameters[1]) !== undefined) {
                let actionBody = {
                    uuid: uuid,
                    type: actionType,
                    time: time
                };
                actionBody[parameters[0]] = newValues[0];
                actionBody[parameters[1]] = newValues[1];
                condition(figure) && dispatch(actionBody);
            }
        } else {
            if (figure.get(parameters[0]) !== undefined) {
                let actionBody = {
                    uuid: uuid,
                    type: actionType,
                    time: time
                };
                actionBody[parameters[0]] = newValues[0];
                condition(figure) && dispatch(actionBody);
            }
        }
    } else {
        if (figure.get(parameters[0]) !== undefined && parameters[0] === "layout") {
            let actionBody = {
                uuid: uuid,
                type: actionType,
                time: time,
                layout: newValues[0]
            };
            condition(figure) && dispatch(actionBody);
        }
        figure.get("children").map((u, index) => {
            setFixedChildrenValues(figures, u, parameters, dispatch, newValues, actionType, time, condition);
            return undefined;
        });
    }
    return undefined;
}

export function setDifferentChildrenValues (figures, uuid, parameters, dispatch, newValues, actionType, time) {
    let figure = figures.get(uuid);
    if (figure.get("tool") !== "g-s-72") {
        if (parameters.length === 2) {
            if (figure.get(parameters[0]) !== undefined || figure.get(parameters[1]) !== undefined) {
                let actionBody = {
                    uuid: uuid,
                    type: actionType,
                    time: time
                };
                actionBody[parameters[0]] = newValues[0];
                actionBody[parameters[1]] = newValues[1];
                dispatch(actionBody);
            }
        } else {
            if (figure.get(parameters[0]) !== undefined) {
                let actionBody = {
                    uuid: uuid,
                    type: actionType,
                    time: time
                };
                actionBody[parameters[0]] = newValues[0];
                dispatch(actionBody);
            }
        }
    } else {
        figure.get("children").map((u, index) => {
            if (parameters.length === 2) {
                setDifferentChildrenValues(figures, u, parameters, dispatch, [newValues[0][index], newValues[1][index]], actionType, time);
            } else if (parameters.length === 1) {
                setDifferentChildrenValues(figures, u, parameters, dispatch, [newValues[0][index]], actionType, time);
            }
            return undefined;
        });
    }
    return undefined;
}

export function getChildrenValues(figures, uuid, parameter) {
    let figure = figures.get(uuid);
    if (!figure) {
        return [];
    }
    if (figure.get("tool") !== "g-s-72") {
        return figure.get(parameter);
    } else {
        let result = [];
        if (figure.get(parameter) !== undefined) {
            result.push(figure.get(parameter));
        }
        figure.get("children").map(u => {
            result.push(getChildrenValues(figures, u, parameter));
            return undefined;
        });
        return result;
    }
}

export function getChildrenUuidsSatisfyTheCondition(figures, uuid, condition=()=>true) {
    let figure = figures.get(uuid);
    if (!figure) {
        return null;
    }

    if (figure.get("tool") !== "g-s-72") {
        if (condition(figure)) {
            return uuid;
        } else {
            return [];
        }
    } else {
        let result = [];
        if (condition(figure)) {
            result.push(uuid);
        }
        figure.get("children").map(u => result.push(getChildrenUuidsSatisfyTheCondition(figures, u, condition)));
        return result;
    }
}

export function transformArrayValues (arr, scale, shift) {
    let result = [];
    if (typeof arr !== "object") {
        //console.log("data", arr, scale, shift, arr * scale + shift);
        return +arr * scale + shift;
    }
    arr.map(el => result.push(transformArrayValues(el, scale, shift)));
    return result;
}

export function flattenEmojis(arr) {
    let result = [];
    if (typeof arr === "object" && (arr.length === 0 || (typeof arr[0] === "object" && arr[0].emoji !== undefined))) {
        return [arr];
    }
    arr.map(el => flattenEmojis(el).map(elem => result.push(elem)));
    return result;
}

export function getRootUuid(figures, uuid) {
    if (figures.get(uuid).get("parentUuid")) {
        return getRootUuid(figures, figures.get(uuid).get("parentUuid"));
    }
    return uuid;
}

export function getUuidHierarchy(figures, uuid) {
    if (!uuid) {
        return [];
    }
    if (figures.get(uuid).get("parentUuid")) {
        return [uuid].concat(getUuidHierarchy(figures, figures.get(uuid).get("parentUuid")));
    }
    return [uuid];
}

// debounce(functionName, 50, false)
export function debounce(func, wait, immediate) {
    var timeout;
    // console.log(this);
    const context = this;
    return () => {
        const args = arguments;
        const later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function renderObjectCopy(
    figures,
    oldUuid,
    newParentUuid,
    dispatch,
    time,
    orderIndex,
    xCoo,
    yCoo
) {
    let sourceFigure = figures?.get(oldUuid)?.toJS();
    if (!sourceFigure) {
        return "";
    }
    let newObject = {};
    Object.keys(sourceFigure).map(key => {
        newObject[key] = sourceFigure[key];
        return undefined;
    });
    let newUuid = uuid();

    newObject["uuid"] = newUuid;
    newObject["parentUuid"] = newParentUuid;
    newObject["orderIndex"] = orderIndex;

    if (sourceFigure.tool.split("-")[0] !== "g") {
        newObject["x"] = newObject["x"] + xCoo;
        newObject["y"] = newObject["y"] + yCoo;
    } else {
        newObject["children"] = [];
    }

    let objType = sourceFigure.tool.split("-")[0];
    let actionType = objType === "g" // TODO: rewrite it with dictionary, like: {"g": "addGroup", ...}
        ? "addGroup" : objType === "p" ? "addPolygon" : objType === "c" ? "addPolyline" : "addPointFeature";

    if (objType === "g") {
        sourceFigure.children.map(u => {
                let newChildUuid = renderObjectCopy(figures, u, newUuid, dispatch, time, orderIndex, xCoo, yCoo);
                if (newChildUuid !== "") {
                    newObject["children"].push(newChildUuid);
                }
                return undefined;
            }
        );
    }

    const validationSchema = {g: groupSchema, p: polygonSchema, c: polylineSchema, i: pointFeatureSchema}[objType];
    if (!validationSchema.isValidSync(newObject)) {
        return;
    }

    newObject["type"] = actionType;
    newObject["time"] = time;
    dispatch(newObject);

    return newUuid;
}

export function isPointIntoRectangle (point, rectangle) {
    return !(
        point.x < rectangle.startX || point.y < rectangle.startY || point.x > rectangle.endX || point.y > rectangle.endY
    );
}


/**
 * Check if item in array or not.
 * @param  {string} item   An item, which may be in the array
 * @param  {array}  array  The array of string items
 * @return {boolean}       True if item in array, false otherwise
 */
export function isItemInArray (item, array) {
    return array.indexOf(item) !== -1;
}

/**
 * Check if figure selected or not.
 * @param  {string} uuid                 An item, which may be in the array
 * @param  {array}  selectedFigureUuids  The array of selected figure uuids
 * @param  {array}  figures              The figures object from state
 * @return {boolean}   True if figure or one of its parent selected, false otherwise
 */
export function isFigureSelected (uuid, selectedFigureUuids, figures) {
    if (!isUUID.v4(uuid)) {
        return false;
    }
    const figureUuids = getUuidHierarchy(figures, uuid);
    return selectedFigureUuids.filter(item => figureUuids.includes(item)).length > 0;
}

export function shouldFiguresUpdate (lastAction, actionList = "") {
    actionList = actionList
        || [ //all actions affecting figures changing
            "createMap",
            "addPolygon",
            "addPolyline",
            "addPointFeature",
            "addGroup",
            "changeObjectName",
            "changeObjectDescription",
            "changeObjectParentUuid",
            "changeFigureColor",
            "changeFigurePoints",
            "changeFigureDashLength",
            "addFigureEmoji",
            "deleteFigureEmoji",
            "changeFigureFontColor",
            "changeFigureFontSize",
            "changeFigureTransparency",
            "changeFigureLayout",
            "changeFigureVolume",
            "changeFigureThickness",
            "loadStateByTime",
            "clearState",
            "deleteFigure",
            "disbandGroup",
            "moveFigure"
        ];

    if (actionList.indexOf(lastAction) !== -1) {
        return true;
    }
    return false;
}

/**
 * Compute, what color (black or white) to use for the foreground, based on background color.
 * @param  {string} rgbBackgroundColor  The background color in RGB format, like: "#144066"
 * Note, that reduced colors like "#ccc" or color names like "green" are not allowed!
 * @return {string}                     The color name. "black" or "white"
 */
export function getPreferredFontColorByBackgroundColor(rgbBackgroundColor) {
    let hexBackground = rgbBackgroundColor.slice(1);
    let rgb = [
        parseInt(hexBackground.slice(0,2), 16),
        parseInt(hexBackground.slice(2,4), 16),
        parseInt(hexBackground.slice(4,6), 16)
    ];

    // http://www.w3.org/TR/AERT#color-contrast
    const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);
    return (brightness > 125) ? "black" : "white";
}

/**
 * Compute age of user (by his birthdate)
 * @param  {Object} user  The user object.
 * @param  {Date}   now   Now (time). If not declared, now = new Date().
 * Note, if user hasn't birthdate, function will return undefined
 * @return {number}       The age of user (integer)
 */
export function getAgeOfUser(user, now = new Date()) {
    const birthdate = user?.birthdate ? new Date(user.birthdate) : undefined;
    let age;
    if (birthdate) {
        age = (now.getFullYear() - birthdate.getFullYear()) - (
            now.getMonth() * 100 + now.getDate() < birthdate.getMonth() * 100 + birthdate.getDate() ? 1 : 0
        );
    }
    return age;
}
