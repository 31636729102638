import React, {useState, useEffect, useRef} from "react";
import {useDispatch} from 'react-redux';

import {useReduxData} from "../../customHooks/useContextReduxData";
import tooltipHOC from "../../../components/tooltipHOC";
import hintModeTooltipHOC from "../../onboarding/hintModeTooltipHOC";


const ToolMenuImageContainer = tooltipHOC( //hint on hover
    hintModeTooltipHOC( //hint in hintMode
        ({id, styles}) => <div className="tool-menu-item" id={`tool-menu-item-${id}`} style={styles}/>
    ), undefined, undefined, {direction: "right", padding: "10px"}
);


export default function ToolMenuItem({
    id,
    name,
    color,
    image,
    body,
    title,
    emojiPickerRerenderSign,
    sliderPurpose,
    sliderType,
    hintModeKey,
    tooltipContent
}) {
    const {getShouldToolMenuItemDisabled, openedMenuItemNumber} = useReduxData();
    const shouldToolMenuItemDisabled = getShouldToolMenuItemDisabled(name, sliderPurpose, sliderType);

    const dispatch = useDispatch();

    const [bodyOverflowX, setBodyOverflowX] = useState("0px");
    const [bodyOverflowY, setBodyOverflowY] = useState("0px");
    const [isPositionRecalculated, setIsPositionRecalculated] = useState(false); //used to prevent body render before useEffect
    const [shouldResetPosition, setShouldResetPosition] = useState(false); //on add/delete emoji item should be rerendered

    const bodyRef = useRef(null);

    useEffect(() => {
        const body = bodyRef?.current?.getBoundingClientRect();
        if (body?.right > document.body.clientWidth && !shouldResetPosition) {
            setBodyOverflowX(-body.width - 108 + "px");
        } else if (bodyOverflowX !== "30px") {
            setBodyOverflowX("30px");
        }

        if (body?.bottom >= document.body.clientHeight && !shouldResetPosition) {
            setBodyOverflowY(document.body.clientHeight - body?.bottom + "px");
        } else {
            setBodyOverflowY("0px");
        }

        setIsPositionRecalculated(true);
        if (+openedMenuItemNumber !== +id) {
            setIsPositionRecalculated(false);
        }

        if (shouldResetPosition) {
            setShouldResetPosition(false);
        }
    }, [openedMenuItemNumber === id, shouldResetPosition]);

    useEffect(() => setShouldResetPosition(true), [emojiPickerRerenderSign]);

    const changeBodyVisibility = event => (openedMenuItemNumber !== id
        || [`tool-menu-item-${id}`, `tool-menu-item-wrapper-${id}`].includes(event.target.id)
    ) && dispatch({type: "openedMenuItemNumber", value: openedMenuItemNumber !== id ? id : "-1"});

    const highlight = (openedMenuItemNumber === id && body) ? " tool-menu-item-wrapper-active" : "";
    const disabled = shouldToolMenuItemDisabled ? " disabled" : "";
    const pointerEvents = shouldToolMenuItemDisabled ? " none" : "auto";

    //Attention: tool-menu-item-wrapper class should be the first
    return <div
        className={`tool-menu-item-wrapper${highlight}${disabled}`}
        id={"tool-menu-item-wrapper-" + id}
        style={{backgroundColor: color, pointerEvents: pointerEvents}}
        onClick={changeBodyVisibility}
    >
        <ToolMenuImageContainer
            id={id}
            hintModeKey={hintModeKey}
            tooltipContent={tooltipContent}
            styles={{backgroundImage: `url(${image})`}}
        />
        {openedMenuItemNumber === id && body && !shouldToolMenuItemDisabled && <div
            className="tool-menu-item-body"
            id={`tool-menu-item-body-${id}`}
            ref={bodyRef}
            style={{
                marginLeft: `calc(30px + ${+id % 2 === 0 ? "50px" : "0px"} + ${bodyOverflowX})`,
                marginTop: `calc(-40px + ${bodyOverflowY})`,
                opacity: isPositionRecalculated ? 1 : 0
            }}
        >{title ? <p className="tool-menu-item-title">{title}</p> : undefined}{body}</div>}
    </div>;
}
