import React, {useRef} from "react";

import NameField from "./NameField";
import RetractableSubsection from "./RetractableSubsection";
import useIconDropTarget from "./dnd/hooks/useIconDropTarget";
import useTabs from "../hooks/useTabs";
import {useLocalization} from "../../../../customHooks/useContextLocalization";
import useRenderIconsById from "../hooks/useRenderIconsById";
import useSubpartitionDragSource from "./dnd/hooks/useSubpartitionDragSource";
import useSubpartitionDropTarget from "./dnd/hooks/useSubpartitionDropTarget";

import {ReactComponent as Cross} from "../../../images/cross-transparent.svg";


const Subpartition = ({
    subpartition,
    icons,
    tabIndex,
    partitionIndex,
    subpartitionIndex,
    tryToRemoveSubpartition,
    checkIcon,
    selectedIcons
}) => {
    const subpartitionRef = useRef();

    const {tabs, dispatchTabs} = useTabs();
    const locale = useLocalization();
    const [drop, {isOver}] = useIconDropTarget(tabs, dispatchTabs, tabIndex, partitionIndex, subpartitionIndex);
    const [, dragSubpartition] = useSubpartitionDragSource(tabIndex, partitionIndex, subpartition.id);
    const [{sourcePartitionIndex, sourceSubpartitionId}, dropSubpartition] =
        useSubpartitionDropTarget(subpartitionRef, tabIndex, partitionIndex, subpartitionIndex, dispatchTabs, tabs);
    const renderIcons = useRenderIconsById();

    dragSubpartition(dropSubpartition(drop(subpartitionRef)));
    let opacity = (sourcePartitionIndex === partitionIndex
        && sourceSubpartitionId === tabs[tabIndex].children[partitionIndex].children[subpartitionIndex].id) ? 0 : 1;

    return <div
        ref={subpartitionRef}
        key={`account-icons-subpartition-title-name-container-${subpartitionIndex}`}
        className="account-icons-subpartition-title-name-container"
        style={{fontWeight: isOver ? "600" : "400", opacity: opacity}}
    >
        <RetractableSubsection
            idSuffix={`-${partitionIndex}-${subpartitionIndex}`}
            name={<NameField
                defaultValue={subpartition.name[locale?.get.name]}
                tIndex={tabIndex}
                pIndex={partitionIndex}
                sIndex={subpartitionIndex}
            />}
            key={`account-icons-subpartition-${subpartitionIndex}`}
            labelClass="account-icons-subpartition-title"
        >
            <div className="account-icons-subpartition-container">
                {icons && renderIcons(subpartition?.children, icons, checkIcon, selectedIcons, tabIndex, partitionIndex, subpartitionIndex)}
            </div>
        </RetractableSubsection>
        <Cross
            className="account-icons-partition-button"
            id={`account-icons-partition-button-${subpartitionIndex}`}
            height="16"
            width="16"
            viewBox="-4 -4 24 24"
            fill="#144066"
            onClick={tryToRemoveSubpartition}
            style={{marginTop: "0.25rem"}}
        />
    </div>;
};

export default Subpartition;