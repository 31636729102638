import {useState} from "react";
import Async from "react-select/async";
import {toast} from "react-toastify";
import {createOption} from "@semograph/semui";

import {ClearIndicator, DropdownIndicator, IndicatorSeparator, ValueContainer} from "../../../store/components/CustomIcons";
import SocialNetworkIcon from "../../../components/SocialNetworkIcon";
import {useApiClient} from "../../../customHooks/useApiClient";
import {useLocalization} from "../../../customHooks/useContextLocalization";
import {useMap} from "../../customHooks/useContextMap";
import {useCurrentUser} from "../../../customHooks/useContextCurrentUser";

import {ReactComponent as ShareIcon} from "../../../images/social/icon-share.svg";


export default function ShareToSomeoneField({openSignInIfUnauthorized}) {
    const [users, setUsers] = useState([]);

    const api = useApiClient();
    const locale = useLocalization();
    const map = useMap();
    const user = useCurrentUser();

    const searchUsers = async inputValue => inputValue.length > 2 && api.get(
        `/api/v1/store/user-list/`,
        {params: {user_input: inputValue}}
    ).then(result => result.data.map(createOption));

    const shareMapToUsers = async () => {
        openSignInIfUnauthorized();

        if (!user) {
            toast.warn(locale?.get.studio.sharing.authRequired);
            return undefined;
        }

        await map.shareMap();
        if (map.mapId) {
            try {
                await api.put(`/api/v1/map/${map.mapId}/share_to_someone/`, {users: users});
            } catch (err) {
                if (err.response.status === 404 && err.response.data.detail) {
                    if (err.response.data.detail.users) {
                        toast.error(locale?.get.studio.sharing.noUsersFound);
                    } else {
                        toast.error(locale?.get.studio.sharing.sendToServerRequired);
                    }
                }
                return;
            }
            toast.success(locale?.get.studio.sharing.sharedSuccessfully);
        }
    };

    const getNoOptionMessage = () => locale?.get?.store.product.form.noOptions;
    const getLoadingMessage = () => locale?.get?.store.product.form.loading;
    const setUsers_ = options => setUsers(options.map(option => option.value));

    return <div className = "footer-share-wrapper">
        <Async
            isClearable
            isMulti
            isPlanar
            closeMenuOnSelect
            cacheOptions
            defaultOptions
            menuPlacement="top"
            loadOptions={searchUsers}
            className="studio-footer-share-field"
            name="users"
            placeholder={locale?.get?.store.product.form.usersPlaceholder}
            components={{ClearIndicator, DropdownIndicator, IndicatorSeparator, ValueContainer}}
            noOptionsMessage={getNoOptionMessage}
            loadingMessage={getLoadingMessage}
            onChange={setUsers_}
            value={users.map(createOption)}
        />
        <SocialNetworkIcon
            id="footer-social-share"
            className="footer-icon-wrapper"
            SvgIcon={ShareIcon}
            iconSize="24"
            onClick={shareMapToUsers}
        />
    </div>;
}
