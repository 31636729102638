import React from "react";
import MDEditor, {commands} from "@uiw/react-md-editor";

import {useLocalization} from "../../../customHooks/useContextLocalization";

import {ReactComponent as Bold} from "../../images/md-editor/bold.svg";
import {ReactComponent as Italic} from "../../images/md-editor/italic.svg";
import {ReactComponent as Underline} from "../../images/md-editor/underline.svg";
import {ReactComponent as Code} from "../../images/md-editor/code.svg";
import {ReactComponent as Strikethrough} from "../../images/md-editor/strikethrough.svg";
import {ReactComponent as UnorderedList} from "../../images/md-editor/unorderedList.svg";
import {ReactComponent as OrderedList} from "../../images/md-editor/orderedList.svg";
import {ReactComponent as Quote} from "../../images/md-editor/quote.svg";
import {ReactComponent as Link} from "../../images/md-editor/link.svg";
import {ReactComponent as Image} from "../../images/md-editor/image.svg";
import {ReactComponent as Arrow} from "../../images/md-editor/arrow.svg";
import {ReactComponent as Live} from "../../images/md-editor/live.svg";

const Divider = () => null;
const FlippedArrow = () => <Arrow style = {{transform: "rotate(180deg)"}}/>;
const TitleGroup = () => {
    const locale = useLocalization();

    return <div className = "md-editor-heading">
        <p>{locale?.get?.store.product.form.mdTitles}</p>
        <Arrow height = "1.25rem" style = {{transform: "rotate(270deg)"}}/>
    </div>;
};

const getSurroundingWord = (text, position) => {
    if (!text) {
        return undefined;
    }

    const isWordDelimiter = c => (c === ' ' || c.charCodeAt(0) === 10);

    let start = 0;
    let end = text.length;

    for (let i = position; i - 1 > -1; i--) {
        if (isWordDelimiter(text[i - 1])) {
            start = i;
            break;
        }
    }

    for (let i = position; i < text.length; i++) {
        if (isWordDelimiter(text[i])) {
            end = i;
            break;
        }
    }

    return { start, end };
}

const CustomMDEditor = props => {

    const locale = useLocalization();

    const divider = {
        name: "CustomDivider",
        buttonProps: {disabled: true, style: {cursor: "default", width: "0.625rem"}},
        icon: <Divider/>,
        keyCommand: "customDivider",
        execute: () => undefined,
    };

    commands.bold.icon = <Bold/>;
    commands.italic.icon = <Italic/>;
    commands.strikethrough.icon = <Strikethrough/>;
    commands.code.icon = <Code/>;
    commands.orderedListCommand.icon = <OrderedList/>;
    commands.unorderedListCommand.icon = <UnorderedList/>;
    commands.quote.icon = <Quote/>;
    commands.link.icon = <Link/>;
    commands.image.icon = <Image/>;
    commands.codeEdit.icon = <Arrow/>;
    commands.codeLive.icon = <Live/>;
    commands.codePreview.icon = <FlippedArrow/>;
    commands.title1.buttonProps.style = {width: "7.375rem"};
    commands.title1.icon = (<div style={{ fontSize: 18, textAlign: 'left' }}>{locale?.get?.store.product.form.mdTitle} 1</div>);
    commands.title2.icon = (<div style={{ fontSize: 16, textAlign: 'left' }}>{locale?.get?.store.product.form.mdTitle} 2</div>);
    commands.title3.icon = (<div style={{ fontSize: 15, textAlign: 'left' }}>{locale?.get?.store.product.form.mdTitle} 3</div>);
    commands.title4.icon = (<div style={{ fontSize: 14, textAlign: 'left' }}>{locale?.get?.store.product.form.mdTitle} 4</div>);
    commands.title5.icon = (<div style={{ fontSize: 13, textAlign: 'left' }}>{locale?.get?.store.product.form.mdTitle} 5</div>);
    commands.title6.icon = (<div style={{ fontSize: 12, textAlign: 'left' }}>{locale?.get?.store.product.form.mdTitle} 6</div>);

    // console.log(commands);

    return <MDEditor
        className = {props.className || "product-form-editor"}
        minHeight = "120"
        autoFocus = {false}
        fullscreen = {false}
        toolbarHeight ={43}
        commands = {[
            commands.bold,
            commands.italic,
            commands.strikethrough,
            commands.code,
            {
                name: "Underline",
                buttonProps: {},
                icon: <Underline/>,
                keyCommand: "underline",
                execute: (state, api) => {
                    const selectWord = ({text, selection}) => {
                        if (text && text.length && selection.start === selection.end) {
                            // the user is pointing to a word
                            return getSurroundingWord(text, selection.start);
                        }
                        return selection;
                    }

                    const newSelectionRange = selectWord({ text: state.text, selection: state.selection });
                    const state1 = api.setSelectionRange(newSelectionRange);
                    const state2 = api.replaceSelection(`<ins>${state1.selectedText}</ins>`);
                    api.setSelectionRange({
                        start: state2.selection.end - 5 - state1.selectedText.length,
                        end: state2.selection.end - 6,
                    });
                },
            },
            divider,
            commands.unorderedListCommand,
            commands.orderedListCommand,
            commands.quote,
            divider,
            commands.link,
            commands.image,
            divider,
            commands.group([commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6], {
                name: 'title',
                icon: <TitleGroup/>,
                groupName: 'title',
                buttonProps: { 'aria-label': 'Insert title', "style": {width: "8rem"}}
            }),
            divider,
            commands.codeEdit,
            commands.codeLive,
            commands.codePreview,
        ]}
        extraCommands = {[]}
        value = {props.value}
        onChange = {props.onChange}
    />;
}

export default CustomMDEditor;