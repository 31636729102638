import React from "react";

import Studio from "./Studio";
import {SnappingModeProvider} from "./customHooks/useContextSnappingMode";
import {MapFileNameProvider} from "./customHooks/useContextMapFileName";
import {MapProvider} from "./customHooks/useContextMap";
import {OnboardingProvider} from "./customHooks/useContextOnboarding";
import {CanvasBoundingClientRectProvider} from "./customHooks/useContextCanvasBoundingClientRect";
import {SurveySchemeProvider} from "./customHooks/useContextSurveyScheme";
import {ReduxDataProvider} from "./customHooks/useContextReduxData";
import {useLocalization} from "../customHooks/useContextLocalization";


export default function StudioWithProviders() {
    const locale = useLocalization();

    return locale?.get ?
        <SnappingModeProvider>
        <MapFileNameProvider>
        <CanvasBoundingClientRectProvider>
        <ReduxDataProvider>
        <MapProvider>
        <OnboardingProvider>
        <SurveySchemeProvider>
            <Studio/>
        </SurveySchemeProvider>
        </OnboardingProvider>
        </MapProvider>
        </ReduxDataProvider>
        </CanvasBoundingClientRectProvider>
        </MapFileNameProvider>
        </SnappingModeProvider> : null;
}
