import {toast} from "react-toastify";


export const getUpdateIndex = (localIndex, prohibitedIndices) =>
    [0, 1, 2].filter(x => !prohibitedIndices.includes(x))[localIndex];

export const getAllowedOptions = prohibitedIndices => {
    const names = ["majorScope", "minorScope", "patchScope"];
    let allowedNames = [];
    names.map((name, i) => !prohibitedIndices.includes(i) && allowedNames.push(name));
    return allowedNames;
}

export const getLanguageByAbbr = (languages,  abbr) => {
    let result = [];
    Object.keys(languages).map(lang => languages[lang].abbr.toLowerCase() === abbr && result.push(lang));
    return result[0] || undefined;
}

export const getFileNameFromContentDisposition = disposition => {
    if (disposition && disposition.startsWith('attachment')) {
        let filename = disposition.replace("attachment;", ""); //replaces first match only
        filename = filename.trim();
        if (filename.includes("filename*=") && filename.includes("filename=")) {
            let filenames = filename.split(";"); //we can parse by ";" because all ";"s inside filename are escaped
            if (filenames.length > 1) { //"filename=" or "filename*=" not inside filename
                if (filenames[0].trim().startsWith("filename*=")) { //"filename*=" is preferred
                    filename = filenames[0].trim();
                } else {
                    filename = filenames[1].trim();
                }
            }
        }
        if (filename.startsWith("filename*=")) {
            filename = filename.replace("filename*=", "")
                .split("''").slice(1).join("''"); //remove encoding and ''
            filename = decodeURIComponent(filename);
        } else if (filename.startsWith("filename=")) {
            filename = filename.replace("filename=", "")
            if (filename.startsWith('"') && filename.endsWith('"')) {
                filename = filename.slice(1, filename.length - 1); //remove quotes
            }
        }
        return filename;
    }
}

export const createImageFromSelectedIcons = (selectedIcons, icons) => {
    const ICON_COOS = [
        {x: 0, y: 0},
        {x: 84, y: 0},
        {x: 168, y: 0},
        {x: 0, y: 84},
        {x: 84, y: 84},
        {x: 168, y: 84}
    ];

    if (icons.length === 0) {
        return {image: "", names: []};
    }
    let cardPhoto = `<svg id="svg-layer" xmlns="http://www.w3.org/2000/svg" width="230" height="150" viewBox="0 0 230 150">`;
    let sourceIconNames = [];
    selectedIcons.map((ind, i) => {
        if (icons[ind]) {
            sourceIconNames.push(icons[ind].name);
            cardPhoto += `<symbol id="icon-${i}">${icons[ind].textData}</symbol>`;
            cardPhoto += `<use href="#icon-${i}" x="${ICON_COOS[i].x}" y="${ICON_COOS[i].y}"/>`;
        }
    });
    cardPhoto += "</svg>";
    return {image: cardPhoto, names: sourceIconNames};
};


//FILE LOADERS//////////////////////////////////////////////////////////////////////////////////////////////////////////


const loadArchive = (setFieldValue, extractArchive) => async file => await extractArchive(file, setFieldValue);

const clearArchive = (setFieldValue, setIconsFromArchive, setSelectedIconsFromArchive) => () => {
    setFieldValue("file", undefined);
    setFieldValue("card_photo", "");
    setFieldValue("card_photo_icon_names", "");
    setIconsFromArchive([]);
    setSelectedIconsFromArchive([]);
};

const loadImage = (setFieldValue, errMessage) => file => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
        let result = event.target.result;
        const img = document.createElement("img");
        img.onload = () => {
            setFieldValue("file", file);
            setFieldValue("card_photo", result);
        };
        img.onerror = () => toast.error(errMessage);
        img.src = result;
    };
};

const clearImage = setFieldValue => () => {
    setFieldValue("card_photo", "");
    setFieldValue("file", undefined);
};

export const getFileFieldProps = categoryIndex => ([
    { //icons
        loadFile: loadArchive,
        clearFile: clearArchive,
    },
    { //backgrounds
        loadFile: loadImage,
        clearFile: clearImage,
    },
    { //polygons
        loadFile: loadImage,
        clearFile: clearImage,
    },
    { //patterns
        loadFile: () => {},
        clearFile: () => {}
    },
][categoryIndex]);