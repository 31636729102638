import React from "react";
import {components} from 'react-select';

import {ReactComponent as SearchBoldTransparentIconRaw} from "../images/search-bold-transparent.svg";
import {ReactComponent as CrossIconRaw} from "../images/cross-transparent.svg";
import {ReactComponent as StarHeavyRaw} from "../images/star-heavy.svg";
import {ReactComponent as StarLightRaw} from "../images/star-light.svg";

import searchStyles from './header/search.module.css';


export const SearchBoldIcon = () =>
    <SearchBoldTransparentIconRaw viewBox = "0 0 28 24" className ={searchStyles["header-search-active-icon"]}/>;
export const SearchIcon = () => <SearchBoldTransparentIconRaw className = "product-from-icon-search"/>;
export const SearchIconTransparent = (props) =>
    <SearchBoldTransparentIconRaw className ={`${props.className} product-from-icon-search`} opacity = "0.8"/>;


export const StarHeavy = props =>
    <StarHeavyRaw height = "20" width = "26" viewBox = "0 0 20 20" onClick = {props.onClick}/>;
export const StarLight = props =>
    <StarLightRaw height = "20" width = "26" viewBox = "0 0 20 20" onClick = {props.onClick}/>;
export const StarHeavySmall = () => <StarHeavyRaw height = "18" width = "22" viewBox = "0 0 20 18" />;
export const StarLightSmall = () => <StarLightRaw height = "18" width = "22" viewBox = "0 0 20 18"/>;

export const CrossIcon = () => <CrossIconRaw className = "product-from-icon-cross"/>;


//reactSelect custom icons

export const ClearIndicator = props => {
    const {
        innerProps: { ref, ...restInnerProps },
    } = props;
    return <div className="react-select-clear-indicator-wrapper" {...restInnerProps} ref={ref}>
        <CrossIconRaw className = "product-form-clear-indicator"/>
    </div>;
};
export const IndicatorSeparator = () => null;
export const DropdownIndicator = () => <SearchIcon/>;
export const ValueContainer = ({children, ...props}) => {
    const {getValue, hasValue} = props;
    const values = getValue();

    if (!hasValue) {
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    }
    return (
        <components.ValueContainer {...props}>
            {values.map(v => v.label).join(', ')}
            {children[1]}
        </components.ValueContainer>
    );
};
