import React, {useState, useEffect, useRef} from "react";

import {useReduxData} from "../../../customHooks/useContextReduxData";


export default function Description() {
    const {
        figureUuidDescriptionPopupShownFor,
        objectDescriptionParameters: [objectName, objectDescription, objectCenterX, objectCenterY]
    } = useReduxData();

    const [maxHeight, setMaxHeight] = useState(objectCenterY);

    const descriptionMenu = useRef(null);

    useEffect(() => {
        setMaxHeight(document.body.clientHeight - descriptionMenu?.current?.getBoundingClientRect().height);
    }, [objectName, objectDescription]);

    const animationName = (figureUuidDescriptionPopupShownFor !== "" && objectDescription !== "") ? "show" : "hide";
    const speed = objectDescription !== "" ? "1s" : "0s";

    return <div className="description-window-invisible-container" style={{
        marginLeft: `clamp(0%, ${objectCenterX - 250}px, calc(100% - 250px))`,
        marginTop: `clamp(0%, ${objectCenterY}px, ${maxHeight}px)`
    }}>
        <div className="description-window" ref={descriptionMenu} style={{
            animationName: animationName,
            animationDuration: speed,
            overflowWrap: "anywhere",
            animationFillMode: "forwards"
        }}>
            <h4 className="description-window-title">{objectName}</h4>
            <div><p className="description-window-text">{objectDescription}</p></div>
        </div>
    </div>;
}
