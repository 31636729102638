import React, {useCallback, useContext, useEffect, useState} from "react";
import Gravatar from "react-gravatar";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

import {useModal} from "./useContextModal";
import {useApiClient} from "./useApiClient";
import {useLocalization} from "./useContextLocalization";
import {useSurveyScheme} from "../studio/customHooks/useContextSurveyScheme";


export const CurrentUserContext = React.createContext();

export const CurrentUserProvider = ({children}) => {
    const api = useApiClient();
    const [user, setUser] = useState();

    const fetchUser = useCallback(() => {
        api.get('/api/v1/user/me', {restricted: false})
            .then(resp => setUser(resp.data))
            .catch(() => setUser(null));
    }, [api]);

    useEffect(() => fetchUser(), []);

    return <CurrentUserContext.Provider value={{user, setUser, fetchUser}}>
        {children}
    </CurrentUserContext.Provider>;
};


export const useCurrentUser = (allow_unauthorized=false) => {
    const {user} = useContext(CurrentUserContext);
    return user;
}


const Avatar = ({custom, social, email, size}) => <span className="top-menu-avatar-wrapper">
    {!social && !custom && <Gravatar email={email} size={size} />}
    {(custom || social) && <img
        className="react-gravatar"
        src={custom || social}
        alt="user-icon"
        height={size}
        width={size}
    />}
</span>;


export const UserInlineView = ({user, prefix, show_avatar=true, show_login=true, size=30, ...props}) => {
    const modal = useModal();
    const openEditProfilePopup = () => !prefix && modal.set("edit_profile");
    return <div className={`${prefix || "top-menu-"}user-name-container`} onClick={props.onClick || undefined}>
        <span onClick={openEditProfilePopup}>{user && show_login && user.username}</span>
        {user && show_avatar &&
            <Avatar custom={user.custom_avatar} social={user.social_avatar} email={user.email} size={size}/>
        }
    </div>;
}


export const UserToolbarView = (props) => {
    const history = useHistory();
    const locale = useLocalization();
    const api = useApiClient();
    const surveyScheme = useSurveyScheme();
    const modal = useModal();
    const {user, setUser} = useContext(CurrentUserContext);

    const logout = useCallback(async () => {
        await api.delete('/api/v1/token/');
        setUser(undefined);
        window.location.reload();
    }, [api]);

    const showSurvey = async () => {
        try {
            await api.get("/api/v1/surveys/");
        } catch (e) {
            if (e.response.status === 404) {
                try {
                    let response = await api.get("/api/v1/surveys/scheme/");
                    surveyScheme.set(response.data);
                    modal.set("survey");
                } catch(err) {
                    if (err.response.status === 409) {
                        toast.warn(locale?.get.studio.header.research.surveyWarn);
                    } else if (err.response.status === 404) {
                        // survey scheme not found
                    }
                }
            }
        }
    };

    const openAnalystPage = () => history.push("/research");

    return <div className={`${props.prefix || ""}dropdown`}>
        <div className={`${props.prefix || ""}dropbtn`}><UserInlineView {...props} /></div>
        <div className={`${props.prefix || ""}dropdown-content`}>
            {!props.prefix && <span onClick={showSurvey}>{locale?.get.studio.header.research.survey}</span>}
            {!props.prefix && user?.is_analyst &&
                <span onClick={openAnalystPage}>{locale?.get.studio.header.research.analytics}</span>
            }
            <span onClick={logout}>{locale?.get.auth.signingout}</span>
        </div>
    </div>;
}
