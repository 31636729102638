import React from "react";
import {Scrollbars} from 'react-custom-scrollbars-2';


const Scrollbar = ({autohide, classNamePrefix, style, children}) => {
    return <Scrollbars
        autoHide={autohide}
        autoHideTimeout={500}
        autoHideDuration={1000}
        renderTrackHorizontal={props => <div {...props} className={`${classNamePrefix}track-horizontal`}/>}
        renderTrackVertical={props => <div {...props} className={`${classNamePrefix}track-vertical`}/>}
        renderThumbHorizontal={props => <div {...props} className={`${classNamePrefix}thumb-horizontal`}/>}
        renderThumbVertical={props => <div {...props} className={`${classNamePrefix}thumb-vertical`}/>}
        renderView={props => <div {...props} style = {{
            position: "absolute",
            inset: "0px",
            overflow: "scroll",
            marginRight: "-15px",
            marginBottom: "-15px",
            paddingRight: "1rem"
        }}/>}
        style={style}
    >{children}</Scrollbars>
};

export default Scrollbar;