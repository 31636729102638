import React, {useContext} from "react";
import {Formik, Field, Form, ErrorMessage, getIn} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";

import SocialNetworkIcon from "../components/SocialNetworkIcon";
import {CurrentUserContext} from "../customHooks/useContextCurrentUser";
import {ErrorView, RecaptchaField, getStyles} from "./form_fields";
import {useLocalization} from "../customHooks/useContextLocalization";
import {useApiClient} from "../customHooks/useApiClient";
import {useModal} from "../customHooks/useContextModal";
import {isAgreementAccepted} from "../studio/AgreementShowingHandler";

import {ReactComponent as VKIcon} from "../images/social/icon-vk.svg";
// import {ReactComponent as FBIcon} from "../images/social/icon-facebook.svg";
import {ReactComponent as OKIcon} from "../images/social/icon-ok.svg";


const socialNetworks = {
    vk: VKIcon,
    // facebook: FBIcon,
    ok: OKIcon
};


export const SigninForm = () => {
    const locale = useLocalization();
    const api = useApiClient();
    const {user, fetchUser} = useContext(CurrentUserContext);
    const modal = useModal();

    const openForgotPasswordWindow = () => modal.set("forgot");
    const openSignupWindow = () => modal.set("signup");

    const callOAuth = async event => {
        if (!isAgreementAccepted()) {
            let providerName = event.target.id.split("-")[2];
            if (!providerName) {
                providerName = event.target.parentNode.id.split("-")[2];
            }
            modal.set(`${providerName}_oauth_license_agreement`);
        }
    };

    //we cannot remove links because of CORS policy (is prevents redirects via axios)
    const socialComponents = Object.keys(socialNetworks).map(key => !isAgreementAccepted()
        ? <SocialNetworkIcon
            id={`auth-social-${key}`}
            key={`auth-social-${key}`}
            className="auth-social-icon"
            SvgIcon={socialNetworks[key]}
            iconSize="32"
            onClick={callOAuth}
        />
        : <a href={`/api/v1/oauth/authorize/${key}`} key={`auth-social-${key}`}>
            <SocialNetworkIcon
                id={`auth-social-${key}`}
                className="auth-social-icon"
                SvgIcon={socialNetworks[key]}
                iconSize="32"
                onClick={callOAuth}
            />
        </a>
    );

    return user
        ? <span>{locale?.get.auth.alreadyLoggedIn}</span>
        : <Formik
            initialValues={{username: '', password: '', recaptcha: ''}}
            validationSchema={Yup.object({
                username: Yup.string()
                    .max(30, locale?.get.auth.errorView.tooLongUsername)
                    .required(locale?.get.auth.errorView.required),
                password: Yup.string()
                    .min(6, locale?.get.auth.errorView.tooShortPassword)
                    .max(20, locale?.get.auth.errorView.tooLongPassword)
                    .required(locale?.get.auth.errorView.required),
                recaptcha: Yup.string()
                    .required(locale?.get.auth.errorView.arentYouRobot),
            })}
            onSubmit={async (values, {setSubmitting}) => {
                let authResponse;
                try {
                    authResponse = await api.post('/api/v1/token', values, {restricted: false});
                    fetchUser();
                    modal.set("");
                    toast.success(locale?.get.auth.welcomeTo);
                } catch (err) {
                    values.recaptcha = "";
                    if (err.response.status === 401) {
                        toast.warn(locale?.get.auth.incorrectInput);
                    }
                }
                if (authResponse) {
                    const isAgreementAccepted = document.cookie?.split('; ')?.find(row => row.startsWith('agreement'))?.split('=')[1];
                    if (isAgreementAccepted === "true") {
                        await api.post("/api/v1/agreement/", {accepted: true});
                    } else {
                        const resp = await api.get("/api/v1/agreement/");
                        if (resp.data.accepted) {
                            document.cookie = "agreement=true";
                        }
                    }
                }
                setSubmitting(false);
            }}
            render={formProps => {
                return <Form className='row gtr-uniform gtr-50'>
                    <h4 className="auth-title">{locale?.get.auth.signin}</h4>
                    <div className="field-wrapper">
                        <Field
                            className="auth-text-field"
                            name='username'
                            type='text'
                            placeholder={locale?.get.auth.login}
                            style={getIn(formProps.touched, 'username') && getStyles(formProps.errors, 'username')}
                        />
                        <ErrorMessage component={ErrorView} name='username'/>
                    </div>
                    <div className="field-wrapper">
                        <Field
                            className="auth-text-field"
                            name='password'
                            type='password'
                            placeholder={locale?.get.auth.password}
                            style={getIn(formProps.touched, 'password') && getStyles(formProps.errors, 'password')}
                        />
                        <ErrorMessage component={ErrorView} name='password'/>
                    </div>
                    <a className="auth-link" onClick={openForgotPasswordWindow}>
                        {locale?.get.auth.didYouForgetPassword}
                    </a>
                    <div className="field-wrapper">
                        <Field
                            name='recaptcha'
                            component={RecaptchaField}
                            style={getIn(formProps.touched, 'recaptcha') && getStyles(formProps.errors, 'recaptcha')}
                        />
                        <ErrorMessage component={ErrorView} name='recaptcha'/>
                    </div>
                    <div className='col-12'>
                        <button type='submit' className='button alt'>{locale?.get.auth.signin}</button>
                    </div>
                    <div className='col-12 auth-text' style={{textAlign: 'center'}}>{locale?.get.auth.or}</div>
                    <div className='col-12 social-signin'>{socialComponents}</div>
                    <div className='col-12 auth-text' style={{textAlign: 'center'}}>{locale?.get.auth.or}</div>
                    <a className="auth-link" onClick={openSignupWindow}>{locale?.get.auth.signupYourself}</a>
                </Form>;
            }}
        />;
};
