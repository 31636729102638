import DataTypeLabel from "./DataTypeLabel";
import DataSourceSelector from "./DataSourceSelector";
import LocalDataLoader from "./LocalDataLoader";
import ServerDataSourceSelector from "./ServerDataSourceSelector";
import ServerMapSourceSelector from "./ServerMapSourceSelector";
import ServerTableDumpSelector from "./ServerTableDumpSelector";
import {useDataSource} from "../../hooks/useContextDataSource";


export default function ({handleServerMapTableShowing, selectedNodeId}) {
    const {dataSources} = useDataSource();

    const nodeDataSource = dataSources[selectedNodeId]?.source;
    const nodeDataServerSource = dataSources[selectedNodeId]?.serverSource;

    return <div className="research-source-node-configurator-container">
        <h3>Load Data To Work With</h3>
        <div className="research-source-node-configurator-grid">
            <DataSourceSelector
                selectedNodeId={selectedNodeId}
                handleServerMapTableShowing={handleServerMapTableShowing}
            />
            {nodeDataSource === "local"
                ? <LocalDataLoader selectedNodeId={selectedNodeId}/>
                : nodeDataSource === "server" ? <ServerDataSourceSelector selectedNodeId={selectedNodeId}/> : null
            }
            {nodeDataSource === "server"
                ? nodeDataServerSource === "maps"
                    ? <ServerMapSourceSelector selectedNodeId={selectedNodeId}/>
                    : nodeDataServerSource === "dump" ? <ServerTableDumpSelector selectedNodeId={selectedNodeId}/> : null
                : null
            }
        </div>
        <DataTypeLabel id={selectedNodeId}/>
    </div>
}
