import {flattenDeep} from "lodash";

import {getChildrenValues, areAllElementsEqual} from '../../../../pureFunctions/usefulFunctions';


export const getParameterFromFigures = (figures, selectedFigureUuids, parameter) => {
    let value = "no";
    let parameterArray = [];
    selectedFigureUuids.map(u => {
        let tempArr = flattenDeep([getChildrenValues(figures, u, parameter)]);
        if (typeof tempArr === "object") {
            tempArr.map(el => parameterArray.push(el));
        } else {
            parameterArray.push(tempArr);
        }
        return undefined;
    });

    let shouldParameterShow = true;
    if (parameterArray.length !== 0 && parameterArray[0] !== undefined) {
        parameterArray.map(v => (v === undefined ? shouldParameterShow = false : null));
    } else {
        shouldParameterShow = false;
    }

    if (areAllElementsEqual(parameterArray) && parameterArray[0] !== undefined) {
        value = parameterArray[0];
    }

    return [value, shouldParameterShow];
};
