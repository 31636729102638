import React from "react";
import {Formik, Field, Form, ErrorMessage, getIn} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";

import CustomCheckbox from "../components/CustomCheckbox";
import {ErrorView, RecaptchaField, getStyles} from "./form_fields";
import {useLocalization} from "../customHooks/useContextLocalization";
import {useApiClient} from "../customHooks/useApiClient";
import {useCurrentUser} from "../customHooks/useContextCurrentUser";
import {useModal} from "../customHooks/useContextModal";


export const SignupForm = () => {
    const api = useApiClient();
    const locale = useLocalization();
    const modal = useModal();
    const user = useCurrentUser();

    return user
        ? <span>{locale?.get.auth.alreadyLoggedIn}</span>
        : <Formik
            initialValues={{username: "", email: "", password: "", agreement: "", recaptcha: ""}}
            validationSchema={Yup.object({
                username: Yup.string()
                    .max(30, locale?.get.auth.errorView.tooLongUsername)
                    .required(locale?.get.auth.errorView.required),
                email: Yup.string()
                    .email(locale?.get.auth.errorView.invalidEmailAddress)
                    .required(locale?.get.auth.errorView.required),
                password: Yup.string()
                    .min(6, locale?.get.auth.errorView.tooShortPassword)
                    .max(20, locale?.get.auth.errorView.tooLongPassword)
                    .required(locale?.get.auth.errorView.required),
                passwordConfirmation: Yup.string()
                    .oneOf([Yup.ref('password'), null], locale?.get.auth.errorView.passwordsMustMatch),
                agreement: Yup.boolean()
                    .typeError(locale?.get.auth.errorView.agreementShouldBeApplied)
                    .oneOf([true], locale?.get.auth.errorView.agreementShouldBeApplied)
                    .required(locale?.get.auth.errorView.agreementShouldBeApplied),
                recaptcha: Yup.string().required(locale?.get.auth.errorView.arentYouRobot),
            })}
            onSubmit={async (values, {setSubmitting, setErrors}) => {
                try {
                    values.locale = locale?.get?.abbr.toLowerCase();
                    await api.post('/api/v1/user', values);
                    modal.set("");
                    toast.success(locale?.get.auth.youAreSuccessfullySignedUp);
                } catch (err) {
                    values.recaptcha = "";
                    if ([400, 404, 409, 422].includes(err.response.status) && err.response.data.detail) {
                        setErrors(err.response.data.detail);
                    }
                }
                setSubmitting(false);
            }}
            render={formProps => {
                return <Form className='row gtr-uniform gtr-50'>
                    <h4 className="auth-title">{locale?.get.auth.signup}</h4>
                    <div className="field-wrapper">
                        <Field
                            className="auth-text-field"
                            name='username'
                            type='text'
                            placeholder={locale?.get.auth.login}
                            style={getIn(formProps.touched, 'username') && getStyles(formProps.errors, 'username')}
                        />
                        <ErrorMessage component={ErrorView} name='username'/>
                    </div>
                    <div className="field-wrapper">
                        <Field
                            className="auth-text-field"
                            name='email'
                            type='email'
                            placeholder='Email'
                            style={getIn(formProps.touched, 'email') && getStyles(formProps.errors, 'email')}
                        />
                        <ErrorMessage component={ErrorView} name='email'/>
                    </div>
                    <div className="field-wrapper">
                        <Field
                            className="auth-text-field"
                            name='password'
                            type='password'
                            placeholder={locale?.get.auth.password}
                            style={getIn(formProps.touched, 'password') && getStyles(formProps.errors, 'password')}
                        />
                        <ErrorMessage component={ErrorView} name='password'/>
                    </div>
                    <div className="field-wrapper">
                        <Field
                            className="auth-text-field"
                            name='passwordConfirmation'
                            type='password'
                            placeholder={locale?.get.auth.confirmPassword}
                            style={getIn(formProps.touched, 'passwordConfirmation') && getStyles(formProps.errors, 'passwordConfirmation')}
                        />
                        <ErrorMessage component={ErrorView} name='passwordConfirmation'/>
                    </div>
                    <div className="field-wrapper">
                        <CustomCheckbox
                            fieldName="agreement"
                            labelText={<>
                                {locale?.get?.auth.conditionAcceptation}
                                {<a href="https://v1.creativemaps.studio/store/licenses/list" target="_blank">
                                    {locale?.get?.auth.userAgreement}
                                </a>}
                            </>}
                            fieldValue={formProps.values.agreement}
                            setFieldValue={formProps.setFieldValue}
                            style={getIn(formProps.touched, 'agreement') && getStyles(formProps.errors, 'agreement')}
                        />
                        <ErrorMessage component={ErrorView} name='agreement'/>
                    </div>
                    <div className="field-wrapper">
                        <Field
                            name='recaptcha'
                            component={RecaptchaField}
                            style={getIn(formProps.touched, 'recaptcha') && getStyles(formProps.errors, 'recaptcha')}
                        />
                        <ErrorMessage component={ErrorView} name='recaptcha'/>
                    </div>
                    <div className='col-12'>
                        <button type='submit' className='button alt'>{locale?.get.auth.signup}</button>
                    </div>
                </Form>;
            }}
        />;
};
