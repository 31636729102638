import React from "react";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";

import {ReactComponent as PartitionArrow} from "../../../images/controls/next-prev-partition-arrow.svg";
import {ReactComponent as ToolPartitionResizer} from "../../../images/controls/tool-partition-resizer.svg";
import {ReactComponent as ToolScrollArrow} from "../../../images/controls/next-prev-tool-arrow.svg";


export const PrevPartitionArrow = hintModeTooltipHOC(({onClick}) => {
    const {isWatchMode} = useReduxData();
    return <PartitionArrow className={`arrow-prev-partition${isWatchMode ? " disabled" : ""}`} onClick={onClick}/>;
}, "toolbarPartitionArrowPrev");


export const NextPartitionArrow = hintModeTooltipHOC(({onClick}) => {
    const {isWatchMode} = useReduxData();
    return <PartitionArrow
        className={`arrow-next-partition${isWatchMode ? " disabled" : ""}`}
        onClick={onClick}
        transform="rotate(180, 0, 0)"
    />;
}, "toolbarPartitionArrowNext");


export const PartitionResizer = hintModeTooltipHOC(({onMouseDown}) => {
    const {isWatchMode} = useReduxData();
    return <ToolPartitionResizer
        className={`toolbar-partition-resizer${isWatchMode ? " disabled" : ""}`}
        onMouseDown={onMouseDown}
    />

}, "toolbarPartitionResizer");

export const PrevToolArrow = hintModeTooltipHOC(({onClick}) => {
    return <ToolScrollArrow className="arrow-prev-tool" onClick={onClick}/>;
}, "toolbarToolsArrowPrev");


export const NextToolArrow = hintModeTooltipHOC(({onClick}) => {
    return <ToolScrollArrow className="arrow-next-tool" onClick={onClick} transform="rotate(180,0,0)"/>;
}, "toolbarToolsArrowNext");
