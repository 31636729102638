import React, {useState} from "react";
import * as isUUID from "is-uuid";
import {useDispatch} from "react-redux";

import {useReduxData} from "../../../customHooks/useContextReduxData";
import {
    getChildrenValues,
    setDifferentChildrenValues,
    transformArrayValues
} from '../../../pureFunctions/usefulFunctions';
import getTimeElapsedSince from "../../getTimeElapsedSince";
import Path from '../../../pureFunctions/path.js';


export default function addObjectMoving() {
    const [selectedTarget, setSelectedTarget] = useState("");
    const [shiftX, setShiftX] = useState(0);
    const [shiftY, setShiftY] = useState(0);

    const [startPointX, setStartPointX] = useState(0);
    const [startPointY, setStartPointY] = useState(0);

    const dispatch = useDispatch();
    const {figures, scale, selectedFigureUuids, startTime} = useReduxData();

    const startMovingObject = (target, mouseX, mouseY) => {
        let localShiftX = [],
            localShiftY = [],
            targetFigureNode = target;
        if (!isUUID.v4(target.id.split("|")[0])) {
            if (isUUID.v4(target.parentNode.id.split("|")[0])) {
                targetFigureNode = target.parentNode;
            } else {
                targetFigureNode = target.parentNode.parentNode;
            }
        }

        if (targetFigureNode.className.baseVal === "snapping-connector") {
            return undefined;
        }

        let targetX = typeof targetFigureNode.x === "object" ? targetFigureNode.x : targetFigureNode.cx,
            targetY = typeof targetFigureNode.y === "object" ? targetFigureNode.y : targetFigureNode.cy;
        if (!targetX || !targetY) {
            return undefined;
        }
        targetX = targetX.baseVal.value;
        targetY = targetY.baseVal.value;

        let targetUuid = targetFigureNode.id.split("|")[0];
        if (!figures.get(targetUuid)) { //not an object
            return undefined;
        }

        let targetType = "figure";
        if (targetFigureNode.className.baseVal === "transformational-connector") {
            targetType = "transformational-connector";
            let targetFigure = figures.get(targetUuid);
            localShiftX = [mouseX - targetFigure.get("x") * scale];
            localShiftY = [mouseY - targetFigure.get("y") * scale];
        } else {
            selectedFigureUuids.map(uuid => {
                let figure = figures.get(uuid);
                if (figure.get("tool").split("-")[0] === "g") {
                    localShiftX.push(transformArrayValues(getChildrenValues(figures, uuid, "x"), -scale, mouseX));
                    localShiftY.push(transformArrayValues(getChildrenValues(figures, uuid, "y"), -scale, mouseY));
                } else {
                    localShiftX.push(mouseX - figure.get("x") * scale);
                    localShiftY.push(mouseY - figure.get("y") * scale);
                }
                return undefined;
            });
        }

        setSelectedTarget(targetFigureNode);
        setShiftX(localShiftX);
        setShiftY(localShiftY);
        setStartPointX((targetX - 16) / scale);
        setStartPointY((targetY - 16) / scale);

        return targetType;
    };

    const moveObject = (mouseX, mouseY) => {
        if (selectedTarget) {
            let targetClass = selectedTarget.className.baseVal;
            if (["figure", "figure-neighborhood", "group", "text"].includes(targetClass)) {
                selectedFigureUuids.map((uuid, i) => {
                    let figure = figures.get(uuid);
                    let newCoosX, newCoosY;

                    if (figure.get("tool").split("-")[0] !== "g") {
                        newCoosX = (mouseX - shiftX[i]) / scale;
                        newCoosY = (mouseY - shiftY[i]) / scale;
                    } else {
                        newCoosX = transformArrayValues(shiftX[i], -1 / scale, mouseX / scale);
                        newCoosY = transformArrayValues(shiftY[i], -1 / scale, mouseY / scale);
                    }

                    setDifferentChildrenValues(
                        figures,
                        uuid,
                        ["x", "y"],
                        dispatch,
                        [newCoosX, newCoosY],
                        "moveFigure",
                        getTimeElapsedSince(startTime)
                    );
                    return undefined;
                });
            } else if (targetClass === "transformational-connector") {
                let [figureUuid, segmentIndex, connectorIndex] = selectedTarget.id.split("|");
                let figure = figures.get(figureUuid); //многоугольник или контур
                if (!figure) {
                  return undefined;
                }
                let offsetX = (mouseX - shiftX) / scale,
                    offsetY = (mouseY - shiftY) / scale,

                    newPointX = offsetX - figure.get("x") + startPointX,
                    newPointY = offsetY - figure.get("y") + startPointY,

                    newFigureX = figure.get("x") + (newPointX < 0 ? newPointX : 0),
                    newFigureY = figure.get("y") + (newPointY < 0 ? newPointY : 0);

                if (newPointX < 0 || newPointY < 0) {
                    dispatch({
                        type: "moveFigure",
                        uuid: figureUuid,
                        x: newFigureX,
                        y: newFigureY,
                        time: getTimeElapsedSince(startTime)
                    });
                }

                let path = Path.replacePoints(
                    figure.get("points"),
                    [{
                        spInd: 0,
                        curveInd: +segmentIndex - 1,
                        conInd: (!connectorIndex ? "last" : +connectorIndex),
                        x: newPointX,
                        y: newPointY
                    }]
                );

                if (+segmentIndex === 0 && connectorIndex === undefined
                    && (figure.get("tool").split("-")[0] === "p" || figure.get("enclosed"))
                ) {
                    path = Path.replacePoints(
                        path,
                        [{spInd: 0, curveInd: "last", conInd: "last", x: newPointX, y: newPointY}]
                    );
                }
                path = Path.transform(path, newPointX < 0 ? -newPointX : 0, newPointY < 0 ? -newPointY : 0, 1, 1);

                dispatch({
                    type: "changeFigurePoints",
                    uuid: figureUuid,
                    points: path,
                    time: getTimeElapsedSince(startTime)
                });
            }
        }
    };

    return {startMovingObject, moveObject};
}
