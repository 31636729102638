import React from "react";

import {useIconSet} from "../../../customHooks/useContextIconSet";


export default function PolygonsPatterns () {
    const {get: {icons}} = useIconSet();
    return Object.keys(icons.polygons).map(productId => Object.keys(icons.polygons[productId].icons).map(imageIndex =>
        <defs key={`pattern-p-${productId}-${imageIndex}`}>
            <pattern
                id={`pattern-p-${productId}-${imageIndex}`}
                x="2"
                y="2"
                width="35"
                height="35"
                patternUnits="userSpaceOnUse"
            >
                <image href={icons.polygons[productId].icons[imageIndex] || ""} x="0" y="0" width="35" height="35"/>
            </pattern>
        </defs>
    ));
}