import {v4 as uuid} from "uuid";


const toolDictionary = { // this dictionary to translate old tool names to new ones
    "bioFish": "i-s-55",
    "bioFur": "i-s-54",
    "bioPlant": "i-s-56",
    "brdSolid": "c-s-0",
    "custom": "p-s-12",
    "extBuildingMaterials": "i-s-43",
    "extBuildingStones": "i-s-44",
    "extChemicalMaterials": "i-s-45",
    "extCoal": "i-s-46",
    "extGas": "i-s-47",
    "extJewel": "i-s-48",
    "extMine": "i-s-49",
    "extOilPump": "i-s-50",
    "extOilShale": "i-s-51",
    "extOre": "i-s-52",
    "extPeat": "i-s-53",
    "group": "g-s-72",
    "hisAnotherAncient": "i-s-28",
    "hisArcheology": "i-s-19",
    "hisBuddhist": "i-s-23",
    "hisCemetery": "i-s-27",
    "hisFort": "i-s-24",
    "hisMonument": "i-s-25",
    "hisMosque": "i-s-22",
    "hisObelisk": "i-s-20",
    "hisOrthodox": "i-s-21",
    "hisRiderMonument": "i-s-26",
    "humCity": "i-s-14",
    "humMegacity": "i-s-13",
    "humMilitary": "i-s-42", //this line from update to 1.42.0
    "humTown": "i-s-15",
    "humTownship": "i-s-16",
    "humVillage": "i-s-18",
    "humVillageWithChurch": "i-s-17",
    "lanArcticDesert": "p-s-9",
    "lanBeach": "i-s-70",
    "lanCave": "i-s-69",
    "lanDesert": "p-s-8",
    "lanGlacier": "i-s-68",
    "lanGrassyIsland": "p-s-11",
    "lanHill": "i-s-64",
    "lanIsland": "p-s-10",
    "lanMountain": "i-s-66",
    "lanMountainWithSnow": "i-s-67",
    "lanPlateau": "i-s-65",
    "lanSteppe": "p-s-6",
    "lanSwamp": "p-s-5",
    "lanTundra": "p-s-7",
    "lanVolcano": "i-s-71",
    "outAirport": "i-s-30",
    "outCosmodrome": "i-s-38",
    "outFactory": "i-s-31",
    "outGeothermal": "i-s-32",
    "outLighthouse": "i-s-29",
    "outObservatory": "i-s-35",
    "outMilitary": "i-s-42",
    "outNationalPark": "i-s-39",
    "outNuclearPowerPlant": "i-s-33",
    "outPowerPlant": "i-s-34",
    "outRadar": "i-s-36",
    "outReserve": "i-s-40",
    "outReservoir": "i-s-37",
    "outTouristBase": "i-s-41",
    "vegBush": "i-s-57",
    "vegDeciduous": "i-s-62",
    "vegEvergreen": "i-s-58",
    "vegFruit": "i-s-63",
    "vegGrass": "i-s-60",
    "vegPalm": "i-s-59",
    "vegWheat": "i-s-61",
    "watReservoirs": "p-s-4",
    "watRiver": "c-s-3",
    "wayAuto": "c-s-1",
    "wayRail": "c-s-2"
};

export default function updateMap(map) {
    if (map.main.version >= "v1.47.0") {
        return map;
    }

    if (!map.actionHistory) { //too old map (before 07/17/2020)
        return "unsupported";
    }
    if (map.actionHistory.map(action => action.type).includes("device")) { // too old map (actionHistory is incompatible, 07/29/2020)
        return "unsupported";
    }
    if (Object.keys(map.main.figures).map(uuid => map.main.figures[uuid].orderIndex).includes(undefined)) { // too old map 08/03/2020
        return "unsupported";
    }
    if (!map.main.mapId) { //map version is lower than 1.16.0
        map.main.mapId = uuid();
    }
    if ("lang" in map.menu) { //map version is lower than 1.16.2
        delete map.menu.lang;
    }
    if ("currentDescription" in map.menu && "currentName" in map.menu && "isDescriptionShow" in map.menu) { //map version is lower than 1.17.6
        delete map.menu.currentDescription;
        delete map.menu.currentName;
        delete map.menu.isDescriptionShow;
        map.menu.descriptionPopupTitle = "";
        map.menu.descriptionPopupText = "";
        map.menu.figureUuidDescriptionPopupShownFor = "";
    }
    if ("isContextMenuColorPickerShow" in map.menu && "isContextMenuEmojiPickerShow" in map.menu) { //map version is lower than 1.34.0
        delete map.menu.isContextMenuColorPickerShow;
        delete map.menu.isContextMenuEmojiPickerShow;
        map.menu.openContextMenuTool = "no";
    }
    if (map.menu.isSquareMode || map.menu.isSquareMode === false) { //map version is lower than 1.38.9
        map.menu.isRectangleSelectionMode = map.menu.isSquareMode;
    }
    if (!("isUserCanChangeCanvasSize" in map.menu) && !("backgroundImage" in map.menu)) { //map version is lower than 1.45.0
        map.menu.isUserCanChangeCanvasSize = true;
        map.menu.backgroundImage = "";
    }
    if (!map.menu.tool.includes("-")) { //map version is lower than 1.47.0 (old tool names)
        map.menu.tool = toolDictionary[map.menu.tool];
        Object.keys(map.main.figures).map(uuid => {
            map.main.figures[uuid].tool = toolDictionary[map.main.figures[uuid].tool];
        });
        map.main.lastAction.tool = toolDictionary[map.main.lastAction.tool];
        map.actionHistory.map((action, i) => {
            if (map.actionHistory[i].tool) {
                map.actionHistory[i].tool = toolDictionary[map.actionHistory[i].tool];
            }
        })
    }
    if ("isRectangleSelectionMode" in map.menu && "isPencilMode" in map.menu) { //map version is lower than 1.65.0
        delete map.menu.isRectangleSelectionMode;
        delete map.menu.isPencilMode;
    }

    map.actionHistory.map((action, i) => { //map version is lower than 1.67.0
        if (typeof action.layout === "string") {
            map.actionHistory[i].layout = +action.layout;
        }
    });
    if (typeof map.main.lastAction.layout === "string") { //map version is lower than 1.67.0
        map.main.lastAction.layout = +map.main.lastAction.layout;
    }

    Object.keys(map.main.figures).map(uuid => { //map version is lower than 1.67.0
        if (typeof map.main.figures[uuid].layout === "string") {
            map.main.figures[uuid].layout = +map.main.figures[uuid].layout;
        }
    });

    Object.keys(map.main.figures).map(uuid => { //map version, that have not flags
        if (map.main.figures[uuid].tool.split("-")[0] === "i" && !map.main.figures[uuid].flags) {
            map.main.figures[uuid].flags = [];
        }
    });

    map.actionHistory.map((action, i) => { //map version, that have not flags
        if (action.type === "addPointFeature" && typeof map.actionHistory[i].flags === "undefined") {
            map.actionHistory[i].flags = [];
        }
    });
    if (map.main.lastAction.type === "addPointFeature" && typeof map.main.lastAction.flags === "undefined") { //map version, that have not flags
        map.main.lastAction.flags = [];
    }

    map.actionHistory.map((action, i) => { //map version is lower than 1.69.0
        if (action.type === "addGroup" && (
                map.actionHistory[i].tool !== "g-s-72"
            || map.actionHistory[i].description !== ""
            || map.actionHistory[i].name !== ""
        )) {
            map.actionHistory[i].name = "";
            map.actionHistory[i].description = "";
            map.actionHistory[i].tool = "g-s-72";
        }
    });
    if (map.main.lastAction.type === "addGroup" && (
            map.main.lastAction.tool !== "g-s-72"
        || map.main.lastAction.description !== ""
        || map.main.lastAction.name !== ""
    )) {
        map.main.lastAction.name = "";
        map.main.lastAction.description = "";
        map.main.lastAction.tool = "g-s-72";
    }

    return map;
}