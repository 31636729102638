import React, {useState} from "react";


export default function SocialNetworkIcon({id, className, SvgIcon, iconSize, onClick}) {
    const [isFired, setIsFired] = useState(false);

    const onFire = () => setIsFired(true);
    const onUnfire = () => setIsFired(false);

    return <div
        id={id}
        className={className}
        onMouseDown={onFire}
        onMouseUp={onUnfire}
        onClick={onClick}
        style={{backgroundColor: isFired ? "#144066" : "#C4DEF5"}}
    >
        <SvgIcon
            id={`${id}-icon`}
            fill={isFired ? "#C4DEF5" : "#144066"}
            stroke={isFired ? "#C4DEF5" : "#144066"}
            height={iconSize}
            width={iconSize}
        />
    </div>;
}
