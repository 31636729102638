import React, {useState, useContext} from "react";


export const MapFileNameContext = React.createContext();


export const MapFileNameProvider = ({children}) => {
    const [get, set] = useState("");

    return (
        <MapFileNameContext.Provider value={{get, set}}>
            {children}
        </MapFileNameContext.Provider>
    );
};


export const useMapFileName = () => useContext(MapFileNameContext);
