import {useState, useCallback, useRef, useEffect} from "react";
import ReactFlow, {
    MiniMap,
    Controls,
    applyEdgeChanges,
    applyNodeChanges,
    addEdge
} from 'react-flow-renderer';

import NodeMenu from "./nodeMenu/NodeMenu";
import NodeConfigurator from "./nodeConfigurator/NodeConfigurator";
import ResultRenderer from "./resultRenderer/ResultRenderer";
import {CustomDragLayer} from "./dnd/CustomDragLayer";
import {NodeFilterProvider} from "./nodeMenu/hooks/useNodeFilter";
import useNodeDropTarget from "./dnd/hooks/useNodeDropTarget";
import {useApiClient} from "../../../../customHooks/useApiClient";
import {useDataSource} from "./hooks/useContextDataSource";
import {getFileNameFromContentDisposition} from "../../../../store/components/productForm/pureFunctions";
import {compressFiles} from "../../../pureFunctions";
import {CUSTOM_NODE_TYPES} from "./customNodeTypes";


export default function ({serverMapInfo, handleServerMapTableShowing}) {
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [selectedNode, setSelectedNode] = useState(undefined);
    const [newNodeId, setNewNodeId] = useState(1);
    const [isRenderMode, setIsRenderMode] = useState(false);
    const [renderDataType, setRenderDataType] = useState("");
    const [dataToRender, setDataToRender] = useState("");
    const [shouldRunPipeline, setShouldRunPipeline] = useState(true);
    const [loadingStatus, setLoadingStatus] = useState("");

    const reactFlowContainerRef = useRef(null);

    const api = useApiClient();
    const [drop] = useNodeDropTarget(newNodeId, setNewNodeId, reactFlowContainerRef);
    const {dataSources} = useDataSource();

    useEffect(() => {
        setShouldRunPipeline(true);
    }, [dataSources, serverMapInfo]);

    const onNodesChange = useCallback(
        changes => setNodes(nds => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        changes => {
            setEdges(eds => applyEdgeChanges(changes, eds));
            setShouldRunPipeline(true);
        },
        [setEdges, setShouldRunPipeline]
    );
    const onConnect = useCallback(
        connection => {
            setEdges(eds => addEdge(connection, eds));
            setShouldRunPipeline(true);
        },
        [setEdges, setShouldRunPipeline]
    );

    const onSelectionChange = useCallback(
        selectedElements => {
            if (selectedElements.nodes.length === 1) {
                setSelectedNode(selectedElements.nodes[0]);
            } else {
                setSelectedNode(undefined);
            }
        },
        []
    )

    const runPipeline = async () => {
        if (shouldRunPipeline) {
            let formData = new FormData();
            const formDataSources = [];
            for (let i = 0; i < Object.keys(dataSources).length; i++) {
                const sourceNodeId = Object.keys(dataSources)[i];
                const dataSource = dataSources[sourceNodeId].source;
                formDataSources.push({"id": sourceNodeId, "source": dataSource});

                let dataFile = undefined;
                if (dataSource === "server") {
                    const dataServerSource = dataSources[sourceNodeId].serverSource;
                    if (dataServerSource === "maps") {
                        let mapsToConvert = "";
                        if (dataSources[sourceNodeId].serverMapSource === "table") {
                            mapsToConvert = serverMapInfo.map(tableRow => tableRow.mv_id).join(", ");
                        } else if (dataSources[sourceNodeId].serverMapSource === "custom") {
                            mapsToConvert = dataSources[sourceNodeId].serverCustomList;
                        }
                        dataFile = new Blob([mapsToConvert], {type: "text/txt"});
                    } else if (dataServerSource === "dump") {
                        dataFile = new Blob([dataSources[sourceNodeId].serverDumpTable], {type: "text/txt"});
                    }
                } else if (dataSource === "local") {
                    dataFile = await compressFiles(dataSources[sourceNodeId].localFiles);
                }
                formData.append("data_files", dataFile, sourceNodeId);
            }

            formData.append("data_sources", JSON.stringify(formDataSources));

            // console.log(nodes);
            // console.log(edges);
            const processed_edges = edges.map(edge => ({
                source_id: edge.source,
                source_handle_id: edge.sourceHandle,
                target_id: edge.target,
                target_handle_id: edge.targetHandle
            }));
            const processed_nodes = {};
            nodes.map(node => {
                processed_nodes[node.id] = {name: node.data.label, type: node.type};
                if (node.type === "source") {
                    processed_nodes[node.id]["data_type"] = dataSources[node.id].dataType;
                }
            });
            formData.append("node_tree", JSON.stringify({nodes: processed_nodes, edges: processed_edges}));

            try {
                const {data: pipelineResults, ...other} = (await api.put(
                    `/api/v1/researches/pipeline/run`,
                    formData,
                    {
                        headers: {'Content-Type': 'multipart/form-data'},
                        responseType: "blob",
                        onUploadProgress: progressEvent => {
                            const loadingPercent = Math.round(progressEvent.loaded / progressEvent.total * 100);
                            setLoadingStatus(
                                `Uploading: ${loadingPercent}%${loadingPercent === 100 ? ", sending ..." : ""}`
                            );
                        }
                    }
                ));
                setLoadingStatus("");
                if (pipelineResults) {
                    if (pipelineResults.type.startsWith("studio")) {
                        setIsRenderMode(true);
                        setRenderDataType(pipelineResults.type);
                        setDataToRender(await pipelineResults.text());
                        setShouldRunPipeline(false);
                    } else {
                        const filename = prompt(
                            "Do you want to save the results?",
                            getFileNameFromContentDisposition(other.headers["content-disposition"]) || "default_filename"
                        );
                        const blob = new Blob([pipelineResults], {type: pipelineResults.type || "application/json"});
                        filename && saveAs(blob, filename);
                    }
                }
            } catch (e) {
                console.log(e);
                setLoadingStatus("Error occurred");
            }
        } else {
            setIsRenderMode(true);
        }
    }

    const returnToPipeline = () => setIsRenderMode(false);

    return isRenderMode
        ? <div className="research-dfd-visualization-container">
            <input type="button" className="research-dfd-return-to-pipeline" value="Back" onClick={returnToPipeline}/>
            <ResultRenderer dataType={renderDataType} dataToRender={dataToRender}/>
        </div>
        : <>
            <div
                className="research-dfd-canvas-grid"
                style={selectedNode?.type === "source" ? {maxHeight: "calc(100% - 10rem)"} : {}}
            >
                <CustomDragLayer/>
                <NodeFilterProvider>
                    <NodeMenu runPipeline={runPipeline} loadingStatus={loadingStatus}/>
                </NodeFilterProvider>
                <div ref={reactFlowContainerRef}>
                    <ReactFlow
                        ref={drop}
                        nodes={nodes}
                        edges={edges}
                        nodeTypes={CUSTOM_NODE_TYPES}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        deleteKeyCode={"Delete"}
                        onSelectionChange={onSelectionChange}
                    >
                        {/*<MiniMap/>*/}
                        <Controls/>
                    </ReactFlow>
                </div>
            </div>
            <NodeConfigurator selectedNode={selectedNode} handleServerMapTableShowing={handleServerMapTableShowing}/>
        </>;
}