export const menuItems = [
    {
        key: "file",
        isExtended: false, //нужно ли рендерить место для hotKeys
        items: [
            {
                key: "newMap",
                enabled: true
            },
            {
                key: "myMaps",
                enabled: true
            },
            {
                key: "send",
                enabled: true
            },
            {
                key: "load",
                enabled: true
            },
            {
                key: "save",
                enabled: true
            }
        ]
    },
    {
        key: "edit",
        isExtended: true,
        items: [
            {
                key: "undo",
                hotkey: "Ctrl+Z",
                enabled: (props) => !props.isWatchMode && props.isTherePast
            },
            {
                key: "redo",
                hotkey: "Ctrl+Shift+Z",
                enabled: (props) => !props.isWatchMode && props.isThereFuture
            },
            {
                key: "copy",
                hotkey: "Ctrl+C",
                enabled: (props) => !props.isWatchMode && props.isThereSelectedFigures
            },
            {
                key: "paste",
                hotkey: "Ctrl+V",
                enabled: (props) => !props.isWatchMode && props.isThereClipboardContent
            },
            {
                key: "delete",
                hotkey: "Delete",
                enabled: (props) => !props.isWatchMode && props.isThereSelectedFigures
            },
            {
                key: "resize",
                enabled: (props) => !props.isWatchMode && props.isUserCanChangeCanvasSize
            },
            {
                key: "settings",
                hotkey: ">",
                enabled: false
            }
        ]
    },
    {
        key: "help",
        isExtended: false,
        items: []
    },
    {
        key: "onboarding",
        isExtended: false,
        items: []
    },
    {
        key: "store",
        isExtended: false,
        items: []
   }
];
